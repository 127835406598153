import { classNames } from "primereact/utils";
import { Calendar } from "./theme/Calendar";
import { Checkbox } from "./theme/Checkbox";

//calender
// const CALTRANSITIONS = {
//   overlay: {
//       timeout: 150,
//       classNames: {
//           enter: 'opacity-0 scale-75',
//           enterActive: 'opacity-100 !scale-100 transition-transform transition-opacity duration-150 ease-in',
//           exit: 'opacity-100',
//           exitActive: '!opacity-0 transition-opacity duration-150 ease-linear'
//       }
//   }
// };
const design = {
  message: {
    root: ({ props }) => ({
      className: classNames(
        "inline-flex items-center justify-center align-top",
        "p-3 m-0 rounded-md",
        {
          "bg-blue-100 border-0 text-blue-700": props.severity == "info",
          "bg-green-100 border-0 text-green-700": props.severity == "success",
          "bg-orange-100 border-0 text-orange-700": props.severity == "warn",
          "bg-red-100 border-0 text-red-700": props.severity == "error",
        },
      ),
    }),
    icon: "text-base mr-2",
  },
  inputtext: {
    root: ({ props, context }: any) => ({
      className: classNames(
        "m-0",
        "w-full text-gray-600  bg-white  border border-primary-border transition-colors duration-200 appearance-none rounded-lg",
        {
          "hover:border-primary-blue focus:outline-none focus:outline-offset-0 focus:ring-1 ring-primary-blue ":
            !context.disabled,
          "opacity-60 select-none pointer-events-none cursor-default":
            context.disabled,
        },
        {
          "text-sm px-3 py-3": props.size == "large",
          "text-xs px-2 py-2": props.size == "small",
          "p-3 text-base": props.size == null,
        },
      ),
    }),
  },
  button: {
    root: ({ props, context }) => ({
      className: classNames(
        "items-center gap-2 justify-center cursor-pointer inline-flex overflow-hidden relative select-none text-center align-bottom",
        "transition duration-200 ease-in-out",
        "focus:outline-none focus:outline-offset-0",
        {
          "border ":
            !props.link &&
            props.severity === null &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          "text-blue-600 bg-transparent border-transparent ": props.link,
          "text-white bg-primary-blue border-primary-blue ": !context.disabled,
        },
        {
          "text-white bg-primary-blackButton hover:bg-gray-700 ":
            props.severity === "secondary" &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          "text-white dark:text-gray-900 bg-green-500 dark:bg-green-400 border border-green-500 dark:border-green-400 hover:bg-green-600 dark:hover:bg-green-500 hover:border-green-600 dark:hover:border-green-500":
            props.severity === "success" &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          "text-white dark:text-gray-900 dark:bg-blue-400 bg-blue-500 dark:bg-blue-400 border border-blue-500 dark:border-blue-400 hover:bg-blue-600 hover:border-blue-600 dark:hover:bg-blue-500 dark:hover:border-blue-500":
            props.severity === "info" &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          "text-white dark:text-gray-900 bg-orange-500 dark:bg-orange-400 border border-orange-500 dark:border-orange-400 hover:bg-orange-600 dark:hover:bg-orange-500 hover:border-orange-600 dark:hover:border-orange-500":
            props.severity === "warning" &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          "text-white dark:text-gray-900 bg-purple-500 dark:bg-purple-400 border border-purple-500 dark:border-purple-400 hover:bg-purple-600 dark:hover:bg-purple-500 hover:border-purple-600 dark:hover:border-purple-500":
            props.severity === "help" &&
            !props.text &&
            !props.outlined &&
            !props.plain,
          "text-white dark:text-gray-900 bg-red-500 dark:bg-red-400 border border-red-500 dark:border-red-400 hover:bg-red-600 dark:hover:bg-red-500 hover:border-red-600 dark:hover:border-red-500":
            props.severity === "danger" &&
            !props.text &&
            !props.outlined &&
            !props.plain,
        },
        { "shadow-lg": props.raised },
        { "rounded-[5px]": !props.rounded, "rounded-full": props.rounded },
        {
          "bg-transparent border-transparent": props.text && !props.plain,
          "text-blue-500 dark:text-blue-400 hover:bg-blue-300/20":
            props.text &&
            (props.severity === null || props.severity === "info") &&
            !props.plain,
          "text-gray-500 dark:text-grayy-400 hover:bg-gray-300/20":
            props.text && props.severity === "secondary" && !props.plain,
          "text-green-500 dark:text-green-400 hover:bg-green-300/20":
            props.text && props.severity === "success" && !props.plain,
          "text-orange-500 dark:text-orange-400 hover:bg-orange-300/20":
            props.text && props.severity === "warning" && !props.plain,
          "text-purple-500 dark:text-purple-400 hover:bg-purple-300/20":
            props.text && props.severity === "help" && !props.plain,
          "text-red-500 dark:text-red-400 hover:bg-red-300/20":
            props.text && props.severity === "danger" && !props.plain,
        },
        { "shadow-lg": props.raised && props.text },
        {
          "text-gray-500 hover:bg-gray-300/20": props.plain && props.text,
          "text-gray-500 border border-gray-500 hover:bg-gray-300/20":
            props.plain && props.outlined,
          "text-white bg-gray-500 border border-gray-500 hover:bg-gray-600 hover:border-gray-600":
            props.plain && !props.outlined && !props.text,
        },
        {
          "bg-transparent border": props.outlined && !props.plain,
          "text-blue-500 dark:text-blue-400 border border-blue-500 dark:border-blue-400 hover:bg-blue-300/20":
            props.outlined &&
            (props.severity === null || props.severity === "info") &&
            !props.plain,
          "text-gray-500 dark:text-gray-400 border border-gray-500 dark:border-gray-400 hover:bg-gray-300/20":
            props.outlined && props.severity === "secondary" && !props.plain,
          "text-green-500 dark:text-green-400 border border-green-500 dark:border-green-400 hover:bg-green-300/20":
            props.outlined && props.severity === "success" && !props.plain,
          "text-orange-500 dark:text-orange-400 border border-orange-500 dark:border-orange-400 hover:bg-orange-300/20":
            props.outlined && props.severity === "warning" && !props.plain,
          "text-purple-500 dark:text-purple-400 border border-purple-500 dark:border-purple-400 hover:bg-purple-300/20":
            props.outlined && props.severity === "help" && !props.plain,
          "text-red-500 dark:text-red-400 border border-red-500 dark:border-red-400 hover:bg-red-300/20":
            props.outlined && props.severity === "danger" && !props.plain,
        },
        {
          "px-8 py-[10px] text-sm": props.size === null,
          "text-xs py-2 px-3": props.size === "small",
          " py-4 px-4": props.size === "large",
        },
        { "flex-column": props.iconPos == "top" || props.iconPos == "bottom" },
        {
          "bg-[#021B1D] shadow-none text-[#8A8AA1] bg-opacity-[0.03] pointer-events-none cursor-default":
            context.disabled,
        },
      ),
    }),
    label: ({ props }) => ({
      className: classNames(
        "flex-1",
        "duration-200",
        "font-bold text-base",
        {
          "hover:underline": props.link,
        },
        { "invisible w-0": props.label == null },
      ),
    }),
  },
  password: {
    root: () => ({
      className: classNames(
        "w-full relative border border-primary-border rounded-lg",
      ),
    }),
    showicon: {
      className: classNames("absolute", "right-4 top-4 text-primary-gray "),
    },
    hideicon: {
      className: classNames("absolute", "right-4 top-4  text-primary-gray "),
    },
  },
  sidebar: {
    mask: {
      className: classNames("!items-start !top-[85px] "),
    },
    root: () => ({
      //{props} removed due to unused to avoid commiting errors.
      className: classNames(
        "bg-secondary-blue text-white border-0 lg:w-[240px] ",
      ),
    }),
    content: { className: classNames() },
  },
  /* sidebar: {
    root: ({ props }) => ({
        className: classNames(
            'flex flex-col pointer-events-auto relative transform translate-x-0 translate-y-0 translate-z-0 relative transition-transform duration-300',
            'bg-white text-gray-700 border-0 shadow-lg',
            {
                '!transition-none !transform-none !w-screen !h-screen !max-h-full !top-0 !left-0': props.position == 'full',
                'h-full w-80': props.position == 'left' || props.position == 'right',
                'h-40 w-full': props.position == 'top' || props.position == 'bottom'
            },
            'dark:border dark:border-blue-900/40 dark:bg-gray-900 dark:text-white/80'
        )
    }),
    header: {
        className: classNames('flex items-center justify-end', 'p-5')
    },
    closeButton: {
        className: classNames(
            'flex items-center justify-center overflow-hidden relative',
            'w-8 h-8 text-gray-500 border-0 bg-transparent rounded-full transition duration-200 ease-in-out mr-2 last:mr-0',
            'hover:text-gray-700 hover:border-transparent hover:bg-gray-200',
            'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]', // focus
            'dark:hover:text-white/80 dark:hover:text-white/80 dark:hover:border-transparent dark:hover:bg-gray-800/80 dark:focus:shadow-[inset_0_0_0_0.2rem_rgba(147,197,253,0.5)]'
        )
    },
    closeButtonIcon: 'w-4 h-4 inline-block',
    content: {
        className: classNames('p-5 pt-0 h-full w-full', 'grow overflow-y-auto')
    },
    mask: {
        className: classNames('flex pointer-events-auto', 'bg-black bg-opacity-40 transition duration-200 z-0 !w-5 transition-colors')
    },
    transition: ({ props }) => {
        return props.position === 'top'
            ? {
                  enterFromClass: 'translate-x-0 -translate-y-full translate-z-0',
                  leaveToClass: 'translate-x-0 -translate-y-full translate-z-0'
              }
            : props.position === 'bottom'
            ? {
                  enterFromClass: 'translate-x-0 translate-y-full translate-z-0',
                  leaveToClass: 'translate-x-0 translate-y-full translate-z-0'
              }
            : props.position === 'left'
            ? {
                  enterFromClass: '-translate-x-full translate-y-0 translate-z-0',
                  leaveToClass: '-translate-x-full translate-y-0 translate-z-0'
              }
            : props.position === 'right'
            ? {
                  enterFromClass: 'translate-x-full translate-y-0 translate-z-0',
                  leaveToClass: 'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0'
              }
            : {
                  enterFromClass: 'opacity-0',
                  enterActiveClass: 'transition-opacity duration-400 ease-in',
                  leaveActiveClass: 'transition-opacity duration-400 ease-in',
                  leaveToClass: 'opacity-0'
              };
    }
},*/
  checkbox: Checkbox,
  progressbar: {
    root: {
      className: classNames(
        "overflow-hidden relative",
        "border-0 h-2 bg-gray-200 rounded-md",
      ),
    },
    value: ({ props }) => ({
      className: classNames("border-0 m-0 bg-blue-500", {
        "transition-width duration-1000 ease-in-out absolute items-center border-0 flex h-full justify-center overflow-hidden w-0":
          props.mode !== "indeterminate",
        "progressbar-value-animate before:absolute before:top-0 before:left-0 before:bottom-0 before:bg-inherit after:absolute after:top-0 after:left-0 after:bottom-0 after:bg-inherit after:delay-1000":
          props.mode == "indeterminate",
      }),
    }),
    label: {
      className: classNames("inline-flex", "text-white leading-6"),
    },
  },
  dialog: {
    root: ({ state }) => ({
      className: classNames(
        "rounded-lg shadow-lg border-0",
        "max-h-[90%] transform scale-100 relative",
        "m-0 ",
        {
          "transition-none transform-none !w-screen !h-screen !max-h-full !top-0 !left-0":
            state.maximized,
        },
      ),
    }),
    header: {
      className: classNames(
        "flex items-center justify-between shrink-0",
        "bg-white text-gray-800 border-t-0  rounded-tl-lg rounded-tr-lg p-6",
      ),
    },
    headerTitle: "text-2xl font-bold text-primary-text",
    headerIcons: "flex items-center",
    closeButton: {
      className: classNames(
        "flex items-center justify-center overflow-hidden relative",
        "w-10 h-10 text-gray-500 border-0 bg-transparent border:none transition duration-200 ease-in-out mr-2 last:mr-0",
        "hover:text-gray-700 hover:border-transparent hover:bg-gray-200",
        "focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]", // focus
      ),
    },
    closeButtonIcon: "w-4 h-4 inline-block",
    content: ({ state }) => ({
      className: classNames(
        "overflow-y-auto",
        "bg-white text-gray-700  pb-8 pt-0",
        "rounded-bl-lg rounded-br-lg ",
        {
          grow: state.maximized,
        },
      ),
    }),
    footer: {
      className: classNames(
        "shrink-0 ",
        "bg-white text-gray-700 px-6 pb-6 text-right rounded-b-lg",
      ),
    },
    mask: ({ state }) => ({
      className: classNames("transition duration-200 ", {
        "bg-black/40": state.containerVisible,
      }),
    }),
    transition: ({ props }) => {
      return props.position === "top"
        ? {
            enterFromClass:
              "opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0",
            enterActiveClass: "transition-all duration-200 ease-out",
            leaveActiveClass: "transition-all duration-200 ease-out",
            leaveToClass:
              "opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0",
          }
        : props.position === "bottom"
          ? {
              enterFromClass: "opacity-0 scale-75 translate-y-full",
              enterActiveClass: "transition-all duration-200 ease-out",
              leaveActiveClass: "transition-all duration-200 ease-out",
              leaveToClass:
                "opacity-0 scale-75 translate-x-0 translate-y-full translate-z-0",
            }
          : props.position === "left" ||
              props.position === "top-left" ||
              props.position === "bottom-left"
            ? {
                enterFromClass:
                  "opacity-0 scale-75 -translate-x-full translate-y-0 translate-z-0",
                enterActiveClass: "transition-all duration-200 ease-out",
                leaveActiveClass: "transition-all duration-200 ease-out",
                leaveToClass:
                  "opacity-0 scale-75  -translate-x-full translate-y-0 translate-z-0",
              }
            : props.position === "right" ||
                props.position === "top-right" ||
                props.position === "bottom-right"
              ? {
                  enterFromClass:
                    "opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0",
                  enterActiveClass: "transition-all duration-200 ease-out",
                  leaveActiveClass: "transition-all duration-200 ease-out",
                  leaveToClass:
                    "opacity-0 scale-75 opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0",
                }
              : {
                  enterFromClass: "opacity-0 scale-75",
                  enterActiveClass: "transition-all duration-200 ease-out",
                  leaveActiveClass: "transition-all duration-200 ease-out",
                  leaveToClass: "opacity-0 scale-75",
                };
    },
  },
  dropdown: {
    root: ({ props }) => ({
      className: classNames(
        "cursor-pointer inline-flex relative select-none",
        "bg-white border border-primary-border  transition-colors duration-200 ease-in-out rounded-md",
        "hover:border-primary-blue focus:outline-none focus:outline-offset-0",
        {
          "opacity-60 select-none pointer-events-none cursor-default":
            props.disabled,
        },
      ),
    }),
    input: ({ props }) => ({
      className: classNames(
        "cursor-pointer block min-w-[200px] flex flex-auto overflow-hidden overflow-ellipsis whitespace-nowrap relative",
        "bg-transparent border-0 text-gray-800 ",
        "p-3 transition duration-200 bg-transparent rounded appearance-none font-sans text-base",
        "focus:outline-none focus:shadow-none",
        { "pr-7": props.showClear },
      ),
    }),
    trigger: {
      className: classNames(
        "flex items-center justify-center shrink-0",
        "bg-transparent text-gray-500 w-12 rounded-tr-lg rounded-br-lg",
      ),
    },
    wrapper: {
      className: classNames(
        "max-h-[200px] overflow-auto",
        "bg-white text-gray-700 border-0 rounded-md shadow-lg",
      ),
    },
    list: "py-3 list-none m-0",
    item: ({ context }) => ({
      className: classNames(
        "cursor-pointer font-normal overflow-hidden relative whitespace-nowrap",
        "m-0 p-3 border-0  transition-shadow duration-200 rounded-none",
        "hover:text-gray-700 hover:bg-gray-200",
        {
          "text-gray-700": !context.focused && !context.selected,
          "bg-gray-300 text-gray-700 ": context.focused && !context.selected,
          "bg-blue-400 text-blue-700 ": context.focused && context.selected,
          "bg-blue-50 text-blue-700 ": !context.focused && context.selected,
          "opacity-60 select-none pointer-events-none cursor-default":
            context.disabled,
        },
      ),
    }),
    itemgroup: {
      className: classNames(
        "m-0 p-3 text-gray-800 bg-white font-bold",
        "cursor-auto",
      ),
    },
    header: {
      className: classNames(
        "p-3 border-b border-gray-300 text-gray-700 bg-gray-100 mt-0 rounded-tl-lg rounded-tr-lg",
      ),
    },
    filtercontainer: "relative",
    filterinput: {
      className: classNames(
        "pr-7 -mr-7",
        "w-full",
        "font-sans text-base text-gray-700 bg-white py-3 px-3 border border-gray-300 transition duration-200 rounded-lg appearance-none",
        "hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]",
      ),
    },
    filtericon: "-mt-2 absolute top-1/2",
    clearicon: "text-gray-500 right-12 -mt-2 absolute top-1/2",
    // transition: TRANSITIONS.overlay //commented to commit
  },
  inputtextarea: {
    root: ({ context }) => ({
      className: classNames(
        "m-0",
        "font-sans text-base text-gray-600  p-3 border border-primary-border  transition-colors duration-200 appearance-none rounded-lg",
        "hover:border-primary-blue focus:outline-none focus:outline-offset-0 focus:ring-1 ring-primary-blue",
        {
          "opacity-60 select-none pointer-events-none cursor-default":
            context.disabled,
        },
      ),
    }),
  },
  inputswitch: {
    root: ({ props }) => ({
      className: classNames("inline-block relative", "w-12 h-7", {
        "opacity-60 select-none pointer-events-none cursor-default":
          props.disabled,
      }),
    }),
    slider: ({ props }) => ({
      className: classNames(
        "absolute cursor-pointer top-0 left-0 right-0 bottom-0 border border-transparent",
        "transition-colors duration-200 rounded-2xl",
        "focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(2, 56, 91, 1)]",
        "before:absolute before:content-'' before:top-1/2 before:bg-white before:w-5 before:h-5 before:left-1 before:-mt-2.5 before:rounded-full before:transition-duration-200",
        {
          "bg-gray-200  hover:bg-gray-300 ": !props.checked,
          "bg-primary-blue before:transform before:translate-x-5":
            props.checked,
        },
      ),
    }),
  },

  inputnumber: {
    root: "w-full inline-flex",
    input: {
      root: ({ props }) => ({
        className: classNames({
          "rounded-tr-none rounded-br-none":
            props.showButtons && props.buttonLayout == "stacked",
        }),
      }),
    },
    buttongroup: ({ props }) => ({
      className: classNames({
        "flex flex-col": props.showButtons && props.buttonLayout == "stacked",
      }),
    }),
    incrementbutton: ({ props }) => ({
      className: classNames("flex !items-center !justify-center", {
        "rounded-br-none rounded-bl-none rounded-bl-none !p-0 flex-1 w-[3rem]":
          props.showButtons && props.buttonLayout == "stacked",
      }),
    }),
    decrementbutton: ({ props }) => ({
      className: classNames("flex !items-center !justify-center", {
        "rounded-tr-none rounded-tl-none rounded-tl-none !p-0 flex-1 w-[3rem]":
          props.showButtons && props.buttonLayout == "stacked",
      }),
    }),
  },
  confirmpopup: {
    root: {
      className: classNames(
        "bg-white text-gray-700 border-0 rounded-md shadow-lg",
        "z-40 transform origin-center",
        "mt-3 absolute left-0 top-0",
        "before:absolute before:w-0 before:-top-3 before:h-0 before:border-transparent before:border-solid before:ml-6 before:border-x-[0.75rem] before:border-b-[0.75rem] before:border-t-0 before:border-b-white dark:before:border-b-gray-900",
        "dark:border dark:border-blue-900/40 dark:bg-gray-900  dark:text-white/80",
      ),
    },
    content: "p-5 items-center flex",
    icon: "text-2xl",
    message: "ml-4",
    footer: " justify-end flex space-x-4 px-5 py-5 pt-0 ",
    // transition: TRANSITIONS.overlay,
  },
  toast: {
    root: {
      className: classNames("w-96", "opacity-90"),
    },
    message: ({ state, index }) => ({
      className: classNames("my-4 rounded-md w-full", {
        "bg-blue-100 border-solid border-0 border-l-4 border-blue-500 text-blue-700":
          state.messages[index] &&
          state.messages[index].message.severity == "info",
        "bg-green-100 border-solid border-0 border-l-4 border-green-500 text-green-700":
          state.messages[index] &&
          state.messages[index].message.severity == "success",
        "bg-orange-100 border-solid border-0 border-l-4 border-orange-500 text-orange-700":
          state.messages[index] &&
          state.messages[index].message.severity == "warn",
        "bg-red-100 border-solid border-0 border-l-4 border-red-500 text-red-700":
          state.messages[index] &&
          state.messages[index].message.severity == "error",
      }),
    }),
    content: "flex items-center py-5 px-7",
    icon: {
      className: classNames("w-6 h-6", "text-lg mr-2"),
    },
    text: "text-base font-normal flex flex-col flex-1 grow shrink ml-4",
    summary: "font-bold block",
    detail: "mt-1 block",
    closebutton: {
      className: classNames(
        "w-8 h-8 rounded-full bg-transparent transition duration-200 ease-in-out",
        "ml-auto overflow-hidden relative",
        "flex items-center justify-center",
        "hover:bg-white/30",
      ),
    },
    transition: {
      enterFromClass: "opacity-0 translate-x-0 translate-y-2/4 translate-z-0",
      enterActiveClass: "transition-transform transition-opacity duration-300",
      leaveFromClass: "max-h-40",
      leaveActiveClass: "transition-all duration-500 ease-in",
      leaveToClass: "max-h-0 opacity-0 mb-0 overflow-hidden",
    },
  },
  fileupload: {
    input: "hidden",
    buttonbar: {
      className: classNames(
        "flex flex-wrap",
        "bg-gray-50  p-5 border border-solid border-gray-300  text-gray-700  rounded-tr-lg rounded-tl-lg gap-2 border-b-0",
      ),
    },
    basicButton: {
      className: classNames(
        "text-white bg-blue-500 border border-blue-500 p-3 px-5 rounded-md text-base",
        "overflow-hidden relative",
      ),
    },
    chooseButton: {
      className: classNames(
        "text-white bg-blue-500 border border-blue-500 p-3 px-5 rounded-md text-base",
        "overflow-hidden relative",
      ),
    },
    chooseIcon: "mr-2 inline-block",
    chooseButtonLabel: "flex-1 font-bold",
    uploadButton: {
      icon: "mr-2",
    },
    cancelButton: {
      icon: "mr-2",
    },
    content: {
      className: classNames(
        "relative",
        "bg-white  p-8 border border-gray-300  text-gray-700  rounded-b-lg",
      ),
    },
    file: {
      className: classNames(
        "flex items-center flex-wrap",
        "p-4 border border-gray-300 rounded gap-2 mb-2",
      ),
    },
    thumbnail: "shrink-0",
    fileName: "mb-2",
    fileSize: "mr-2",
    uploadIcon: "mr-2",
  },
  calendar: Calendar,
};

export { design };
