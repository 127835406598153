import { routes } from "@/routes";
import { FC } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./Topbar";
import Footer from "./Footer";
import Sidenav from "./Sidenav";
import useSidebarStore from "@/stores/SidebarStore";

const Dashboard: FC = () => {
  const { isExpanded } = useSidebarStore();

  return (
    <>
      <Topbar />
      <div className="relative flex min-h-[97vh] flex-row pt-[85px]">
        <Sidenav />
        <div
          className={`flex w-full flex-grow flex-col ${isExpanded ? "pl-[242px]" : "pl-5"}`}
        >
          <Routes>
            {routes.map(
              ({ layout, pages }) =>
                layout === "dashboard" &&
                pages.map((page) => {
                  if (!page.children) {
                    return (
                      <Route
                        path={page.path}
                        element={page.element}
                        key={page.path}
                      />
                    );
                  }
                  return (
                    // Outlets
                    <Route
                      path={page.path}
                      element={page.element}
                      key={page.path}
                    >
                      {page?.children.map((childRoute) => (
                        <Route
                          path={childRoute.path}
                          element={childRoute.element}
                          key={childRoute.path}
                        />
                      ))}
                    </Route>
                  );
                }),
            )}
          </Routes>
        </div>
      </div>
      <Footer />
    </>
  );
};
Dashboard.displayName = "DLayout";

export default Dashboard;
