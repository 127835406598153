import { Months } from "@/constants/common";
import FormModal from "@/layouts/FormModal";
import { Payroll } from "@/models/payroll";
import axiosInstance from "@/utils/apiUtil";
import { downloadPDFFromBase64 } from "@/utils/commonUtils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { FC, useEffect, useState } from "react";
import { AiFillDollarCircle } from "react-icons/ai";
import { TiDownload } from "react-icons/ti";
import { toast } from "react-toastify";
import DotIcon from "../../../../assets/dot.svg?react";
import ViewIndividualPayroll from "./ViewIndividualPayroll";
import UpdateProcessedPayrolls from "./updateProcessedPayroll";
import ViewCurrentStatus from "./currentOfficerStatus";

const tableHeaders = [
  "",
  "Ref No",
  "Employee Name",
  "Designation",
  "Payment Method",
  "EPF Number",
  "Day Shifts",
  "Total Day Shift Payment",
  "Night Shifts",
  "Total Night Shift Payment",
  "Shifts Worked",
  "Basic Salary",
  "Total Earnings",
  "Salary Advancement",
  "Uniforms",
  "Food",
  "EPF",
  "ETF",
  "Well Fare",
  "Gross Salary",
  "Total Deduction",
  "Net Salary",
  "Action",
];

const paymentMethods = [
  { label: "Bank Transfer", value: "Bank" },
  { label: "Cheque", value: "Cheque" },
  { label: "Direct Payment (Cash)", value: "Cash" },
  { label: "Online Payment", value: "Online" },
];

const Payroll: FC = () => {
  const [searchTerms, setSearchTerms] = useState("");
  //const [selectedItem, setSelectedItem] = useState(null);
  const [selectedMethods, setSelectedMethods] = useState({});
  const [isOpenAddModal, setisOpenAddModal] = useState(false);
  const [isOpenStatusModal, setisOpenStatusModal] = useState(false);
  const [isOpenUpdateModal, setisOpenUpdateModal] = useState(false);
  const [isOpenDeleteModal, setisOpenDeleteModal] = useState(false);
  const [date, setDate] = useState(new Date());
  const [selectedOfficerId, setSelectedOfficerId] = useState<string | null>(
    null,
  );
  const [checkedOfficers, setCheckedOfficers] = useState({});
  const [officerIds, setOfficerIds] = useState([]);
  const [, setProcessedOfficerIds] = useState<any>([]);
  const [isDownloadEnabled, setIsDownloadEnabled] = useState(false);
  const [filteredPayrolls, setFilteredPayrolls] = useState<Payroll[]>([]);

  const handleCheckboxChange = (officerId) => {
    setCheckedOfficers((prev) => {
      const newCheckedState = { ...prev, [officerId]: !prev[officerId] };
      const newOfficerIds = newCheckedState[officerId]
        ? [...officerIds, officerId]
        : officerIds.filter((id) => id !== officerId);

      setOfficerIds(newOfficerIds as any);

      return newCheckedState;
    });
  };

  // const handleRankChange = (e: DropdownChangeEvent) => {
  //   const { value } = e;
  //   setSelectedItem(value);
  //   filterMutation.mutate(value as any);
  // };

  //const items = Levels.map((level) => level.value);
  // const methods = paymentMethods.map((method) => method);

  const year = date.getFullYear();
  const monthIndex = date.getMonth();
  const monthString = Months[monthIndex];

  //get All payroll objects
  const { data: payroll } = useQuery({
    queryKey: ["Payroll", year, monthString],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `/payroll/${year}/${monthString}`,
      );
      if (!response) {
        throw new Error("Network response was not ok");
      }
      return response.data;
    },
  });

  //get All processed payroll objects
  const { data: procesedPayrolls } = useQuery({
    queryKey: ["ProcessedPayrolls", year, monthString],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `/payrolls/processed/${year}/${monthString}`,
      );
      if (!response) {
        throw new Error("Network response was not ok");
      }
      return response.data;
    },
  });

  //download processed payrolls
  const downloadMutation = useMutation({
    mutationFn: async (downloadData) => {
      const response = await axiosInstance.post(
        `/payroll/generate-pdf`,
        downloadData,
      );
      return downloadPDFFromBase64(
        response.data.pdf,
        `Processed-Payrolls-${monthString}-${year}`,
      );
    },
    onSuccess: async () => {
      setIsDownloadEnabled(false);
      toast.success("Payroll slips downloaded succesfully");
    },
  });

  //download processed payrolls
  const downloadFullMutation = useMutation({
    mutationFn: async (downloadData) => {
      const response = await axiosInstance.post(
        `/payroll/download`,
        downloadData,
      );
      return downloadPDFFromBase64(
        response.data.pdf,
        `Processed-Payroll-Full-${monthString}-${year}`,
      );
    },
    onSuccess: async () => {
      setIsDownloadEnabled(false);
      toast.success("Payroll sheet downloaded succesfully");
    },
  });

  //get payroll summary
  const { data: summary } = useQuery({
    queryKey: ["Summary", year, monthString],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `/payrolls/summary/${year}/${monthString}`,
      );
      if (!response) {
        throw new Error("Network response was not ok");
      }
      return response.data;
    },
  });

  const { data: categories } = useQuery({
    queryKey: ["Categories", year, monthString],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `/payrolls/summary/category/${year}/${monthString}`,
      );
      if (!response) {
        throw new Error("Network response was not ok");
      }
      return response.data;
    },
  });

  //get individual payroll object
  const { data: selectedOfficer } = useQuery({
    queryKey: ["Payroll", selectedOfficerId],
    queryFn: async () => {
      if (selectedOfficerId) {
        const response = await axiosInstance.get(
          `/payroll/${selectedOfficerId}/${year}/${monthString}`,
        );
        if (!response) {
          throw new Error("Network response was not ok");
        }
        return response.data;
      }
      return null;
    },
    enabled: !!selectedOfficerId, // Only fetch data if selectedCustomerId is truthy
  });

  const handleViewButtonClick = (officerId: string) => {
    setisOpenAddModal(true);
    setSelectedOfficerId(officerId); // Set the selected customer ID
  };

  // Function to handle delete button click
  const handleDeleteButtonClick = (officerId: string) => {
    setisOpenDeleteModal(true);
    setSelectedOfficerId(officerId); // Set the selected officer ID
  };

  //handle download button click.
  const handleDownloadButtonClick = async (data: []) => {
    await downloadMutation.mutateAsync(data as any);
  };

  //handle download button click.
  const handleDownloadFullButtonClick = async (data: []) => {
    await downloadFullMutation.mutateAsync(data as any);
  };

  //update payrollObject
  // Get the query client instance
  const queryClient = useQueryClient();

  // updating payroll
  const mutation = useMutation({
    mutationFn: async ({ officerId, food, wellFare, paymentMethod }: any) => {
      const postData = {
        food: food,
        wellFare: wellFare,
        paymentMethod: paymentMethod,
      };

      const response = await axiosInstance.patch(
        `/payroll/${officerId}/${year}/${monthString}`,
        postData,
      );
      return response.data;
    },
    onSuccess: async () => {
      // Refetch the list of officer payroll after updating individual payroll
      await queryClient.invalidateQueries("Payroll" as any);
      await queryClient.invalidateQueries("Summary" as any);
      await queryClient.invalidateQueries("Categories" as any);
      toast.success("Payroll Updated Succesfully");
    },
  });

  //deleting an individual payroll
  const deleteMutation = useMutation({
    mutationFn: (officerId) => {
      return axiosInstance.delete(
        `/payroll/${officerId}/${year}/${monthString}`,
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("Payroll" as any);
      await queryClient.invalidateQueries("Summary" as any);
    },
  });

  const handlePaymentMethod = (e, officerId, food, wellfare) => {
    setSelectedMethods((prevState) => ({
      ...prevState,
      [officerId]: e.value,
    }));
    mutation.mutateAsync({
      officerId: officerId,
      food: food,
      wellFare: wellfare,
      paymentMethod: e.value,
    });
  };

  //processing the payroll for each officerId in the newOfficerId array
  const processMutation = useMutation({
    mutationFn: async ({ officerId }: any) => {
      const response = await axiosInstance.post(
        `/payroll/${officerId}/${year}/${monthString}`,
      );
      return response.data;
    },
    onSuccess: async () => {
      //diabling the selected rows when the payrolls are processed succesfully
      setProcessedOfficerIds((prev) => [...prev, ...officerIds]);
      setIsDownloadEnabled(true);
      await queryClient.invalidateQueries("ProcessedPayrolls" as any);
      await queryClient.invalidateQueries("AdvancePayments" as any);
      toast.success("Payroll Processed Succesfully");
    },
  });

  //add payrolls
  const addPayrollMutation = useMutation({
    mutationFn: async () => {
      const response = await axiosInstance.post(
        `/payroll/${year}/${monthString}`,
      );
      return response.data;
    },
    onSuccess: async () => {
      //diabling the selected rows when the payrolls are processed succesfully
      await queryClient.invalidateQueries("Payroll" as any);
      await queryClient.invalidateQueries("Summary" as any);
      toast.success(`Payroll generated for ${monthString} Sucessfully`);
    },
  });

  const processPayrolls = async () => {
    const promises = officerIds.map((officerId) =>
      processMutation.mutateAsync({ officerId }),
    );

    try {
      await Promise.all(promises);
    } catch (error) {
      console.error("Error processing payrolls:", error);
    }
  };

  //searching name
  const { mutate: searchMutation } = useMutation({
    mutationFn: async (searchTerm) => {
      try {
        const response = await axiosInstance.post("/payroll/search", {
          searchTerm,
        });
        return response.data;
      } catch (error) {
        throw new Error("Error searching payroll by officer name");
      }
    },
    onSuccess: async (data) => {
      setFilteredPayrolls(data);
    },
  });

  //search while typing
  useEffect(() => {
    if (searchTerms) {
      searchMutation(searchTerms as any);

      const filteredPayrolls = searchTerms
        ? payroll.filter(
            (item) =>
              item.name.toLowerCase().includes(searchTerms.toLowerCase()) ||
              (item.refNo && item.refNo.includes(searchTerms)),
          )
        : payroll;

      setFilteredPayrolls(filteredPayrolls);
    } else {
      setFilteredPayrolls(payroll);
    }
  }, [searchTerms, payroll, searchMutation]);

  useEffect(() => {
    if (payroll) {
      setFilteredPayrolls(payroll);
    }
  }, [payroll]);

  const handleClearFilters = async () => {
    setSearchTerms("");
    setFilteredPayrolls(payroll);
  };

  return (
    <>
      <div className="ml-8 mt-9 flex flex-row">
        <AiFillDollarCircle className=" mr-3" size={35} />
        <span className=" text-3xl font-bold text-primary-blue ">
          {" "}
          Accounting and Payroll
        </span>
      </div>
      <div className=" mx-8 mt-12 grid grid-cols-2  gap-4 lg:grid-cols-6 lg:gap-8">
        <span className="p-input-icon-left">
          <i className="pi pi-search right-4" />
          <InputText
            placeholder="SO name or Ref No."
            className="w-full"
            value={searchTerms}
            onChange={(e) => setSearchTerms(e.target.value)}
          />
        </span>

        <Calendar
          value={date}
          onChange={(e) => setDate(e.value as any)}
          showIcon
          dateFormat="mm/yy"
          view="month"
          placeholder="Month"
          pt={{
            dropdownButton: {
              root: {
                className:
                  "bg-white !border-gray-300 border-l-0 !rounded-l-none !pl-[14px] !pr-[7px] !text-gray-900 !text-2xl",
              },
            },
          }}
        />
        <Button onClick={handleClearFilters} className=" w-fit px-8">
          Clear Filters
        </Button>

        <Button
          onClick={() => {
            setisOpenStatusModal(true);
          }}
          className="w-fit"
        >
          Get Current Officer Data
        </Button>

        <Button
          onClick={() => {
            addPayrollMutation.mutateAsync();
          }}
          className="w-fit"
        >
          Generate Payroll
        </Button>
      </div>
      <div className=" mx-8 mt-12 grid grid-cols-2  gap-4 lg:grid-cols-7 lg:gap-8">
        <div className="flex items-center space-x-3">
          <DotIcon className={`h-5 w-5 text-red-700`} />

          <span className=" mr-2 text-sm text-red-700">Shift Not Covered</span>
        </div>
        <div className="flex items-center space-x-3">
          <DotIcon className={`h-5 w-5 text-orange-500`} />

          <span className=" mr-2 text-sm text-orange-500">Missing</span>
        </div>
      </div>

      <div
        className={`w-full max-w-[100vw] overflow-y-auto ${
          filteredPayrolls && filteredPayrolls.length > 0
            ? "h-[550px]"
            : "h-[300px]"
        }`}
      >
        <table className="w-full min-w-max max-w-[100vw] rounded-[2px] text-left text-sm md:text-base">
          <thead className="sticky top-0 z-20 bg-white">
            <tr>
              {tableHeaders.map((head, index) => (
                <th
                  key={head}
                  className={`w-36 break-words p-4 text-start text-primary-text ${
                    index === 0
                      ? "sticky left-0 z-20 bg-white"
                      : index === 1
                        ? "sticky left-32 z-20 bg-white"
                        : index === 2
                          ? "sticky left-56 z-20 bg-white"
                          : ""
                  }`}
                >
                  {head}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredPayrolls &&
              filteredPayrolls.map((pay) => (
                <tr
                  className="border-border bg-tableCellBg ${ processedOfficerIds.includes(pay.officerId) ? 'bg-gray-200' : '' }`} w-full
                  border border-l-0 border-r-0 text-sm hover:bg-white
                md:text-base"
                >
                  <td className="sticky left-0 z-10 bg-white p-4">
                    <span className="flex flex-row items-center space-x-2">
                      <Checkbox
                        onChange={() => handleCheckboxChange(pay.officerId)}
                        checked={
                          !!checkedOfficers[pay.officerId] || pay.isProcessed
                        }
                        disabled={pay.isProcessed}
                      />
                      <Button
                        icon="pi pi-eye"
                        className=" !w-3 !border-none !bg-white text-3xl !text-primary-blue !shadow-none"
                        onClick={() => {
                          handleViewButtonClick(pay.officerId);
                        }}
                        //disabled={pay.isProcessed}
                      />
                    </span>
                  </td>
                  <td className="sticky left-32 z-10 bg-white p-4">
                    {" "}
                    {pay.refNo}{" "}
                  </td>
                  <td className="sticky left-56 z-10 bg-white p-4">
                    {" "}
                    {pay.name}
                  </td>
                  <td className="p-4">
                    {" "}
                    {pay.designation
                      .split(" ")
                      .map((word) => word[0])
                      .join("")}{" "}
                  </td>
                  <td className="p-4">
                    <Dropdown
                      value={
                        selectedMethods[pay.officerId] || pay.paymentMethod
                      }
                      onChange={(e) =>
                        handlePaymentMethod(
                          e,
                          pay.officerId,
                          pay.food,
                          pay.wellFare,
                        )
                      }
                      options={paymentMethods}
                      placeholder="Select here"
                      className="w-40"
                      dropdownIcon
                      disabled={pay.isProcessed}
                    />
                  </td>
                  <td className="p-4"> {pay.EPFnumber}</td>
                  <td className="p-4"> {pay.totalDayShifts} </td>
                  <td className="p-4">
                    {" "}
                    {pay.dayShiftPayment.toLocaleString()}
                  </td>
                  <td className="p-4"> {pay.totalNightShifts} </td>
                  <td className="p-4">
                    {" "}
                    {pay.nightShiftPayment.toLocaleString()}
                  </td>
                  <td className="p-4"> {pay.totalShiftsWorked} </td>
                  <td className="p-4"> {pay.basicSalary.toLocaleString()}</td>
                  <td className="p-4"> {pay.totalEarnings.toLocaleString()}</td>
                  <td className="p-4">
                    {" "}
                    {pay.salaryAdvancement.toLocaleString()}
                  </td>
                  <td className="p-4"> {pay.uniforms.toLocaleString()}</td>
                  <td className="p-4">
                    <InputNumber
                      onValueChange={(e) => e.target.value}
                      useGrouping={true}
                      minFractionDigits={2}
                      className=" !w-[100px]"
                      value={pay.food}
                      disabled={pay.isProcessed}
                      onBlur={(e) => {
                        const foodValue = parseFloat(e.target.value);
                        mutation.mutateAsync({
                          officerId: pay.officerId,
                          food: foodValue,
                          wellFare: pay.wellFare,
                          paymentMethod:
                            selectedMethods[pay.officerId] || pay.paymentMethod,
                        });
                      }}
                    />
                  </td>
                  <td className="p-4"> {pay.EPF.toLocaleString()} </td>
                  <td className="p-4"> {pay.ETF?.toLocaleString()} </td>
                  <td className="p-4">
                    <InputNumber
                      onValueChange={(e) => e.target.value}
                      useGrouping={true}
                      minFractionDigits={2}
                      className=" !w-[100px]"
                      disabled={pay.isProcessed}
                      onBlur={(e) => {
                        const wellFareValue = parseFloat(e.target.value);
                        mutation.mutateAsync({
                          officerId: pay.officerId,
                          food: pay.food,
                          wellFare: wellFareValue,
                          paymentMethod:
                            selectedMethods[pay.officerId] || pay.paymentMethod,
                        });
                      }}
                      value={pay.wellFare}
                    />
                  </td>
                  <td className="p-4"> {pay.grossSalary?.toLocaleString()}</td>
                  <td className="p-4">
                    {" "}
                    {pay.totalDeduction.toLocaleString()}{" "}
                  </td>
                  <td className="p-4"> {pay.netSalary.toLocaleString()}</td>
                  <td className="p-4">
                    <span>
                      <Button
                        icon="pi pi-trash"
                        className=" !w-3 !border-none !bg-white !text-primary-blue !shadow-none"
                        disabled={pay.isProcessed}
                        onClick={() => {
                          handleDeleteButtonClick(pay.officerId);
                        }}
                      />
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="mx-8 mt-12 flex flex-row space-x-96 font-semibold text-primary-blue">
        {/* Left Section */}
        <div className="flex flex-1 flex-col space-y-2">
          <div className=" flex flex-row space-x-4">
            <span> Total Payroll </span>
            <span> - </span>
            <span> LKR {summary?.total?.toLocaleString()} </span>
          </div>
          <div className=" flex flex-row space-x-4">
            <span> Total Cash </span>
            <span> - </span>
            <span>LKR {summary?.Cash?.toLocaleString()}</span>
          </div>
          <div className=" flex flex-row space-x-4">
            <span> Total Online </span>
            <span> - </span>
            <span> LKR {summary?.Online?.toLocaleString()}</span>
          </div>
          <div className=" flex flex-row space-x-4">
            <span> Total Cheque </span>
            <span> - </span>
            <span> LKR {summary?.Cheque?.toLocaleString()}</span>
          </div>
          <div className=" flex flex-row space-x-4">
            <span> Total Bank </span>
            <span> - </span>
            <span> LKR {summary?.Bank?.toLocaleString()}</span>
          </div>

          <div className=" !mt-9">
            <Button
              onClick={processPayrolls}
              loading={processMutation.isPending}
            >
              Proceed Payroll
            </Button>
          </div>
          <div className=" !mt-7">
            <Button
              onClick={() => {
                setisOpenUpdateModal(true);
              }}
            >
              Add Payment Details
            </Button>
          </div>
          <div className=" !mt-7">
            <Button
              onClick={() => {
                handleDownloadButtonClick(procesedPayrolls);
              }}
              className=" flex text-center"
              disabled={!isDownloadEnabled}
              loading={downloadMutation.isPending}
            >
              <TiDownload className=" mr-1" size={25} />
              Download
            </Button>
          </div>
          <div className=" !mt-7">
            <Button
              onClick={() => {
                handleDownloadFullButtonClick(payroll);
              }}
              className=" flex text-center"
              loading={downloadFullMutation.isPending}
            >
              <TiDownload className=" mr-1" size={25} />
              Download Payroll Full Document
            </Button>
          </div>
        </div>

        {/* Right Section */}
        <div className="flex flex-1 flex-col space-y-2">
          <div className="flex flex-row space-x-4">
            <span> Total Food </span>
            <span> - </span>
            <span> LKR {categories?.food?.toLocaleString()} </span>
          </div>
          <div className="flex flex-row space-x-4">
            <span> Total EPF </span>
            <span> - </span>
            <span> LKR {categories?.EPF?.toLocaleString()}</span>
          </div>
          <div className="flex flex-row space-x-4">
            <span> Total ETF </span>
            <span> - </span>
            <span> LKR {categories?.ETF?.toLocaleString()} </span>
          </div>
          <div className="flex flex-row space-x-4">
            <span> Total Welfare </span>
            <span> - </span>
            <span> LKR {categories?.wellFare?.toLocaleString()} </span>
          </div>
          <div className="flex flex-row space-x-4">
            <span> Total Uniform </span>
            <span> - </span>
            <span> LKR {categories?.uniforms?.toLocaleString()} </span>
          </div>
        </div>
      </div>

      <ViewIndividualPayroll
        title={`Individual Payroll Form`}
        isOpen={isOpenAddModal}
        onClose={() => setisOpenAddModal(false)}
        officer={selectedOfficer}
      />

      <ViewCurrentStatus
        title={`View Current Officer Shift Status`}
        isOpen={isOpenStatusModal}
        onClose={() => {
          setisOpenStatusModal(false);
        }}
        onSave={() => {
          setisOpenStatusModal(false);
        }}
      />

      <FormModal
        isOpen={isOpenDeleteModal}
        title="Delete Individual Payroll"
        onClose={() => setisOpenDeleteModal(false)}
        onSave={async () => {
          setisOpenDeleteModal(false);
          await deleteMutation.mutateAsync(selectedOfficer.officerId);
        }}
        submitText="Delete"
        submitColor="red"
        isSmall
      >
        <div>
          Are you sure you want to delete this Officer's payroll details?
        </div>
      </FormModal>

      <UpdateProcessedPayrolls
        title="Add Payment Details for Processed Payrolls"
        isOpen={isOpenUpdateModal}
        onClose={() => setisOpenUpdateModal(false)}
        year={year}
        monthString={monthString}
      />
    </>
  );
};

export default Payroll;
