import { Months } from "@/constants/common";
import { DailyRoster } from "@/models/dailyRoster";
import axiosInstance from "@/utils/apiUtil";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
//import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { FC, useEffect, useState } from "react";
import { BsFillGrid1X2Fill } from "react-icons/bs";
import { toast } from "react-toastify";
import AddEditShift from "./AddShift";

const Home: FC = () => {
  const [date, setDate] = useState(new Date());
  const [searchTerms, setSearchTerms] = useState("");
  const [officerName, setofficerName] = useState("");
  // const [selectedItem, setSelectedItem] = useState(null);
  const [isOpenAddModal, setisOpenAddModal] = useState(false);
  const [selectedCustomerId, setSelectedSCustomerId] = useState<string | null>(
    null,
  );
  const [, setShiftType] = useState("");
  const [filteredItems, setFilteredItems] = useState<DailyRoster[]>([]);
  const [tdNightValue, setTdNightValue] = useState<number | null>(null);
  const [tdDayValue, setTdDayValue] = useState(); //{}
  const [dayFocus, setDayFocus] = useState({});
  const [nightFocus, setNightFocus] = useState({});
  // const [rosters, setRosters] = useState<DailyRoster[]>([]);
  //const [loading, setLoading] = useState(false);
  //const [lastEvaluatedKey, setLastEvaluatedKey] = useState(undefined);

  const daysInMonth = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0,
  ).getDate();
  const tableHeaders = [
    "#N",
    "Name",
    "Rank",
    "Telephone",
    "Shift",
    ...Array.from({ length: daysInMonth }, (_, i) => i + 1),
  ];

  const daysInputs = Array.from({ length: daysInMonth }, (_, i) => (
    <td key={`input-${i}`} className="p-4">
      <div className="flex flex-col space-y-1">
        <InputNumber
          mode="decimal"
          min={0}
          max={1}
          step={0.5}
          pt={{
            root: {
              className:
                "!h-8 !w-16 !rounded-md !bg-secondary-inputNumberBg text-center",
            },
          }}
        />
        <InputNumber
          mode="decimal"
          min={0}
          max={1}
          step={0.5}
          pt={{
            root: {
              className:
                "!h-8 !w-10 !rounded-md !bg-secondary-inputNumberBg  text-center",
            },
          }}
        />
      </div>
    </td>
  ));

  const year = date.getFullYear();
  const monthIndex = date.getMonth();
  const monthString = Months[monthIndex];
  //
  //get All roster details
  const { data: rosters } = useQuery({
    queryKey: ["DailyRosters", year, monthString],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `/dailyRosters/${year}/${monthString}`,
      );
      if (!response) {
        throw new Error("Network response was not ok");
      }
      return response.data;
    },
  });
  //line 84 to 148 -pagination code
  // const observer = useRef();
  // const isInitialCall = useRef(true);
  // const resetPagination = useRef(false); // Ref to control reset for pagination

  // // Fetch rosters function with optional reset functionality
  // const fetchRosters = useCallback(async () => {
  //   if (lastEvaluatedKey === null && !resetPagination.current) return;

  //   setLoading(true);
  //   try {
  //     const response = await axiosInstance.get(
  //       `/dailyRosters/${year}/${monthString}`,
  //       {
  //         params: {
  //           limit: 10,
  //           lastEvaluatedKey: resetPagination.current
  //             ? undefined
  //             : lastEvaluatedKey,
  //         },
  //       },
  //     );

  //     if (!response) {
  //       throw new Error("Network response was not ok");
  //     }

  //     setRosters((prevRosters) =>
  //       resetPagination.current
  //         ? response.data.updatedCustomers
  //         : [...prevRosters, ...response.data.updatedCustomers],
  //     );

  //     setLastEvaluatedKey(response.data.lastEvaluatedKey ?? null);
  //   } catch (error) {
  //     console.error("Error fetching daily roster data:", error);
  //   } finally {
  //     setLoading(false);
  //     resetPagination.current = false;
  //   }
  // }, [lastEvaluatedKey, year, monthString]);

  // // Initial fetch effect, re-triggers when year or month changes
  // useEffect(() => {
  //   if (isInitialCall.current || resetPagination.current) {
  //     fetchRosters();
  //     isInitialCall.current = false;
  //   }
  // }, [fetchRosters]);

  // // Intersection observer for infinite scrolling
  // const lastRosterElementRef = useCallback(
  //   (node) => {
  //     if (loading || lastEvaluatedKey === null) return;
  //     if (observer.current) observer.current.disconnect();

  //     observer.current = new IntersectionObserver((entries) => {
  //       if (entries[0].isIntersecting) {
  //         fetchRosters();
  //       }
  //     });

  //     if (node) observer.current.observe(node);
  //   },
  //   [loading, lastEvaluatedKey, fetchRosters],
  // );

  //fetching summary details of all customers
  const { data: summary } = useQuery({
    queryKey: ["Summary", year, monthString],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `/dailyRoster/summary/${year}/${monthString}`,
      );
      if (!response) {
        throw new Error("Network response was not ok");
      }
      return response.data;
    },
  });

  const { data: selectedCustomer } = useQuery({
    queryKey: ["staffMembers", selectedCustomerId],
    queryFn: async () => {
      if (selectedCustomerId) {
        const response = await axiosInstance.get(
          `/dailyRosters/customers/${selectedCustomerId}/officers`,
        );
        if (!response) {
          throw new Error("Network response was not ok");
        }
        return response.data;
      }
      return null;
    },
    enabled: !!selectedCustomerId, // Only fetch data if selectedStaffId is truthy
  });

  // Function to handle edit button click
  const handleAddButtonClick = (customerId: string) => {
    setisOpenAddModal(true);
    setSelectedSCustomerId(customerId); // Set the selected customer ID
  };

  // Add roster
  // Get the query client instance
  const queryClient = useQueryClient();

  // Adding a roster
  const mutation = useMutation({
    mutationFn: async ({
      customerId,
      officerId,
      shiftType,
      shiftDate,
      allocation,
    }: any) => {
      const postData = {
        shiftType: shiftType,
        shiftDate: shiftDate,
        allocation: allocation,
      };

      const response = await axiosInstance.post(
        `shift/customer/${customerId}/so/${officerId}/${year}/${monthString}`,
        postData,
      );
      return response.data;
    },
    onSuccess: async () => {
      // Refetch the list of food suppliers after adding a new food supplier
      // resetPagination.current = true; // Trigger reset for the next fetch
      // setLastEvaluatedKey(undefined); // Clear pagination state
      // setRosters([]); // Clear existing rosters to reload from scratch
      await queryClient.invalidateQueries("DailyRosters" as any);
      await queryClient.invalidateQueries("Summary" as any);
      await queryClient.invalidateQueries("AdvancePayments" as any);
      await queryClient.invalidateQueries("currentStatus" as any);
    },
    onError: async () => {
      toast.error(
        "Could not add shift data to security officer, Please try again",
      );
    },
  });

  //search while typing
  useEffect(() => {
    if (searchTerms) {
      const filteredItems = searchTerms
        ? rosters.filter((item) =>
            item.customerName.toLowerCase().includes(searchTerms.toLowerCase()),
          )
        : rosters;

      setFilteredItems(filteredItems);
    } else {
      setFilteredItems(rosters);
    }
  }, [searchTerms, rosters]);

  //search officer name and ref no while typing
  useEffect(() => {
    if (officerName) {
      const filteredItems = officerName
        ? rosters.filter((item) =>
            item.securityOfficers.some(
              (officer) =>
                officer.name
                  .toLowerCase()
                  .includes(officerName.toLowerCase()) ||
                officer.refNo.toLowerCase().includes(officerName.toLowerCase()),
            ),
          )
        : rosters;

      setFilteredItems(filteredItems);
    } else {
      setFilteredItems(rosters);
    }
  }, [officerName, rosters]);

  useEffect(() => {
    if (rosters) {
      setFilteredItems(rosters);
    }
  }, [rosters]);

  const handleClearFilters = async () => {
    setSearchTerms("");
    setofficerName("");
    setFilteredItems(rosters);
  };

  return (
    <>
      <div className="ml-8 mt-9 flex flex-row">
        <BsFillGrid1X2Fill className=" mr-3" size={35} />
        <span className=" text-3xl font-bold text-primary-blue ">
          {" "}
          Daily Roster
        </span>
      </div>
      <div className="mx-8 mt-12 flex flex-col gap-12">
        <div className=" text-2xl font-semibold text-primary-blueLigter">
          Filters
        </div>
        <div className="grid grid-cols-2  gap-6 lg:grid-cols-5 lg:gap-10">
          <span className="p-input-icon-left">
            <i className="pi pi-search right-4" />
            <InputText
              placeholder="Company"
              className="w-full"
              value={searchTerms}
              onChange={(e) => setSearchTerms(e.target.value)}
            />
          </span>

          <span className="p-input-icon-left">
            <i className="pi pi-search right-4" />
            <InputText
              placeholder="Security Officer"
              className="w-full"
              value={officerName}
              onChange={(e) => setofficerName(e.target.value)}
            />
          </span>

          <Calendar
            value={date}
            onChange={(e) => setDate(e.value as any)}
            showIcon
            dateFormat="mm/yy"
            view="month"
            placeholder="Month"
            pt={{
              dropdownButton: {
                root: {
                  className:
                    "bg-white !border-gray-300 border-l-0 !rounded-l-none !pl-[14px] !pr-[7px] !text-gray-900 !text-2xl",
                },
              },
            }}
          />

          <Button onClick={handleClearFilters} className=" w-fit px-8">
            Clear Filters
          </Button>
        </div>

        <div className="grid h-20 w-full grid-cols-7 rounded-lg border border-gray-300 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-7">
          <div className="relative flex flex-row items-center pl-7">
            <div>
              <span className="text-xl font-semibold text-primary-blueLigter">
                TOTAL <br /> CUSTOMERS
              </span>
            </div>
            <div>
              <span className="ml-10 text-3xl font-semibold text-primary-blue">
                {summary?.totalCustomers}
              </span>
            </div>
            <div className="absolute bottom-0 left-[235px] top-0 h-full border-l border-gray-300"></div>
          </div>
          <div className="relative flex flex-row items-center pl-7">
            <div>
              <span className="text-xl font-semibold text-primary-blueLigter">
                TOTAL <br /> ALLOCATIONS
              </span>
            </div>
            <div>
              <div className="ml-10 flex flex-col items-start space-y-1">
                <span className=" text-2xl font-semibold text-primary-blue">
                  {" "}
                  {summary?.totalShifts}{" "}
                </span>
                <div className="flex flex-row items-center space-x-1">
                  <span className=" mr-1  text-xl font-semibold text-primary-blueLigter">
                    {" "}
                    D
                  </span>
                  <span className=" text-xl font-semibold text-primary-blueLigter">
                    {" "}
                    {summary?.totalDayShifts}
                  </span>
                  <span className=" text-xl font-semibold text-primary-blueLigter">
                    {" "}
                    |
                  </span>

                  <span className=" mr-1 text-xl font-semibold text-primary-blueLigter">
                    {" "}
                    N
                  </span>
                  <span className=" text-xl font-semibold text-primary-blueLigter">
                    {" "}
                    {summary?.totalNightShifts}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8 w-full overflow-auto lg:max-h-[60vh]">
          {filteredItems &&
            filteredItems?.map((roster) => (
              <div
                className=" mb-20"
                // ref={lastRosterElementRef}
                key={roster.customerId}
              >
                <div className=" flex flex-row items-center lg:space-x-20">
                  <div className=" min-w-[500px] text-3xl font-bold capitalize text-primary-blue">
                    {roster.customerName}
                  </div>
                  <div className="mt-[-5px] flex flex-col items-center">
                    <div className="flex flex-row">
                      <span className="text-2xl font-bold text-red-500">
                        {roster.customerShiftSummary.allocatedShifts}
                      </span>
                      <span className="text-2xl font-bold text-primary-blueLigter">
                        /
                      </span>
                      <span className="text-2xl font-bold text-primary-blueLigter">
                        {roster.customerShiftSummary.totalShifts}
                      </span>
                    </div>
                    <div className="flex flex-row">
                      <span className="mr-1 text-lg font-bold text-primary-blueLigter">
                        D
                      </span>
                      <span className="text-primary-blueLigte text-lg font-bold">
                        {" "}
                        {roster.customerShiftSummary.day.allocations}
                      </span>
                      <span className="text-lg font-bold text-primary-blueLigter">
                        /
                      </span>
                      <span className="mr-1 text-lg font-bold text-primary-blueLigter">
                        {roster.customerShiftSummary.day.total}
                      </span>
                      <span className="mr-1 text-lg font-bold text-primary-blueLigter">
                        |
                      </span>
                      <span className="mr-1 text-lg font-bold text-primary-blueLigter">
                        N
                      </span>
                      <span className="text-lg font-bold text-red-700">
                        {roster.customerShiftSummary.night.allocations}
                      </span>
                      <span className="text-lg font-bold text-primary-blueLigter">
                        /
                      </span>
                      <span className="text-lg font-bold text-primary-blueLigter">
                        {roster.customerShiftSummary.night.total}
                      </span>
                    </div>
                  </div>
                  <div>
                    <Button
                      onClick={() => {
                        handleAddButtonClick(roster.customerId);
                      }}
                      className="w-fit px-8  lg:ml-4"
                    >
                      <i
                        className="pi pi-plus mr-1"
                        style={{ fontSize: "1rem" }}
                      ></i>
                      Add New Shift
                    </Button>
                  </div>
                  {/* <div>
                  <i
                    className="pi pi-qrcode "
                    style={{ fontSize: "1.5rem" }}
                  ></i>
                </div>
                <div className=" text-2xl font-normal text-primary-blueLigter">
                  Food Supplier
                </div> */}
                </div>
                <div
                  className={`w-full max-w-[100vw] overflow-y-auto ${
                    roster.securityOfficers.length > 0
                      ? "max-h-[300px]"
                      : "h-auto"
                  } bg-white`}
                >
                  <table className="w-full min-w-max max-w-[100vw] rounded-[2px] text-left text-sm md:text-base">
                    <thead className="sticky top-0 z-40 bg-white">
                      <tr>
                        {tableHeaders.map((head, index) => (
                          <th
                            key={head}
                            className={`gap-8 bg-white p-4 text-center text-primary-text ${
                              index === 0
                                ? "sticky left-0 z-30 bg-white"
                                : index === 1
                                  ? "sticky left-20 z-30 w-52 bg-white"
                                  : index === 2
                                    ? "sticky left-60 z-30 bg-white"
                                    : index === 3
                                      ? "sticky left-[370px] z-30 w-36 bg-white"
                                      : index === 4
                                        ? "sticky left-[500px] z-30 bg-white"
                                        : ""
                            } overflow-hidden`}
                          >
                            {head}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {roster.securityOfficers.map((officer, index) => (
                        <tr
                          key={index}
                          className="border-border bg-tableCellBg w-full border border-l-0 border-r-0 text-sm hover:bg-white md:text-base"
                        >
                          <td className="sticky left-0 z-30 overflow-hidden bg-white  p-5">
                            {officer.refNo}
                          </td>
                          <td className="sticky left-20 z-30 w-52 overflow-hidden bg-white p-5">
                            {officer.name}
                          </td>
                          <td className="sticky left-60 z-30 overflow-hidden bg-white p-5">
                            <div className="flex flex-row items-center">
                              <div>
                                <Button
                                  icon="pi pi-times-circle"
                                  className=" !w-3 !border-none !bg-white !text-lg !text-gray-300 !shadow-none"
                                  onClick={() => {
                                    //handleDeleteButtonClick(supplier.supplierId);
                                  }}
                                />
                              </div>
                              <div>
                                {officer.rank
                                  .split(" ")
                                  .map((word) => word[0])
                                  .join("")}
                              </div>
                            </div>
                          </td>
                          <td className="sticky left-[370px] z-30 w-36 overflow-hidden bg-white p-5">
                            {officer.mobileNo}
                          </td>
                          <td className="sticky left-[500px] z-30 overflow-hidden bg-white  p-5">
                            <div className="flex flex-row items-center lg:space-x-2">
                              <div className=" flex items-center rounded border border-gray-300 p-4">
                                <div className="flex flex-col items-center space-y-1">
                                  <span className=" font-semibold text-primary-blueLigter">
                                    {" "}
                                    {
                                      officer.shiftDetails.shiftSummary.total
                                    }{" "}
                                    Shifts
                                  </span>
                                  <div className="flex flex-row items-center">
                                    <span className=" mr-1 text-sm font-semibold text-primary-blueLigter">
                                      {" "}
                                      D
                                    </span>
                                    <span className=" text-sm font-semibold text-primary-blueLigter">
                                      {" "}
                                      {officer.shiftDetails.shiftSummary.day}
                                    </span>
                                    <span className=" text-sm  font-semibold text-primary-blueLigter">
                                      {" "}
                                      /
                                    </span>
                                    <span className=" text-sm font-semibold text-primary-blueLigter">
                                      {" "}
                                      {daysInMonth}
                                    </span>
                                  </div>
                                  <div className="flex flex-row items-center">
                                    <span className=" mr-1 text-sm font-semibold text-primary-blueLigter">
                                      {" "}
                                      N
                                    </span>
                                    <span className=" text-sm font-semibold text-primary-blueLigter">
                                      {" "}
                                      {officer.shiftDetails.shiftSummary.night}
                                    </span>
                                    <span className=" text-sm  font-semibold text-primary-blueLigter">
                                      {" "}
                                      /
                                    </span>
                                    <span className=" text-sm font-semibold text-primary-blueLigter">
                                      {" "}
                                      {daysInMonth}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className=" flex flex-col  space-y-1">
                                <div className=" flex items-center rounded border border-gray-300 p-2">
                                  {" "}
                                  Day{" "}
                                </div>
                                <div className="flex w-full border border-dashed border-primary-border"></div>
                                <div className=" flex items-center rounded border border-gray-300 p-2">
                                  {" "}
                                  Night
                                </div>
                              </div>
                            </div>
                          </td>

                          {/* {daysInputs} */}
                          {daysInputs.map((_key, index: any) => {
                            const officerDayKey = `${officer.id}-${index}`; //uniquely identify officers
                            const dayIsFocused = dayFocus[officerDayKey];
                            const nightIsFocused = nightFocus[officerDayKey];
                            const date = new Date(year, monthIndex, index + 2)
                              .toISOString()
                              .substring(0, 10);
                            // const today = new Date().toISOString().substring(0, 10);  :temporarily removing for the client to test
                            // const isDisabled = date != today;

                            const dayAllocation =
                              officer.shiftDetails.shiftAllocations.dayAllocations.find(
                                (alloc) => alloc.date === date,
                              );
                            const nightAllocation =
                              officer.shiftDetails.shiftAllocations.nightAllocations.find(
                                (alloc) => alloc.date === date,
                              );
                            const dayValue = dayAllocation
                              ? dayAllocation.allocation
                              : null;
                            const nightValue = nightAllocation
                              ? nightAllocation.allocation
                              : null;

                            return (
                              <td key={`input-${index}`} className="p-4">
                                <div className="relative">
                                  <InputNumber
                                    mode="decimal"
                                    min={0}
                                    max={1}
                                    step={0.5}
                                    maxFractionDigits={1}
                                    // disabled={isDisabled}  :temporarily removing for the client to test
                                    pt={{
                                      root: {
                                        className: `!h-8 !w-20 !rounded-md !border ${dayValue ? "!border-blue-500" : "!border-red-500"}`,
                                      }, //${!isDisabled && dayValue ? '!border-blue-500' : !isDisabled ? '!border-red-500' : ''}`,   : replace this above when the function is enabled again
                                      input: {
                                        root: {
                                          className: `!border-none!text-left ${dayValue ? "!bg-blue-300" : "!bg-red-300"}`,
                                        },
                                      },
                                    }}
                                    value={dayValue}
                                    onChange={(e) => {
                                      // setTdDayValue({
                                      //   [`${date}_Day_${officerDayKey}`] : e.value,
                                      //   ...tdDayValue
                                      // });
                                      setTdDayValue(e.value as any);
                                      if (e.value !== dayValue) {
                                        setDayFocus((prev) => ({
                                          ...prev,
                                          [officerDayKey]: true,
                                        }));
                                      } else {
                                        setDayFocus((prev) => ({
                                          ...prev,
                                          [officerDayKey]: false,
                                        }));
                                      }
                                    }}
                                  />
                                  {dayIsFocused && (
                                    <i
                                      className="pi pi-check absolute right-4 top-1/2 -translate-y-1/2 transform cursor-pointer"
                                      onClick={() => {
                                        setShiftType("Day");
                                        const allocation = tdDayValue;
                                        const allocationValue = parseFloat(
                                          String(allocation),
                                        );
                                        const shiftDate = new Date(
                                          year,
                                          monthIndex,
                                          index + 2,
                                        )
                                          .toISOString()
                                          .substring(0, 10);

                                        mutation
                                          .mutateAsync({
                                            customerId: roster.customerId,
                                            officerId: officer.id,
                                            shiftType: "Day",
                                            shiftDate: shiftDate,
                                            allocation: allocationValue,
                                          })
                                          .then(() => {
                                            setDayFocus((prev) => ({
                                              ...prev,
                                              [officerDayKey]: false,
                                            }));
                                          });
                                      }}
                                    />
                                  )}
                                </div>
                                <div className="my-1 flex w-full border border-dashed border-primary-border"></div>
                                <div className="relative">
                                  <InputNumber
                                    mode="decimal"
                                    min={0}
                                    max={1}
                                    step={0.5}
                                    maxFractionDigits={1}
                                    pt={{
                                      root: {
                                        className: `!h-8 !w-20 !rounded-md !border ${nightValue ? "!border-green-500" : "!border-red-500"}`,
                                      },
                                      input: {
                                        root: {
                                          className: `!border-none!text-left ${nightValue ? "!bg-green-300" : "!bg-red-300"}`,
                                        },
                                      },
                                    }}
                                    value={nightValue}
                                    onChange={(e) => {
                                      setTdNightValue(e.value);
                                      if (e.value !== nightValue) {
                                        setNightFocus((prev) => ({
                                          ...prev,
                                          [officerDayKey]: true,
                                        }));
                                      } else {
                                        setNightFocus((prev) => ({
                                          ...prev,
                                          [officerDayKey]: false,
                                        }));
                                      }
                                    }}
                                  />
                                  {nightIsFocused && (
                                    <i
                                      className="pi pi-check absolute right-4 top-1/2 -translate-y-1/2 transform cursor-pointer"
                                      onClick={() => {
                                        setShiftType("Night");
                                        const allocation = tdNightValue;
                                        const allocationValue = parseFloat(
                                          String(allocation),
                                        );
                                        const shiftDate = new Date(
                                          year,
                                          monthIndex,
                                          index + 2,
                                        )
                                          .toISOString()
                                          .substring(0, 10);
                                        mutation
                                          .mutateAsync({
                                            customerId: roster.customerId,
                                            officerId: officer.id,
                                            shiftType: "Night",
                                            shiftDate: shiftDate,
                                            allocation: allocationValue,
                                          })
                                          .then(() => {
                                            setNightFocus((prev) => ({
                                              ...prev,
                                              [officerDayKey]: false,
                                            }));
                                          });
                                      }}
                                    />
                                  )}
                                </div>
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          {/* {loading && <p>Loading...</p>} */}
        </div>
      </div>

      <AddEditShift
        title={"Add Shift"}
        isOpen={isOpenAddModal}
        onClose={() => setisOpenAddModal(false)}
        //onSave={() => setisOpenDeleteModal(false)}
        //isEdit={modalState === modalStates.EDIT}
        shifts={selectedCustomer}
        customerId={selectedCustomerId}
      />
    </>
  );
};

export default Home;
