import FormModal from "@/layouts/FormModal";
import { Customer } from "@/models/customer";
import { modalStates } from "@/models/modelStates";
import axiosInstance from "@/utils/apiUtil";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FC, useEffect, useState } from "react";
import { FaBuildingUser } from "react-icons/fa6";
import { toast } from "react-toastify";
import AddEditCustomer from "./AddEditCustomer";

const tableHeaders = ["Customer Name", "Address", "Contact No", "Actions"];

const Customers: FC = () => {
  const [isOpenAddModal, setisOpenAddModal] = useState(false);
  const [modalState, setModalState] = useState(modalStates.ADD);
  const [isOpenDeleteModal, setisOpenDeleteModal] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(
    null,
  );
  const [filteredCustomers, setFilteredCustomers] = useState<Customer[]>([]);
  const [searchTerms, setSearchTerms] = useState("");

  //get All customers
  const { data: customers } = useQuery({
    queryKey: ["customers"],
    queryFn: async () => {
      const response = await axiosInstance.get("/customers");
      if (!response) {
        throw new Error("Network response was not ok");
      }

      return response.data;
    },
  });

  // Fetch customers by ID (only if selectedCustomerId is not null)
  const { data: selectedCustomer } = useQuery({
    queryKey: ["customers", selectedCustomerId],
    queryFn: async () => {
      if (selectedCustomerId) {
        const response = await axiosInstance.get(
          `/customers/${selectedCustomerId}`,
        );
        if (!response) {
          throw new Error("Network response was not ok");
        }
        return response.data;
      }
      return null;
    },
    enabled: !!selectedCustomerId, // Only fetch data if selectedCustomerId is truthy
  });

  // Get the query client instance
  const queryClient = useQueryClient();

  // Deleting customer
  const deleteMutation = useMutation({
    mutationFn: async (customerId) => {
      const response = await axiosInstance.delete(`/customers/${customerId}`);
      return response.data;
    },
    onSuccess: async () => {
      // Refetch the list of customers after deleting one
      await queryClient.invalidateQueries("customers" as any);
      toast.success("Customer Details Deleted Successfully");
    },
    onError: async () => {
      toast.error("Could not delete customer details, Please try again");
    },
  });

  useEffect(() => {
    if (customers) {
      setFilteredCustomers(customers);
    }
  }, [customers]);

  //search while typing
  useEffect(() => {
    if (searchTerms) {
      // searchMutation(searchTerms as any);

      const filteredCustomers = searchTerms
        ? customers.filter((item) =>
            item.name.toLowerCase().includes(searchTerms.toLowerCase()),
          )
        : customers;

      setFilteredCustomers(filteredCustomers);
    } else {
      setFilteredCustomers(customers);
    }
  }, [searchTerms, customers]);

  //handle update button click
  const handleEditButtonClick = (customerId: string) => {
    setModalState(modalStates.EDIT);
    setisOpenAddModal(true);
    setSelectedCustomerId(customerId); // Set the selected customer ID
  };
  // Function to handle delete button click
  const handleDeleteButtonClick = (customerId: string) => {
    setisOpenDeleteModal(true);
    setSelectedCustomerId(customerId); // Set the selected customer ID
  };

  return (
    <>
      <div className="ml-8 mt-9 flex flex-row">
        <FaBuildingUser className=" mr-3" size={35} />
        <span className=" text-3xl font-bold text-primary-blue ">
          {" "}
          Customers
        </span>
      </div>
      <div className="mx-8 mt-12 flex flex-col gap-12">
        <div className="mt-4 flex h-fit w-full flex-col space-y-3 lg:w-3/4 lg:flex-row lg:items-center lg:space-x-14 lg:space-y-0">
          <span className="p-input-icon-left !w-[250px]">
            <i className="pi pi-search ml-[220px]" />
            <InputText
              placeholder="Search"
              className="w-full"
              value={searchTerms}
              onChange={(e) => setSearchTerms(e.target.value)}
            />
          </span>

          <Button
            className=" ml-8 "
            onClick={() => {
              setModalState(modalStates.ADD);
              setisOpenAddModal(true);
            }}
          >
            Add New
          </Button>
        </div>

        <div className="max-h-[60vh] overflow-y-auto">
          <table className="w-full min-w-max max-w-[100vw] rounded-[2px] text-left text-sm md:text-base">
            <thead className="sticky top-0 z-10">
              <tr>
                {tableHeaders.map((head) => (
                  <th key={head} className="bg-white p-4 text-primary-text">
                    {head}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredCustomers?.map((customer) => (
                <tr className="border-border bg-tableCellBg w-full border border-l-0 border-r-0 text-sm hover:bg-white md:text-base">
                  <td className="p-4"> {customer?.name}</td>
                  <td className="p-4">{customer?.address}</td>
                  <td className="p-4">{customer?.telephoneNumber}</td>

                  <td className="p-4">
                    <span className="flex flex-row space-x-3">
                      <Button
                        icon="pi pi-pencil"
                        className=" !w-3 !border-none !bg-white !text-primary-blue !shadow-none"
                        onClick={() => {
                          handleEditButtonClick((customer as any).customerId);
                        }}
                      />
                      <Button
                        icon="pi pi-trash"
                        className=" !w-3 !border-none !bg-white !text-primary-blue !shadow-none"
                        onClick={() => {
                          handleDeleteButtonClick((customer as any).customerId);
                        }}
                      />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <AddEditCustomer
        title={`${modalState} Customer`}
        isOpen={isOpenAddModal}
        onClose={() => {
          setisOpenAddModal(false);
          setModalState("");
        }}
        //onSave={() => setisOpenDeleteModal(false)}
        isEdit={modalState === modalStates.EDIT}
        customer={selectedCustomer}
      />

      <FormModal
        isOpen={isOpenDeleteModal}
        title="Delete Customer"
        onClose={() => {
          setisOpenDeleteModal(false);
          setModalState("");
        }}
        onSave={async () => {
          setisOpenDeleteModal(false);
          await deleteMutation.mutateAsync(selectedCustomer.id);
        }}
        submitText="Delete"
        submitColor="red"
        isSmall
      >
        <div>Are you sure you want to delete this customer?</div>
      </FormModal>
    </>
  );
};

export default Customers;
