import axiosInstance from "./apiUtil";

export async function uploadFileToS3(
  file: File,
  resourceName: string,
  fileName = "snapshot.jpeg",
  fileId?: string,
) {
  const response = await axiosInstance.post("/common/upload-file", {
    resourceName,
    fileName,
    fileId,
  });

   

  if (response.data.presignedUrl && file) {
    try {
      await fetch(response.data.presignedUrl, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
        },
      });
      return response.data.filePath;
    } catch (uploadError) {
      console.error("Error uploading file", uploadError);
      console.log("File upload failed.", {
        //TODO : Add toast instead of console log
        type: "warning",
      });
      return "";
    }
  }
}

export async function downloadFileFromS3(filePath: string) {
  const response = await axiosInstance.get(
    `/common/download-file?filePath=${filePath}`,
  );

  if (response.data.presignedUrl) {
    try {
      const downloadResponse = await fetch(response.data.presignedUrl);
      if (!downloadResponse.ok)
        throw new Error(`HTTP error! status: ${response.status}`);

      const blob = await downloadResponse.blob();
      const url = URL.createObjectURL(blob);
      return [url, blob];
    } catch (error) {
      console.error("Error downloading image: ", error);
      return [null, null];
    }
  }
  return [null, null];
}

export async function deleteFileFromS3(filePath: string) {
  try {
    const response = await axiosInstance.delete(`/common/delete-file`, {
      params: { filePath: filePath },
    });

     
    return response.data;
  } catch (error) {
    console.error("Error deleting file:", error);
    return null;
  }
}

// Function to download a PDF from a base64 string
export const downloadPDFFromBase64 = (
  base64String: string,
  fileName = "document.pdf",
) => {
  const link = document.createElement("a");
  link.href = `data:application/pdf;base64,${base64String}`;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
