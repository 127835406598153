export const Roles = ["Receptionist", "Visiting Officer", "Manager", "Finance"];

export const RoleAccess = {
  Receptionist: ["Security Personnel"],
  "Visiting Officer": ["Daily Roster", "Customers", "Security Personnel"],
  Manager: ["All"], // All menu items are accessible
  Finance: ["Accounting & Payroll", "Inventory"],
  super: ["All"],
};

export const RoleDefaultRoute = {
  Receptionist: "/dashboard/security-personnel",
  "Visiting Officer": "/dashboard/daily-roster",
  Manager: "/dashboard/daily-roster",
  Finance: "/dashboard/accounting-payroll",
  super: "/dashboard/daily-roster",
};
export const Levels = [
  { label: "Chief Security Officer (CSO)", value: "Chief Security Officer" },
  { label: "Official in Charge (OIC)", value: "Official in Charge" },
  {
    label: "Senior Security Officer (SSO)",
    value: "Senior Security Officer",
  },
  {
    label: "Junior Security Officer (JSO)",
    value: "Junior Security Officer",
  },
  {
    label: "Ladies Security Officer (LSO)",
    value: "Ladies Security Officer",
  },
  { label: "Temporary Guard (T)", value: "Temporary Guard" },
];

export const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
