import FormModal from "@/layouts/FormModal";
import { Inventory } from "@/models/inventory";
import { modalStates } from "@/models/modelStates";
import axiosInstance from "@/utils/apiUtil";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FC, useEffect, useState } from "react";
import { HiSquare2Stack } from "react-icons/hi2";
import { toast } from "react-toastify";
import AddEditInventory from "./AddEditInventory";

const tableHeaders = ["Item Name", "Description", "Item Count", "Actions"];

const Inventory: FC = () => {
  const [modalState, setModalState] = useState(modalStates.ADD);
  const [isOpenAddModal, setisOpenAddModal] = useState(false);
  const [isOpenDeleteModal, setisOpenDeleteModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [filteredItems, setFilteredItems] = useState<Inventory[]>([]);
  const [searchTerms, setSearchTerms] = useState("");

  //get All inventory Items
  const { data: Items } = useQuery({
    queryKey: ["InventoryItems"],
    queryFn: async () => {
      const response = await axiosInstance.get("/inventory");
      if (!response) {
        throw new Error("Network response was not ok");
      }

      return response.data;
    },
  });

  //Fetch Items by ID (if selectedSupplierId is not null)
  const { data: selectedItem } = useQuery({
    queryKey: ["InventoryItems", selectedItemId],
    queryFn: async () => {
      if (selectedItemId) {
        const response = await axiosInstance.get(
          `/inventory/${selectedItemId}`,
        );
        if (!response) {
          throw new Error("Network response was not ok");
        }
        return response.data;
      } //cmmented to commit
      return null;
    },
    enabled: !!selectedItemId, // Only fetch data if selectedItemId is truthy
  });

  //get query client insatnce
  const queryClient = useQueryClient();

  //deleting the item member
  const deleteMutation = useMutation({
    mutationFn: (itemId) => {
      return axiosInstance.delete(`/inventory/${itemId}`);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("InventoryItems" as any);
      toast.success("Inventory Item Deleted Succesfully");
    },
    onError: async () => {
      toast.error("Could not delete inventory item, Please try again");
    },
  });

  //searching name
  const { mutate: searchMutation } = useMutation({
    mutationFn: async (searchTerm) => {
      try {
        const response = await axiosInstance.post("/inventory/search", {
          searchTerm,
        });
        return response.data;
      } catch (error) {
        throw new Error("Error searching Inventory by name");
      }
    },
    onSuccess: async (data) => {
      setFilteredItems(data);
    },
  });

  //search while typing
  useEffect(() => {
    if (searchTerms) {
      searchMutation(searchTerms as any);

      const filteredItems = searchTerms
        ? Items.filter((item) =>
            item.name.toLowerCase().includes(searchTerms.toLowerCase()),
          )
        : Items;

      setFilteredItems(filteredItems);
    } else {
      setFilteredItems(Items);
    }
  }, [searchTerms, Items, searchMutation]);

  //handle update button click
  const handleEditButtonClick = (itemId: string) => {
    setModalState(modalStates.EDIT);
    setisOpenAddModal(true);
    setSelectedItemId(itemId); // Set the selected officer ID
  };
  // Function to handle delete button click
  const handleDeleteButtonClick = (itemId: string) => {
    setisOpenDeleteModal(true);
    setSelectedItemId(itemId); // Set the selected officer ID
  };

  return (
    <>
      <div className="ml-8 mt-9 flex flex-row">
        <HiSquare2Stack className=" mr-3" size={35} />
        <span className=" text-3xl font-bold text-primary-blue ">
          {" "}
          Inventory
        </span>
      </div>
      <div className="mx-8 mt-12 flex flex-col gap-12">
        <div className="flex flex-col space-y-4">
          <div className="flex flex-row space-x-6">
            <span className="font-normal text-primary-blueLigter">
              Ledger Total Value
            </span>
            <span className=" font-semibold text-primary-blue">LKR 50,000</span>
          </div>
          <div className="flex flex-row space-x-4">
            <span className=" font-normal text-primary-blueLigter">
              Total Items at hand
            </span>
            <span className=" font-semibold text-primary-blue">1565</span>
          </div>
        </div>
        <div className="grid grid-cols-2  gap-4 lg:grid-cols-4 lg:gap-4">
          <span className="p-input-icon-left !w-[199px]">
            <i className="pi pi-search ml-[165px]" />
            <InputText
              placeholder="Item Name"
              value={searchTerms}
              onChange={(e) => setSearchTerms(e.target.value)}
            />
          </span>
          <Button
            onClick={() => {
              setModalState(modalStates.ADD);
              setisOpenAddModal(true);
            }}
            className="w-fit px-8 lg:ml-4"
          >
            Add Inventory Item
          </Button>
        </div>
        <div className="max-h-[60vh] overflow-y-auto">
          <table className="w-full min-w-max max-w-[100vw] rounded-[2px] text-left text-sm md:text-base">
            <thead className="sticky top-0 z-10">
              <tr>
                {tableHeaders.map((head) => (
                  <th key={head} className="bg-white p-4 text-primary-text">
                    {head}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredItems?.map((item) => (
                <tr className="border-border bg-tableCellBg w-full border border-l-0 border-r-0 text-sm hover:bg-white md:text-base">
                  <td className="p-4"> {item.name}</td>
                  <td className="p-4">{item.description}</td>
                  <td className="p-4">{item.variantCount}</td>
                  <td className="p-4">
                    <span className="flex flex-row space-x-3">
                      <Button
                        icon="pi pi-pencil"
                        className=" !w-3 !border-none !bg-white !text-primary-blue !shadow-none"
                        onClick={() => {
                          handleEditButtonClick(item.itemId);
                        }}
                      />
                      <Button
                        icon="pi pi-trash"
                        className=" !w-3 !border-none !bg-white !text-primary-blue !shadow-none"
                        onClick={() => {
                          handleDeleteButtonClick(item.itemId);
                        }}
                      />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <AddEditInventory
        title={`${modalState} Inventory Item`}
        isOpen={isOpenAddModal}
        onClose={() => setisOpenAddModal(false)}
        isEdit={modalState === modalStates.EDIT}
        inventory={selectedItem}
      />

      <FormModal
        isOpen={isOpenDeleteModal}
        title="Delete Inventory Item"
        onClose={() => setisOpenDeleteModal(false)}
        onSave={async () => {
          setisOpenDeleteModal(false);
          await deleteMutation.mutateAsync(selectedItem.id);
        }}
        submitText="Delete"
        submitColor="red"
        isSmall
      >
        <div>Are you sure you want to delete this Inventory Item?</div>
      </FormModal>
    </>
  );
};

export default Inventory;
