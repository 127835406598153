import Logo from "@/assets/logo.svg?react";
import UserIcon from "@/assets/user-icon.svg?react";
import { FC } from "react";
// import ArrowDownFilled from "@/assets/down-arrow-filled.svg?react";
import useAuthStore from "@/pages/auth/authStore";
import useSidebarStore from "@/stores/SidebarStore";
import { useNavigate } from "react-router-dom";

const Topbar: FC = () => {
  const { username, role } = useAuthStore((state) => state.user) || {};
  const logout = useAuthStore((state) => state.logout) || {};
  const navigate = useNavigate();
  const { toggleSidebar } = useSidebarStore();

  return (
    <div className=" fixed left-0 top-0 z-[997] flex h-[85px] w-full items-center bg-white justify-between px-3 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]  ">
      <div className=" flex items-center space-x-5 ">
        <Logo className="h-[70px] w-[76px]" />
        <i
          className="pi pi-bars cursor-pointer hover:text-primary-darkBlue"
          onClick={toggleSidebar}
        />
      </div>
      <div className="mr-6 flex items-center  space-x-5 text-black">
        <UserIcon />
        <div className="flex flex-col">
          <span>{username}</span>
          <span className="text-[11px] font-light">{role}</span>
        </div>
        <i
          title="Logout"
          className="pi pi-sign-out cursor-pointer text-lg hover:text-primary-darkBlue"
          onClick={() => {
            logout();
            navigate("/auth/sign-in");
          }}
        />
      </div>
    </div>
  );
};

Topbar.displayName = "Topbar";
export default Topbar;
