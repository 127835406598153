import { FormLabel } from "@/layouts/Label";
import ViewModal from "@/layouts/ViewModal";
import { Payroll } from "@/models/payroll";
import axiosInstance from "@/utils/apiUtil";
import { downloadPDFFromBase64 } from "@/utils/commonUtils";
import { useMutation } from "@tanstack/react-query";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { TiDownload } from "react-icons/ti";
import { toast } from "react-toastify";


interface ViewIndividualPayrollProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  loading?: boolean;
  officer?: Payroll; // Data for pre-filling the form in edit mode
  isEdit?: boolean; // Flag to indicate data loading for edit mode
}

const tableHeaders = [
  "Description Of Earning",
  "Amount in Rs.",
  "Description of Deduction",
  "Amount in Rs.",
];

const ViewIndividualPayroll: React.FC<ViewIndividualPayrollProps> = ({
  isOpen,
  onClose,
  title,
  loading,
  officer,
}) => {
  const { register, handleSubmit, reset } = useForm<Payroll>();

  const [openAdvanceData, setOpenAdvanceData] = useState(false);
  const [openInventoryData, setopenInventoryData] = useState(false);

//download processed payrolls
const downloadMutation = useMutation({
  mutationFn: async (downloadData) => {
    const response = await axiosInstance.post(
      `/individualPDF`,
      downloadData,
    );
    return downloadPDFFromBase64(
      response.data.pdf,
      `Individual Payroll`,
    );
  },
  onSuccess: async () => {
    toast.success("Payroll slips downloaded successfully");
  },
});

  useEffect(() => {
    if (isOpen) {
      reset({
        name: officer?.name,
        refNo: officer?.refNo,
        designation: officer?.designation,
        nic: officer?.nic,
        mobileNo: officer?.mobileNo,
      });
    }
  }, [officer, reset, isOpen]);

  const onSubmit: SubmitHandler<Payroll> = async () => {
   
    reset();
    onClose();
  };

  const totalShiftPayment =
    (officer?.dayShiftPayment ?? 0) + (officer?.nightShiftPayment ?? 0);

  return (
    <ViewModal
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      onSave={handleSubmit(onSubmit)}
      isLoading={loading}
    >
  
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col space-y-10">
            <div className="text-lg font-semibold text-primary-text">
              Security Officer Details
            </div>
            <div>
              <FormLabel label="SO Name" />
              <InputText
                {...register("name", {})}
                className="!bg-inputBg"
                readOnly
              />
            </div>
            <div className="grid grid-cols-1 gap-2 lg:grid-cols-2 lg:gap-10">
              <div>
                <FormLabel label="Designation" />
                <InputText
                  {...register("designation", {})}
                  className="!bg-inputBg"
                  readOnly
                />
              </div>

              <div>
                <FormLabel label="NIC number" />
                <InputText
                  {...register("nic", {})}
                  className="!bg-inputBg"
                  readOnly
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-2 lg:grid-cols-2 lg:gap-10">
              <div>
                <FormLabel label="Ref No" />
                <InputText
                  {...register("refNo", {})}
                  className="!bg-inputBg"
                  readOnly
                />
              </div>
              <div>
                <FormLabel label="Mobile Number" />
                <InputText
                  {...register("mobileNo", {})}
                  className="!bg-inputBg"
                  readOnly
                />
              </div>
            </div>

            <div className="text-lg font-semibold text-primary-text">
              Shift Breakdown
            </div>

            <div className="grid grid-cols-1 gap-2 lg:grid-cols-2 lg:gap-10">
              <div className=" flex flex-col space-y-4">
                <div className="flex flex-row space-x-4">
                  <span>Total Day Shifts : </span>
                  <span> {officer?.totalDayShifts} </span>
                </div>
                <div className="flex flex-row space-x-4">
                  <span>Total Night Shifts : </span>
                  <span> {officer?.totalNightShifts} </span>
                </div>
              </div>
              <div className=" flex flex-col space-y-4">
                <div className="flex flex-row space-x-4">
                  <span>Day Shift Payment : </span>
                  <span>LKR {officer?.dayShiftPayment.toLocaleString()} </span>
                </div>
                <div className="flex flex-row space-x-4">
                  <span>Night Shift Payment : </span>
                  <span>
                    LKR {officer?.nightShiftPayment.toLocaleString()}{" "}
                  </span>
                </div>
              </div>
            </div>

            <div className="text-lg font-semibold text-primary-text">
              Payroll Breakdown
            </div>

            <div className="flex flex-col">
              <table>
                <thead>
                  <tr>
                    {tableHeaders.map((head) => (
                      <th
                        key={head}
                        className="border border-b-2 border-l-0 border-r-0 border-t-0 border-primary-border bg-white p-4 text-start text-sm font-semibold text-primary-text"
                      >
                        {head}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className=" p-4 font-semibold"> Basic Salary</td>
                    <td className=" p-4">
                      {" "}
                      {(officer?.EPF as any) > 0
                        ? 
                          officer?.grossSalaryEPF?.toLocaleString()
                        : officer?.basicSalary.toLocaleString()}
                    </td>
                    <td className=" p-4 font-semibold">Well Fare </td>
                    <td className=" p-4">
                      {" "}
                      {officer?.wellFare.toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td className=" p-4 font-semibold">
                      Total Shift Allowances{" "}
                    </td>
                    <td className=" p-4">
                      {" "}
                      {totalShiftPayment.toLocaleString()}
                    </td>
                    <td className=" p-4 font-semibold">Food </td>
                    <td className=" p-4"> {officer?.food.toLocaleString()}</td>
                  </tr>
                  {officer?.EPF === 0 ? null : (
                    <tr>
                      <td className=" p-4 font-semibold">
                        Budgetary Allowance 1
                      </td>
                      <td className=" p-4"> {officer?.budgetaryAllowance1?.toLocaleString()}</td>
                      <td className=" p-4 font-semibold">EPF amount </td>
                      <td className=" p-4"> {officer?.EPF.toLocaleString()}</td>
                    </tr>
                  )}
                  {officer?.ETF === 0 ? null : (
                    <tr>
                      <td className=" p-4 font-semibold">
                        Budgetary Allowance 2{" "}
                      </td>
                      <td className=" p-4"> {officer?.budgetaryAllowance2?.toLocaleString()}</td>
                      <td className=" p-4 font-semibold"> ETF amount</td>
                      <td className=" p-4"> {officer?.ETF.toLocaleString()}</td>
                    </tr>
                  )}
                  {officer?.salaryAdvancement === 0 ? null : (
                    <tr>
                      <td className=" p-4 font-semibold"> </td>
                      <td className=" p-4"> </td>
                      <td className=" p-4 font-semibold">
                        {" "}
                        Salary Advancement
                      </td>
                      <td className=" p-4">
                        <div className="flex flex-row items-center space-x-8">
                          <span>
                            {officer?.salaryAdvancement.toLocaleString()}
                          </span>
                          <span>
                            <Button
                              type="button"
                              icon={
                                openAdvanceData
                                  ? "pi pi-chevron-up"
                                  : "pi pi-chevron-down"
                              }
                              className="!w-2 !border-none !bg-white !p-3 !text-primary-blue !shadow-none"
                              onClick={() => {
                                openAdvanceData
                                  ? setOpenAdvanceData(false)
                                  : setOpenAdvanceData(true);
                              }}
                            />
                          </span>
                        </div>
                      </td>
                    </tr>
                  )}
                  {officer?.salaryAdvancement != 0 && openAdvanceData && (
                    <tr className=" w-full">
                      <td className=" p-4"></td>
                      <td className=" p-4"></td>
                      <td colSpan={2} className="p-4">
                        <table>
                          <thead className="border border-b-2 border-l-0 border-r-0 border-t-0 border-primary-border bg-white p-4 text-start text-sm font-semibold text-primary-text">
                            <th className=" p-4">Date</th>
                            <th className=" p-4">Amount</th>
                            <th className=" p-4">Payment Method</th>
                          </thead>
                          <tbody>
                            {officer?.advancePaymemntDetails?.map((pay) =>
                              pay.status === "APPROVED" &&
                              pay.isProcessed === false ? (
                                <tr>
                                  <td className="p-4">
                                    {new Date(pay.date)
                                      .toLocaleDateString("en-CA", {
                                        year: "numeric",
                                        month: "2-digit",
                                        day: "2-digit",
                                      })
                                      .replace(/-/g, " ")}
                                  </td>
                                  <td className="p-4">LKR {pay.payment}</td>
                                  <td className="p-4">{pay.payMethod}</td>
                                </tr>
                              ) : null,
                            )}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                  {officer?.uniforms === 0 ? null : (
                    <tr>
                      <td className=" p-4 font-semibold"> </td>
                      <td className=" p-4"> </td>
                      <td className=" p-4 font-semibold">Uniform </td>
                      <td className=" p-4">
                        <div className="flex flex-row items-center space-x-8">
                          <span>{officer?.uniforms.toLocaleString()}</span>
                          <span>
                            <Button
                              type="button"
                              icon={
                                openInventoryData
                                  ? "pi pi-chevron-up"
                                  : "pi pi-chevron-down"
                              }
                              className="!w-2 !border-none !bg-white !p-3 !text-primary-blue !shadow-none"
                              onClick={() => {
                                openInventoryData
                                  ? setopenInventoryData(false)
                                  : setopenInventoryData(true);
                              }}
                            />
                          </span>
                        </div>
                      </td>
                    </tr>
                  )}
                  {officer?.uniforms != 0 && openInventoryData && (
                    <tr className=" w-full">
                      <td className=" p-4"></td>
                      <td className=" p-4"></td>
                      <td colSpan={2} className="p-4">
                        <table>
                          <thead className="border border-b-2 border-l-0 border-r-0 border-t-0 border-primary-border bg-white p-4 text-start text-sm font-semibold text-primary-text">
                            <th className=" p-4">Date</th>
                            <th className=" p-4">Item Name</th>
                            <th className=" p-4">Item Variant</th>
                            <th className=" p-4">Total Price</th>
                          </thead>
                          <tbody>
                            {officer?.inventoryDetails?.map((item) => (
                              <tr>
                                <td className=" p-4">
                                  {" "}
                                  {new Date(item.date)
                                    .toLocaleDateString("en-CA", {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                    })
                                    .replace(/-/g, " ")}
                                </td>
                                <td className=" p-4">{item.itemName}</td>
                                <td className=" p-4">{item.itemVarient}</td>
                                <td className=" p-4">
                                  {item.totalPrice.toLocaleString()}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              {/*CODE TO ENTER SALARY DETAILS */}
              <div className=" mx-4 mt-12 flex justify-between font-semibold text-primary-blue">
                <div className=" flex flex-row space-x-4">
                  <span> Gross Salary </span>
                  <span> - </span>
                  <span>
                    {" "}
                    LKR{" "}
                    {officer?.grossSalary?.toLocaleString()}
                  </span>
                </div>

                <div className=" flex flex-row space-x-4">
                  <span> Total Deduction </span>
                  <span> - </span>
                  <span> LKR {officer?.totalDeduction.toLocaleString()}</span>
                </div>

                <div className=" flex flex-row space-x-4">
                  <span> Net Salary </span>
                  <span> - </span>
                  <span>
                    {" "}
                    LKR{" "}
                    {officer?.netSalary.toLocaleString()}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </form>
      
      <div className="mt-5 flex justify-end">
        <Button
          onClick={() => {
            downloadMutation.mutateAsync(officer as any);
          }}
          loading={downloadMutation.isPending}
        >
          {" "}
          <TiDownload className="mr-1" size={25} />
          Download
        </Button>
      </div>
    </ViewModal>
  );
};

export default ViewIndividualPayroll;