import { create } from "zustand";
import { jwtDecode } from "jwt-decode";
import { User } from "@/models/user";

interface AuthState {
  user: User | null;
  username: string | null;
}

interface AuthStore extends AuthState {
  storeUser: (token: string) => void;
  logout: () => void;
}

//initial state
const initialState: AuthState = {
  user: null,
  username: null,
};

// Auth store
const useAuthStore = create<AuthStore>((set) => ({
  // State
  ...initialState,

  // Actions
  storeUser: (token) => {
    localStorage.setItem("token", token);
    const decodedUser = jwtDecode(token) as User;
    set({ user: decodedUser });
  },
  logout: () => {
    localStorage.removeItem("token");
    set({ ...initialState });
  },
}));

export default useAuthStore;
