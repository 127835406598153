import { FormLabel } from "@/layouts/Label";
import ViewModal from "@/layouts/ViewModal";
import { SecurityPerson } from "@/models/securityPerson";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useEffect, useRef, useState } from "react";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import PhotoModal from "./PhotoModal";
//import { FileUpload } from "primereact/fileupload";
import { Levels } from "@/constants/common";
import axiosInstance from "@/utils/apiUtil";
import { downloadFileFromS3, uploadFileToS3 } from "@/utils/commonUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Calendar } from "primereact/calendar";
import { FileUpload } from "primereact/fileupload";
import { InputNumber } from "primereact/inputnumber";
import { ProgressBar } from "primereact/progressbar";

interface ViewSecurityPersonProps {
  onSave?: (formData: SecurityPerson) => Promise<void>;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  loading?: boolean;
  securityPerson?: SecurityPerson; // Data for pre-filling the form in edit mode
  isEdit?: boolean; // Flag to indicate data loading for edit mode
}

const tableHeaders = [
  "Item Description",
  "Item Varient",
  "Qty",
  "Unit Price",
  "Total Price",
];

const ViewSecurityPerson: React.FC<ViewSecurityPersonProps> = ({
  isOpen,
  onClose,
  title,
  securityPerson,
  isEdit,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    getValues,
    //formState: { /*errors*/ }, //commented to commit
    control,
  } = useForm<SecurityPerson>();
  const toast = useRef(null);

  const [isOpenPhotoModal, setisPhotoAddModal] = useState(false);
  const [isEPFEnabled, setIsEPFEnabled] = useState(false);
  const [capturedScreenshot, setCapturedScreenshot] = useState<string | null>();
  const [profilePhoto] = useState<string | null>();
  const [nicFile, setNICFile] = useState<File | null>();
  const [nicFileB, setNICFileB] = useState<File | null>();
  const [gsnFile, setGSNFile] = useState<File | null>();

  const [bcFile, setBCFile] = useState<File | null>();
  const [bc2File, setBC2File] = useState<File | null>();
  const [birthCFrFile, setBirthCFrFile] = useState<File | null>();
  const [birthCFile, setBirthCFile] = useState<File | null>();

  const [otherDocs, setOtherDocs] = useState<File[] | null>();

  const gsnFileInputRef = useRef(null);
  const birthCFileInputRef = useRef(null);
  const otherDocsRef = useRef(null);

  const [nicFileUrl, setNICFileUrl] = useState<string | null>("");
  const [nicFileBUrl, setNICFileBUrl] = useState<string | null>("");
  const [, setBCFileUrl] = useState<string | null>(""); //bcFileUrl
  const [, setBC2FileUrl] = useState<string | null>(""); //bc2FileUrl
  const [birthCFrUrl, setBirthCFrUrl] = useState<string | null>("");
  const [birthCFileUrl, setBirthCFileUrl] = useState<string | null>("");
  const [gsnFileUrl, setGSNFileUrl] = useState<string | null>("");
  const [filePathsMap, setFilePathsMap] = useState({});
  const [deleteFileInProgress, setDeleteFileInProgress] = useState(false);

  const interviewStatus = watch("interviewStatus");
  const [hasFileChanged, setHasFileChanged] = useState({
    nic: false,
    nicB: false,
    birthCertificateBack: false,
    bankCopy: false,
    bankCopy2: false,
    birthCertificateFront: false,
    gramaNiladhariDiv: false,
  });

  useEffect(() => {
    if (isOpen) {
      if (!isEdit) {
        resetState();
        reset({
          profilePic: "",
          name: "",
          address: "",
          mobileNo: "",
          appliedLevel: "",
          officerLevel: "",
          nic: {
            nicValue: "",
            issuedDate: "",
          },
          nicBack: {
            nicValue: "",
            // issuedDate: "",
          },
          gramaNiladhariDiv: {
            val: "",
          },
          birthCertificateBack: "",
          birthCertificateFront: "",
          bankName: "",
          bankBranch: "",
          accNo: undefined,
          bankName2: "",
          bankBranch2: "",
          accNo2: undefined,
          interviewStatus: "PENDING",
          activeStatus: false,
          bloodGroup: "",
          illnessDetails: "",
          isEPF: false,
          EPFnumber: "",
          basicSalary: 0,
          dayShiftPayment: 0,
          nightShiftPayment: 0,
          inventoryItems: [],
        });
      }

      if (securityPerson && isEdit) {
        if (securityPerson?.nic?.s3Path) {
          setFileDataFromS3(
            securityPerson?.nic?.s3Path,
            setNICFile,
            setNICFileUrl,
          );
        } else {
          resetNICFile();
        }

        if (securityPerson?.nicBack?.s3Path) {
          setFileDataFromS3(
            securityPerson?.nicBack?.s3Path,
            setNICFileB,
            setNICFileBUrl,
          );
        } else {
          resetNICBFile();
        }

        if (securityPerson?.gramaNiladhariDiv?.s3Path) {
          setFileDataFromS3(
            securityPerson?.gramaNiladhariDiv?.s3Path,
            setGSNFile,
            setGSNFileUrl,
          );
        } else {
          resetGSNFile();
        }

        if (securityPerson?.bankCopy?.s3Path) {
          setFileDataFromS3(
            securityPerson?.bankCopy?.s3Path,
            setBCFile,
            setBCFileUrl,
          );
        } else {
          resetBCFile();
        }

        if (securityPerson?.bankCopy2?.s3Path) {
          setFileDataFromS3(
            securityPerson?.bankCopy2?.s3Path,
            setBC2File,
            setBC2FileUrl,
          );
        } else {
          resetBC2File();
        }

        if (securityPerson?.birthCertificateBack?.s3Path) {
          setFileDataFromS3(
            securityPerson?.birthCertificateBack?.s3Path,
            setBirthCFile,
            setBirthCFileUrl,
          );
        } else {
          resetBirthCFile();
        }

        if (securityPerson?.birthCertificateFront?.s3Path) {
          setFileDataFromS3(
            securityPerson?.birthCertificateFront?.s3Path,
            setBirthCFrFile,
            setBirthCFrUrl,
          );
        } else {
          resetBirthCertificateFrFile();
        }

        if (securityPerson?.profilePic) {
          setFileDataFromS3(
            securityPerson?.profilePic,
            undefined,
            setCapturedScreenshot,
          );
        } else {
          setCapturedScreenshot(null);
        }

        if (
          securityPerson?.otherDocuments &&
          securityPerson?.otherDocuments?.length > 0
        ) {
          setFilesDataFromS3(securityPerson?.otherDocuments, (files) => {
            updateFiles(files);
          });
        }

        //setting the state of isEPFEnabled
        setIsEPFEnabled(securityPerson.isEPF);

        reset({
          ...securityPerson,
          dob: new Date(securityPerson.dob || ""),
          nic: {
            nicValue: securityPerson.nic?.nicValue,
            issuedDate: new Date(securityPerson.nic?.issuedDate || ""),
          },
          nicBack: {
            nicValue: securityPerson.nicBack?.nicValue,
            // issuedDate: new Date(securityPerson.nic.issuedDate || ""),
          },
          birthCertificateFront: "",
          birthCertificateBack: "",
          bankCopy: "",
          bankCopy2: "",
          isEPF: securityPerson.isEPF,
          EPFnumber: securityPerson.EPFnumber,
          basicSalary: securityPerson.basicSalary,
          dayShiftPayment: securityPerson.dayShiftPayment,
          nightShiftPayment: securityPerson.nightShiftPayment,
          inventoryItems: securityPerson.inventoryItems?.map((item: any) => {
            return {
              ...item,
              itemName: { itemName: item.itemName, itemId: item.itemId },
            };
          }),
          appliedLevel: Levels.find(
            (level) => level.value === securityPerson.appliedLevel,
          )?.label,
          officerLevel: Levels.find(
            (level) => level.value === securityPerson.officerLevel,
          )?.label,
        });
      } else {
        reset();
      }
    }
  }, [securityPerson, reset, isOpen, isEdit]);

  // Get the query client instance
  const queryClient = useQueryClient();

  // Adding security officer
  const resetState = () => {
    setCapturedScreenshot(null);
    setNICFile(null);
    setNICFileB(null);
    setBCFile(null);
    setBC2File(null);
    setBirthCFrFile(null);
    setBirthCFile(null);
    setGSNFile(null);
    setDeleteFileInProgress(false);

    setNICFileUrl(null);
    setNICFileBUrl(null);
    setBCFileUrl(null);
    setBC2FileUrl(null);
    setBirthCFrUrl(null);
    setBirthCFileUrl(null);
    setGSNFileUrl(null);

    setHasFileChanged({
      nic: false,
      nicB: false,
      birthCertificateBack: false,
      bankCopy: false,
      bankCopy2: false,
      birthCertificateFront: false,
      gramaNiladhariDiv: false,
    });
  };

  const resetNICFile = () => {
    setNICFile(null);
    setNICFileUrl(null);
    setHasFileChanged((prevState) => ({ ...prevState, nic: false }));
  };

  const resetNICBFile = () => {
    setNICFileB(null);
    setNICFileBUrl(null);
    setHasFileChanged((prevState) => ({ ...prevState, nicB: false }));
  };

  const resetBCFile = () => {
    setBCFile(null);
    setBCFileUrl(null);
    setHasFileChanged((prevState) => ({ ...prevState, bankCopy: false }));
  };

  const resetBC2File = () => {
    setBC2File(null);
    setBC2FileUrl(null);
    setHasFileChanged((prevState) => ({ ...prevState, bankCopy2: false }));
  };

  const resetBirthCertificateFrFile = () => {
    setBirthCFrFile(null);
    setBirthCFrUrl(null);
    setHasFileChanged((prevState) => ({
      ...prevState,
      birthCertificateFront: false,
    }));
  };

  const resetBirthCFile = () => {
    setBirthCFile(null);
    setBirthCFileUrl(null);
    setHasFileChanged((prevState) => ({
      ...prevState,
      birthCertificateBack: false,
    }));
  };

  const resetGSNFile = () => {
    setGSNFile(null);
    setGSNFileUrl(null);
    setHasFileChanged((prevState) => ({
      ...prevState,
      gramaNiladhariDiv: false,
    }));
  };

  //adding security officer
  const mutation = useMutation({
    mutationFn: async (securityPerson) => {
      const response = await axiosInstance.post(
        "/securityOfficers",
        securityPerson,
      );
      return response.data;
    },
    onSuccess: async () => {
      // Refetch the list of officers after adding a new officer
      await queryClient.invalidateQueries("securityOfficers" as any);
    },
  });

  const updateMutation = useMutation({
    mutationFn: ({ officerId, securityPerson }: any) => {
      return axiosInstance.patch(
        `/securityOfficers/${officerId}`,
        securityPerson,
      );
    },
    onSuccess: async () => {
      // Refetch the list of officers after updating
      await queryClient.invalidateQueries("securityOfficers" as any);
    },
  });

  const uploadMutation = useMutation({
    mutationFn: async ({
      file,
      folderName,
      fileId,
    }: {
      file: File;
      folderName: string;
      fileId?: string;
    }) => {
      return await uploadFileToS3(file, folderName, file.name, fileId);
    },
    onError: (error) => {
      console.error("Upload failed", error);
    },
  });

  const { fields: inventoryItems } = useFieldArray({
    control,
    name: "inventoryItems",
  });

  const onSubmit: SubmitHandler<SecurityPerson> = async (formData) => {
    const transformInventoryItems = formData.inventoryItems.map((data) => {
      const {
        itemName: { itemId, itemName },
        ...rest
      } = data;
      return { ...rest, itemId, itemName };
    });
    let newFormData = { ...formData, inventoryItems: transformInventoryItems };
    const appLvl = formData?.appliedLevel;
    const offLvl = formData?.officerLevel;

    newFormData.appliedLevel =
      Levels.find((level) => level.label === appLvl)?.value || "";
    newFormData.officerLevel =
      Levels.find((level) => level.label === offLvl)?.value || "";

    if (!formData?.otherDocuments || formData?.otherDocuments?.length === 0) {
      newFormData.otherDocuments = [];
    }
    if (nicFile) {
      const data = hasFileChanged.nic
        ? await uploadMutation.mutateAsync({
            file: nicFile,
            folderName: "security-officer/nic",
            fileId: securityPerson?.nic?.s3Path?.split("/")[2],
          })
        : securityPerson?.nic?.s3Path;

      const nicObj = {
        fileName: nicFile.name,
        nicValue: formData.nic.nicValue,
        issuedDate: formData.nic.issuedDate,
        s3Path: data,
      };
      newFormData = { ...newFormData, nic: nicObj };
    } else {
      const nicObj = {
        fileName: null,
        nicValue: formData.nic.nicValue,
        issuedDate: formData.nic.issuedDate,
        s3Path: null,
      };
      newFormData = { ...newFormData, nic: nicObj };
    }

    if (nicFileB) {
      const data = hasFileChanged.nicB
        ? await uploadMutation.mutateAsync({
            file: nicFileB,
            folderName: "security-officer/nicBack",
            fileId: securityPerson?.nicBack?.s3Path?.split("/")[2],
          })
        : securityPerson?.nic?.s3Path;

      const nicObj = {
        fileName: nicFileB.name,
        nicValue: formData.nicBack.nicValue,
        // issuedDate: formData.nicBack.issuedDate,
        s3Path: data,
      };
      newFormData = { ...newFormData, nicBack: nicObj };
    } else {
      const nicObj = {
        fileName: null,
        nicValue: formData.nicBack.nicValue,
        // issuedDate: formData.nic.issuedDate,
        s3Path: null,
      };
      newFormData = { ...newFormData, nicBack: nicObj };
    }

    if (gsnFile) {
      const data = hasFileChanged.gramaNiladhariDiv
        ? await uploadMutation.mutateAsync({
            file: gsnFile,
            folderName: "security-officer/gsn",
            fileId: securityPerson?.gramaNiladhariDiv?.s3Path?.split("/")[2],
          })
        : securityPerson?.gramaNiladhariDiv?.s3Path;

      const gsnObj = {
        fileName: gsnFile.name,
        val: formData.gramaNiladhariDiv.val,
        s3Path: data,
      };
      newFormData = { ...newFormData, gramaNiladhariDiv: gsnObj };
    } else {
      const gsnObj = {
        fileName: null,
        val: formData.gramaNiladhariDiv.val,
        s3Path: null,
      };
      newFormData = { ...newFormData, gramaNiladhariDiv: gsnObj };
    }

    if (bcFile) {
      const data = hasFileChanged.bankCopy
        ? await uploadMutation.mutateAsync({
            file: bcFile,
            folderName: "security-officer/bank-copy",
            fileId: securityPerson?.bankCopy?.s3Path?.split("/")[2],
          })
        : securityPerson?.bankCopy?.s3Path;

      const bcObj = {
        fileName: bcFile.name,
        s3Path: data,
      };
      newFormData = { ...newFormData, bankCopy: bcObj };
    } else {
      const bcObj = {
        fileName: null,
        s3Path: null,
      };
      newFormData = { ...newFormData, bankCopy: bcObj };
    }

    if (bc2File) {
      const data = hasFileChanged.bankCopy2
        ? await uploadMutation.mutateAsync({
            file: bc2File,
            folderName: "security-officer/bank-copy-2",
            fileId: securityPerson?.bankCopy2?.s3Path?.split("/")[2],
          })
        : securityPerson?.bankCopy2?.s3Path;

      const bc2Obj = {
        fileName: bc2File.name,
        s3Path: data,
      };
      newFormData = { ...newFormData, bankCopy2: bc2Obj };
    } else {
      const bc2Obj = {
        fileName: null,
        s3Path: null,
      };
      newFormData = { ...newFormData, bankCopy2: bc2Obj };
    }

    if (birthCFile) {
      const data = hasFileChanged.birthCertificateBack
        ? await uploadMutation.mutateAsync({
            file: birthCFile,
            folderName: "security-officer/birth-certificate",
            fileId: securityPerson?.birthCertificateBack?.s3Path?.split("/")[2],
          })
        : securityPerson?.birthCertificateBack?.s3Path;

      const bcObj = {
        fileName: birthCFile.name,
        s3Path: data,
      };
      newFormData = { ...newFormData, birthCertificateBack: bcObj };
    } else {
      const bcObj = {
        fileName: null,
        s3Path: null,
      };
      newFormData = { ...newFormData, birthCertificateBack: bcObj };
    }

    if (birthCFrFile) {
      const data = hasFileChanged.birthCertificateFront
        ? await uploadMutation.mutateAsync({
            file: birthCFrFile,
            folderName: "security-officer/birth-certificate-front",
            fileId:
              securityPerson?.birthCertificateFront?.s3Path?.split("/")[2],
          })
        : securityPerson?.birthCertificateFront?.s3Path;

      const bcFrObj = {
        fileName: birthCFrFile.name,
        s3Path: data,
      };

      newFormData = { ...newFormData, birthCertificateFront: bcFrObj };
    } else {
      const bcFrObj = {
        fileName: null,
        s3Path: null,
      };
      newFormData = { ...newFormData, birthCertificateFront: bcFrObj };
    }

    if (capturedScreenshot) {
      // Convert base64 string from webcam to blob
      const fetchResponse = await fetch(capturedScreenshot);
      const blob = await fetchResponse.blob();

      const file = new File([blob], "snapshot.jpeg", { type: "image/jpeg" });

      const profilpicePath = await uploadFileToS3(
        file,
        `security-officer/profile-photos`,
        file.name,
        securityPerson?.profilePic?.split("/")[2],
      );

      newFormData = { ...newFormData, profilePic: profilpicePath };
    } else if (profilePhoto) {
      const fetchResponse = await fetch(profilePhoto);
      const blob = await fetchResponse.blob();

      const file = new File([blob], "snapshot.jpeg", { type: "image/jpeg" });

      const profilpicePath = await uploadFileToS3(
        file,
        `security-officer/profile-photos`,
        file.name,
        securityPerson?.profilePic?.split("/")[2],
      );

      newFormData = { ...newFormData, profilePic: profilpicePath };
      setCapturedScreenshot(profilpicePath);
    }

    reset();
    onClose();
  };

  const StatusOptions = ["REJECTED", "APPROVED", "PENDING"];

  const levelOptions = Levels.map((level) => level.label);

  //saving and displaying the screenshot
  const handleSaveScreenshot = async (screenshot: string) => {
    setCapturedScreenshot(screenshot);
    setisPhotoAddModal(false);
  };

  //clearing the screenshot when the modal is closed
  const handleCloseModal = () => {
    // setCapturedScreenshot(null);
    setisPhotoAddModal(false);
  };

  useEffect(() => {}, [hasFileChanged]);
  const handleFileSelect = (e, setter, urlSetter, fieldName?) => {
    const file: File = e.target.files[0];
    if (e.target.files.length > 0) {
      setter(file);
      setFileUrl(file, urlSetter);
      if (fieldName) {
        fieldName !== "gramaNiladhariDiv" && setValue(fieldName, file.name);
        setHasFileChanged((hasFileChanged) => ({
          ...hasFileChanged,

          [fieldName]: true,
        }));
      }

      //only nic has no fieldName passed
      if (!fieldName) {
        setHasFileChanged((hasFileChanged) => ({
          ...hasFileChanged,
          nic: true,
          nicB: true,
        }));
      }
    }
  };

  const setFileUrl = (file: File, setter) => {
    const fileUrl = URL.createObjectURL(file);
    setter(fileUrl);
  };

  const viewFile = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  const setFilesDataFromS3 = async (paths, fileSetter) => {
    if (!paths || paths.length === 0) {
      return;
    }
    const newFilePathsMap = {};

    // Download each file and create a File object
    const downloadPromises = paths.map(async (path) => {
      const [, blob] = await downloadFileFromS3(path);
      if (blob) {
        const fileName = path.split("/").pop() || "downloadedFile";
        newFilePathsMap[fileName] = path;

        return new File([blob], fileName, { type: (blob as Blob).type });
      }
      return null;
    });

    try {
      const files = await Promise.all(downloadPromises);
      const validFiles = files.filter((file) => file !== null);
      setFilePathsMap(newFilePathsMap);

      if (fileSetter && validFiles.length > 0) {
        fileSetter(validFiles);
      }
    } catch (error) {
      console.error("Error downloading files:", error);
    }
  };

  const setFileDataFromS3 = async (path: string, fileSetter?, urlSetter?) => {
    if (path) {
      const [url, blob] = await downloadFileFromS3(path);
      if (blob) {
        const fileName = path.split("/").pop() || path;
        const file = new File([blob], fileName, { type: (blob as Blob).type });
        fileSetter && fileSetter(file);
      }
      if (url && urlSetter) {
        urlSetter(url);
      }
    }
  };

  const updateFiles = (newFiles) => {
    (otherDocsRef.current as any)?.setUploadedFiles(newFiles);

    setOtherDocs(newFiles);
  };

  return (
    <>
      <ViewModal
        isOpen={isOpen}
        title={title}
        onClose={onClose}
        onSave={handleSubmit(onSubmit)}
        isLoading={
          updateMutation.isPending ||
          uploadMutation.isPending ||
          mutation.isPending
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col space-y-10">
            <div className="flex w-full space-x-16">
              <div className={`'h-24 w-24'} flex  items-center rounded-full`}>
                {capturedScreenshot ? (
                  <>
                    <div className="flex flex-col space-y-4">
                      <div>
                        <Button
                          className={`flex items-center rounded-full ${capturedScreenshot ? "h-[200px] w-[200px]" : "h-24 w-24"}`}
                          style={{
                            backgroundImage: capturedScreenshot
                              ? `url(${capturedScreenshot})`
                              : "none",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                          rounded
                          text
                          onClick={() => {
                            setisPhotoAddModal(true);
                          }}
                          type="button"
                          disabled
                        >
                          <div className=" flex flex-col items-center space-y-1">
                            <i
                              className="pi pi-camera"
                              style={{ fontSize: "1.7rem", color: "#02385B" }}
                            />
                            <span className=" text-sm font-semibold capitalize text-primary-blue">
                              Photo
                            </span>
                          </div>
                        </Button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <Button
                      className="!h-[200px] !w-[200px] !bg-[#DEE9F5] !shadow-none"
                      rounded
                      disabled
                      text
                      onClick={() => {
                        setisPhotoAddModal(true);
                      }}
                    >
                      <div className=" flex flex-col items-center space-y-1">
                        <i
                          className="pi pi-camera"
                          style={{ fontSize: "1.7rem", color: "#02385B" }}
                        />
                        <span className=" text-sm font-semibold capitalize text-primary-blue">
                          Photo
                        </span>
                      </div>
                    </Button>
                  </>
                )}
              </div>
              <div>
                <div>
                  {/* Below field is temporary until current SOs of the system are added. */}
                  <FormLabel label="Reference No:" />
                  <InputText
                    {...register("refNo", {
                      required: false,
                    })}
                    className="!bg-inputBg"
                    readOnly
                    // placeholder="SO Name"
                  />
                </div>
              </div>
            </div>
            <div className=" text-lg font-semibold text-primary-text">
              Security Officer Details
            </div>
            <div>
              <FormLabel label="Full Name" />
              <InputText
                {...register("name", {
                  required: "This field is required.",
                })}
                className="!bg-inputBg"
                readOnly
                // placeholder="SO Name"
              />
            </div>

            <div>
              <FormLabel label="Address" />
              <InputTextarea
                //placeholder="Address"
                color="blue"
                className=" !bg-inputBg !w-full"
                readOnly
                {...register("address", {
                  required: "This field is required.",
                })}
              />
            </div>

            <div className="grid grid-cols-1 gap-2 lg:grid-cols-2  lg:gap-10">
              <div>
                <FormLabel label="Mobile No / Telephone No" />
                <InputText
                  readOnly
                  {...register("mobileNo", {
                    required: "This field is required.",
                  })}

                  // placeholder="Mobile Number"
                />
              </div>
              <div>
                <FormLabel label="Applied Security Officer Level" />
                <Controller
                  name="appliedLevel"
                  control={control}
                  defaultValue={""}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <Dropdown
                      value={field.value}
                      readOnly
                      onChange={(e: DropdownChangeEvent) => {
                        field.onChange(e.value); // Update form field value
                      }}
                      options={levelOptions}
                      className="w-full"
                      disabled
                    />
                  )}
                />
              </div>
              <div>
                <FormLabel label="Date of Birth" />

                <Controller
                  name="dob"
                  control={control}
                  render={({ field }) => (
                    <Calendar
                      id={field.name}
                      value={field.value as Date}
                      disabled
                      onChange={(e) => field.onChange(e.value)}
                      placeholder="DD - MM - YYYY"
                      showIcon
                      className="w-full"
                      dateFormat="dd/mm/yy"
                    />
                  )}
                />
              </div>
              <div>
                <FormLabel label="City of Residence" />
                <InputText
                  {...register("cityOfResidence")}
                  readOnly
                  // placeholder="City of Residence"
                />
              </div>

              <div>
                <FormLabel label="District" />
                <InputText
                  {...register("district")}
                  readOnly
                  // placeholder="District"
                />
              </div>

              <div>
                <FormLabel label="Grama Niladhari Division" />

                <span className="p-input-icon-left w-full">
                  <InputText
                    {...register("gramaNiladhariDiv.val", {
                      required: false,
                    })}
                    readOnly
                  />
                  {gsnFile && gsnFileUrl && (
                    <>
                      <i
                        title="View"
                        onClick={() => viewFile(gsnFileUrl)}
                        className="pi pi-eye right-14  flex cursor-pointer items-center border-l border-r  px-1 hover:text-primary-blue"
                      />
                    </>
                  )}
                </span>

                <input
                  type="file"
                  ref={gsnFileInputRef}
                  hidden
                  onChange={(e) =>
                    handleFileSelect(
                      e,
                      setGSNFile,
                      setGSNFileUrl,
                      "gramaNiladhariDiv",
                    )
                  }
                />
              </div>

              <div>
                <FormLabel label="Police Division" />
                <InputText
                  {...register("policeDiv")}
                  readOnly
                  // placeholder="Police Division"
                />
              </div>

              <div>
                <FormLabel label="Religion" />
                <InputText
                  {...register("religion")}
                  readOnly
                  // placeholder="Religion"
                />
              </div>

              <div>
                <FormLabel label="Civil Status" />
                <InputText
                  {...register("civilStatus")}
                  readOnly
                  // placeholder="Civil Status"
                />
              </div>

              <div>
                <FormLabel label="Gender" />
                <InputText
                  {...register("gender")}
                  readOnly
                  // placeholder="Gender"
                />
              </div>
              <div>
                <FormLabel label="Blood Group" />
                <InputText
                  {...register("bloodGroup")}
                  readOnly
                  // placeholder="Gender"
                />
              </div>
              <div>
                <FormLabel label="Illness details (if any)" />

                <InputTextarea
                  color="blue"
                  {...register("illnessDetails", {
                    required: false,
                  })}
                  autoResize
                  rows={1}
                  className="w-full"
                  readOnly
                />
              </div>
            </div>
            <div className="flex flex-row items-center space-x-6">
              <div className="flex flex-row items-center">
                <Controller
                  name="isEPF"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <InputSwitch
                      onChange={(value) => {
                        field.onChange(value);
                        setIsEPFEnabled(value.value);
                      }}
                      checked={field.value}
                      className=" mr-4"
                      disabled
                    />
                  )}
                />
                <span className="text-textColor  ml-2 text-sm font-normal">
                  EPF
                </span>
              </div>
              <div>
                {isEPFEnabled && (
                  <div className=" ml-4">
                    <FormLabel label="EPF Number" />

                    <InputTextarea
                      color="blue"
                      {...register("EPFnumber", {
                        required: true,
                      })}
                      autoResize
                      rows={1}
                      className="w-[445px]"
                      readOnly
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="grid grid-cols-1 gap-2 lg:grid-cols-2  lg:gap-10"></div>
            <div className="grid grid-cols-1  gap-2 lg:grid-cols-2  lg:gap-10">
              <div className=" text-lg font-semibold text-primary-text">
                Identification Details
              </div>
              {/* <div className="  text-lg font-semibold text-primary-text">
                Qualification Details
              </div> */}
            </div>
            <div className="flex flex-col space-y-4">
              <div className="grid grid-cols-1 gap-2 lg:grid-cols-2  lg:gap-14">
                <div>
                  <FormLabel label="NIC - front" />

                  <span className="p-input-icon-left w-full">
                    <InputText
                      {...register("nic.nicValue", {
                        required: false,
                      })}
                      readOnly
                    />
                    {nicFile && nicFileUrl && (
                      <>
                        <i
                          title="View"
                          onClick={() => viewFile(nicFileUrl)}
                          className="pi pi-eye right-14  flex cursor-pointer items-center border-l border-r  px-1 hover:text-primary-blue"
                        />
                      </>
                    )}
                  </span>
                </div>

                <div>
                  <FormLabel label="Birth Certificate - front" />

                  <span className="p-input-icon-left w-full">
                    <InputText
                      {...register("birthCertificateFront", {})}
                      readOnly
                    />
                    {birthCFrFile && birthCFrUrl && (
                      <>
                        <i
                          title="View"
                          onClick={() => viewFile(birthCFrUrl)}
                          className="pi pi-eye right-14  flex cursor-pointer items-center border-l border-r  px-1 hover:text-primary-blue"
                        />
                      </>
                    )}
                  </span>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-2 lg:grid-cols-2  lg:gap-14">
                <div>
                  <FormLabel label="NIC - back" />

                  <span className="p-input-icon-left w-full">
                    <InputText
                      {...register("nicBack.nicValue", {
                        required: false,
                      })}
                      readOnly
                    />
                    {nicFileB && nicFileBUrl && (
                      <>
                        <i
                          title="View"
                          onClick={() => viewFile(nicFileBUrl)}
                          className="pi pi-eye right-14  flex cursor-pointer items-center border-l border-r  px-1 hover:text-primary-blue"
                        />
                      </>
                    )}
                  </span>
                </div>

                <div>
                  <FormLabel label="Birth Certificate - back" />

                  <span className="p-input-icon-left w-full">
                    <InputText
                      {...register("birthCertificateBack", {})}
                      readOnly
                    />
                    {birthCFile && birthCFileUrl && (
                      <>
                        <i
                          title="View"
                          onClick={() => viewFile(birthCFileUrl)}
                          className="pi pi-eye right-14  flex cursor-pointer items-center border-l border-r  px-1 hover:text-primary-blue"
                        />
                      </>
                    )}
                  </span>

                  <input
                    type="file"
                    ref={birthCFileInputRef}
                    hidden
                    onChange={(e) =>
                      handleFileSelect(
                        e,
                        setBirthCFile,
                        setBirthCFileUrl,
                        "birthCertificateBack",
                      )
                    }
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-2 lg:grid-cols-2  lg:gap-14">
                <div>
                  <FormLabel label="NIC Issued Date" />

                  <Controller
                    name="nic.issuedDate"
                    control={control}
                    render={({ field }) => (
                      <Calendar
                        id={field.name}
                        value={field.value as Date}
                        onChange={(e) => field.onChange(e.value)}
                        placeholder="DD - MM - YYYY"
                        showIcon
                        className=" w-full"
                        dateFormat="dd/mm/yy"
                        disabled
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col  pt-8">
              <div className="flex flex-row space-x-14">
                <div className="  text-lg font-semibold text-primary-text">
                  Dependents Details
                </div>
              </div>
              <div className="my-8 flex items-center justify-between rounded-sm border bg-slate-50 px-3 py-3">
                <span className=" text-sm font-semibold">Dependent 1</span>
                <div className="flex items-center">
                  <Controller
                    name="dependents.0.isEmergencyContact"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <InputSwitch
                        onChange={(value) => field.onChange(value)}
                        checked={field.value}
                        className=" mr-4"
                        disabled
                      />
                    )}
                  />
                  <span className="text-textColor  ml-2 text-sm font-normal">
                    Contact in case of Emergency
                  </span>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-2 lg:grid-cols-2  lg:gap-10">
                <div>
                  <FormLabel label="Name" />
                  <InputText
                    {...register("dependents.0.name", {
                      required: false,
                    })}
                    readOnly
                  />
                </div>
                <div>
                  <FormLabel label="Relationship to SO" />
                  <InputText
                    {...register("dependents.0.relationship", {
                      required: false,
                    })}
                    readOnly
                  />
                </div>
                <div>
                  <FormLabel label="Contact No" />
                  <InputText
                    {...register("dependents.0.contactNo", {
                      required: false,
                    })}
                    readOnly
                  />
                </div>
                <div>
                  <FormLabel label="Address" />

                  <InputTextarea
                    color="blue"
                    {...register("dependents.0.address", {
                      required: false,
                    })}
                    autoResize
                    rows={1}
                    className="w-full"
                    readOnly
                  />
                </div>
              </div>

              <div className="my-8 flex items-center justify-between rounded-sm border bg-slate-50 px-3 py-3">
                <span className=" text-sm font-semibold">Dependent 2</span>
                <div className="flex items-center">
                  <Controller
                    name="dependents.1.isEmergencyContact"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <InputSwitch
                        onChange={(value) => field.onChange(value)}
                        checked={field.value}
                        className=" mr-4"
                        disabled
                      />
                    )}
                  />
                  <span className="text-textColor  ml-2 text-sm font-normal">
                    Contact in case of Emergency
                  </span>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-2 lg:grid-cols-2  lg:gap-10">
                <div>
                  <FormLabel label="Name" />
                  <InputText
                    {...register("dependents.1.name", {
                      required: false,
                    })}
                    readOnly
                  />
                </div>
                <div>
                  <FormLabel label="Relationship to SO" />
                  <InputText
                    {...register("dependents.1.relationship", {
                      required: false,
                    })}
                    readOnly
                  />
                </div>
                <div>
                  <FormLabel label="Contact No" />
                  <InputText
                    {...register("dependents.1.contactNo", {
                      required: false,
                    })}
                    readOnly
                  />
                </div>
                <div>
                  <FormLabel label="Address" />

                  <InputTextarea
                    color="blue"
                    {...register("dependents.1.address", {
                      required: false,
                    })}
                    autoResize
                    rows={1}
                    className="w-full"
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-4 pt-8">
              <div className="flex flex-row space-x-14">
                <div className="  text-lg font-semibold text-primary-text">
                  Bank Details
                </div>
              </div>
              <div className="my-8 flex w-full items-center justify-between rounded-sm border bg-slate-50 px-3 py-3">
                <span className=" text-sm font-semibold">Bank Details 1</span>
              </div>
              <div className="grid grid-cols-1 gap-2 lg:grid-cols-2  lg:gap-10">
                <div>
                  <FormLabel label="Bank Name" />
                  <InputText
                    {...register("bankName", {
                      required: false,
                    })}
                    readOnly
                    //placeholder="Bank Name"
                  />
                </div>
                <div>
                  <FormLabel label="Account No" />
                  <InputText
                    {...register("accNo", {
                      required: false,
                    })}
                    readOnly
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-2 lg:grid-cols-2  lg:gap-10">
              <div>
                <FormLabel label="Bank Branch" />
                <InputText
                  {...register("bankBranch", {
                    required: false,
                  })}
                  readOnly
                />
              </div>
              <div></div>
            </div>
            <div className="flex flex-col space-y-4">
              <div className="flex flex-row space-x-14">
                <div className="my-8 flex w-full items-center justify-between rounded-sm border bg-slate-50 px-3 py-3">
                  <span className=" text-sm font-semibold">Bank Details 2</span>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-2 lg:grid-cols-2  lg:gap-10">
                <div>
                  <FormLabel label="Bank Name" />
                  <InputText
                    {...register("bankName2", {
                      required: false,
                    })}
                    readOnly
                    //placeholder="Bank Name"
                  />
                </div>
                <div>
                  <FormLabel label="Account No" />
                  <InputText
                    {...register("accNo2", {
                      required: false,
                    })}
                    readOnly
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-2 lg:grid-cols-2  lg:gap-10">
              <div>
                <FormLabel label="Bank Branch" />
                <InputText
                  {...register("bankBranch2", {
                    required: false,
                  })}
                  readOnly
                />
              </div>
              <div></div>
            </div>
            <div className=" pt-8 text-lg font-semibold text-primary-text">
              Supporting Documents
              <br />
              <span className="text-sm font-normal text-primary-blueLigter">
                Please include all the Educational Qualification documents and
                other supporting documents into one PDF file and upload the PDF
                file.
              </span>
            </div>

            <div className="card">
              {uploadMutation.isPending || deleteFileInProgress ? (
                <ProgressBar mode="indeterminate"></ProgressBar>
              ) : (
                <></>
              )}
              <FileUpload
                cancelOptions={{ className: "hidden" }}
                ref={otherDocsRef}
                customUpload
                disabled
                itemTemplate={(file) => {
                  return (
                    <div className="my-2 flex justify-between">
                      <div className="flex space-x-6 ">
                        <div>{(file as File).name}</div>
                        <div
                          className={`rounded-lg ${otherDocs?.includes(file as File) ? "bg-blue-100" : "bg-yellow-100"} px-3 py-1`}
                        >
                          {otherDocs?.includes(file as File)
                            ? "Complete"
                            : "Pending"}
                        </div>
                      </div>

                      <div className="space-x-4">
                        <i
                          title="View"
                          onClick={() => {
                            const url = URL.createObjectURL(file as File);

                            viewFile(url);
                          }}
                          className="pi pi-eye  flex cursor-pointer items-center  px-1 hover:text-primary-blue"
                        />
                      </div>
                    </div>
                  );
                }}
                uploadHandler={async (e) => {
                  const existingFiles = otherDocs || [];

                  const uploadPromises = e.files.map((file) => {
                    return uploadMutation
                      .mutateAsync({
                        file: file,
                        folderName: "security-officer/other-docs",
                      })
                      .then((data) => {
                        return { fileName: file.name, filePath: data };
                      });
                  });

                  try {
                    const uploadResults = await Promise.all(uploadPromises);
                    const previousPaths: string[] =
                      getValues("otherDocuments") || [];
                    const newPaths = uploadResults.map(
                      (result) => result.filePath,
                    );

                    setValue("otherDocuments", [...previousPaths, ...newPaths]);
                    updateFiles([...existingFiles, ...e.files]);

                    const newFilePathsMap = { ...filePathsMap };
                    uploadResults.forEach(({ fileName, filePath }) => {
                      newFilePathsMap[fileName] = filePath;
                    });

                    setFilePathsMap(newFilePathsMap);
                    (otherDocsRef.current as any)?.setFiles();
                  } catch (error) {
                    (toast.current as any).show({
                      severity: "warn",
                      summary: "Error",
                      detail: "Error uploading files",
                      life: 3000,
                    });
                  }
                }}
                multiple
                accept="/*"
                maxFileSize={1000000}
                // headerTemplate={<span>hello</span>}
                emptyTemplate={
                  <p className="m-0">Drag and drop files to here to upload.</p>
                }
              />
            </div>

            <div className=" pt-8 text-lg font-semibold text-primary-text">
              Previous Work Details
            </div>
            <div>
              <FormLabel label="Work Experience" />

              <InputTextarea
                color="blue"
                {...register("workExp.workExpDetails", {
                  required: false,
                })}
                autoResize
                rows={1}
                className="w-full"
                readOnly
              />
            </div>
            <div className="flex flex-row space-x-10">
              <div className="flex items-center">
                <Controller
                  name="workExp.wasArmedForces"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <InputSwitch
                      onChange={(value) => field.onChange(value)}
                      checked={field.value}
                      className=" mr-4"
                      disabled
                    />
                  )}
                />
                <span className="text-textColor  ml-2 text-sm font-normal">
                  Was part of Army Forces
                </span>
              </div>
              <div>
                <FormLabel label="Remarks" />
                <InputText
                  {...register("workExp.armedForcesRemarks", {
                    required: false,
                  })}
                  className="!bg-inputBg !w-[550px]"
                  readOnly
                  // placeholder="Remarks"
                />
              </div>
            </div>
            <div className="space-y-10 pt-8">
              <div className=" text-lg font-semibold text-primary-text">
                Interview Status & Feedback
              </div>

              <div className="grid grid-cols-1 gap-10 lg:grid-cols-2">
                <div>
                  <FormLabel label="Interview Feedback" />

                  <InputTextarea
                    color="blue"
                    {...register("interviewFeedback", {
                      required: false,
                    })}
                    autoResize
                    rows={1}
                    className="w-full"
                    readOnly
                  />
                </div>

                <div>
                  <FormLabel label="Officer Level" />

                  <Controller
                    name="officerLevel"
                    control={control}
                    defaultValue={""}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <Dropdown
                        value={field.value}
                        onChange={(e: DropdownChangeEvent) => {
                          field.onChange(e.value); // Update form field value
                        }}
                        options={levelOptions}
                        className="w-full"
                        disabled
                      />
                    )}
                  />
                </div>
                <div>
                  <FormLabel label="Interview Result" />
                  <Controller
                    name="interviewStatus"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <Dropdown
                        value={field.value}
                        onChange={(e: DropdownChangeEvent) => {
                          field.onChange(e.value); // Update form field value
                        }}
                        options={StatusOptions}
                        className="w-full"
                        disabled
                      />
                    )}
                  />
                </div>
                <div>
                  <FormLabel label=" Basic Salary (LKR)" />

                  <Controller
                    control={control}
                    name={`basicSalary`}
                    render={({ field }) => (
                      <InputNumber
                        value={field.value}
                        onValueChange={(e) => field.onChange(e.value)}
                        useGrouping={true}
                        minFractionDigits={2}
                        readOnly
                      />
                    )}
                  />
                </div>
                <div>
                  <FormLabel label="Day Shift Payment (LKR)" />

                  <Controller
                    control={control}
                    name={`dayShiftPayment`}
                    render={({ field }) => (
                      <InputNumber
                        value={field.value}
                        onValueChange={(e) => field.onChange(e.value)}
                        useGrouping={true}
                        minFractionDigits={2}
                        readOnly
                      />
                    )}
                  />
                </div>
                <div>
                  <FormLabel label="Night Shift Payment (LKR)" />

                  <Controller
                    control={control}
                    name={`nightShiftPayment`}
                    render={({ field }) => (
                      <InputNumber
                        value={field.value}
                        onValueChange={(e) => field.onChange(e.value)}
                        useGrouping={true}
                        minFractionDigits={2}
                        readOnly
                      />
                    )}
                  />
                </div>
                {interviewStatus === "APPROVED" && (
                  <div className="flex items-center">
                    <Controller
                      name="activeStatus"
                      control={control}
                      render={({ field }) => (
                        <InputSwitch
                          onChange={(value) => field.onChange(value)}
                          checked={field.value}
                          className=" mr-4"
                          disabled
                        />
                      )}
                    />
                    <span className="text-textColor  ml-2 text-sm font-normal">
                      ACTIVE
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-row space-x-6">
              <div className=" mt-10 text-lg font-semibold text-primary-text">
                Allocation of Inventory Items
              </div>
            </div>
            <div>
              <table className=" mt-3 w-full">
                <thead>
                  <tr>
                    {tableHeaders.map((head) => (
                      <th
                        key={head}
                        className="border border-b-2 border-l-0 border-r-0 border-t-0 border-primary-border bg-white p-4 text-start text-sm font-semibold text-primary-text"
                      >
                        {head}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {inventoryItems.map((item, index) => {
                    return (
                      <tr key={index}>
                        <>
                          <td className="p-4">{item.itemName.itemName}</td>
                          <td className="p-4">{item.itemVarient}</td>
                          <td className="p-4">{item.qty}</td>
                          <td className="p-4">{item.unitPrice}</td>
                          <td className="p-4">{item.totalPrice}</td>
                        </>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </ViewModal>
      <PhotoModal
        isOpen={isOpenPhotoModal}
        onClose={handleCloseModal}
        onSave={handleSaveScreenshot as any}
      />
    </>
  );
};

export default ViewSecurityPerson;
