import { Levels } from "@/constants/common";
import FormModal from "@/layouts/FormModal";
import { FormLabel } from "@/layouts/Label";
import { Customer } from "@/models/customer";
import axiosInstance from "@/utils/apiUtil";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import {
  InputNumber,
  InputNumberValueChangeEvent,
} from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { toast } from "react-toastify";

const tableHeaders = [
  "SO rank",
  "No of Officers",
  "Day Shift Payment",
  "TGS Day Shift Payment",
  "No of day shifts per month",
  "Night Shift Payment",
  "TGS Night Shift Payment",
  "No of night shifts per month",
  "Actions",
];
interface AddEditCustomerProps {
  onSave?: (formData: Customer) => Promise<void>;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  loading?: boolean;
  customer?: Customer;
  isEdit?: boolean; // Flag to indicate data loading for edit mode
}

const AddEditCustomer: React.FC<AddEditCustomerProps> = ({
  isOpen,
  onClose,
  title,
  customer,
  isEdit,
}) => {
  const methods = useForm<Customer>();

  const { register, handleSubmit, reset, control, watch } = methods;

  const [editableRows, setEditableRows] = useState({});

  const isDisabled = watch("isActive");

  useEffect(() => {
    if (isOpen) {
      if (!isEdit) {
        reset({
          name: "",
          address: "",
          deployingAddress: "",
          telephoneNumber: "",
          paymentPersonName: "",
          paymentPersonContact: "",
          joinedDate: "",
          marketPersonName: "",
          commission: 0,
          securityOfficers: [],
          isActive: true,
          disableDate: isDisabled === true ? null : new Date(),
          disabledReason: "",
        });
      }
    }

    if (customer && isEdit) {
      reset({
        ...customer,
        joinedDate: new Date(customer.joinedDate),
        disableDate: customer.disableDate
          ? new Date(customer.disableDate)
          : new Date(),
      });
    } else {
      reset();
    }
  }, [customer, reset, isOpen, isEdit]); //remove the isDisabled dependency when running and building

  // Get the query client instance
  const queryClient = useQueryClient();

  //adding customer
  const mutation = useMutation({
    mutationFn: async (customer) => {
      const response = await axiosInstance.post("/customers", customer);
      return response.data;
    },
    onSuccess: async () => {
      // Refetch the list of customers after adding a new officer
      await queryClient.invalidateQueries("customer" as any);
      toast.success("New Customer Added Successfully");
    },
    onError: async () => {
      toast.error("Could not add customer details, Please try again");
    },
  });

  //update mutation
  const updateMutation = useMutation({
    mutationFn: ({ customerId, customer }: any) => {
      return axiosInstance.patch(`/customers/${customerId}`, customer);
    },
    onSuccess: async () => {
      // Refetch the list of customers after updating
      await queryClient.invalidateQueries("customers" as any);
      await queryClient.invalidateQueries("DailyRosters" as any);
      toast.success("Customer Details Updated Successfully");
    },
    onError: async () => {
      toast.error("Could not update customer details, Please try again");
    },
  });

  const onSubmit: SubmitHandler<Customer> = async (formData) => {
    // Perform add and edit based on mode
    if (isEdit && customer) {
      await updateMutation.mutateAsync({
        customerId: customer.id,
        customer: formData,
      });
    } else {
      await mutation.mutateAsync(formData as any);
    }
    reset();
    onClose();
  };

  const {
    fields: securityFields,
    append: securityAppend,
    remove: securityRemove,
  } = useFieldArray({
    control,
    name: "securityOfficers",
  });

  const levelOptions = Levels;

  const toggleCurrentRow = (index) => {
    setEditableRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const addNewSecurityOfficerRow = () => {
    const newIndex = securityFields.length;
    securityAppend({
      rank: "",
      noOfOfficers: 1,
      dayShiftPayment: 0,
      transDayShiftPayment: 0,
      dayShifts: 1,
      nightShiftPayment: 0,
      transNightShiftPayment: 0,
      nightShifts: 1,
    });
    setEditableRows((prev) => ({
      ...prev,
      [newIndex]: true,
    }));
  };

  const toggleEditableRow = (index) => {
    setEditableRows((prev) => ({
      ...prev,
      [index]: false,
    }));
  };

  const securityRemoveRow = (index) => {
    securityRemove(index);

    setEditableRows((prev) => {
      const updated = { ...prev };
      delete updated[index];
      Object.keys(updated).forEach((key) => {
        const numKey = parseInt(key);
        if (numKey > index) {
          updated[numKey - 1] = updated[numKey];
          delete updated[numKey];
        }
      });
      return updated;
    });
  };

  return (
    <FormModal
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      onSave={handleSubmit(onSubmit)}
      isLoading={updateMutation.isPending || mutation.isPending}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={(e) => {
            handleSubmit(onSubmit)(e);
          }}
        >
          <div className="flex flex-col space-y-10">
            <div className="flex justify-between">
              <div className=" text-lg font-semibold text-primary-text">
                Customer Details
              </div>
              <div className="flex flex-row items-center">
                <Controller
                  name="isActive"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <InputSwitch
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      checked={field.value}
                      className=" mr-4"
                    />
                  )}
                />
                <span className="text-textColor  ml-2 text-sm font-normal">
                  {isDisabled === true ? "Enabled" : "Disabled"}
                </span>
                {isDisabled === false && (
                  <div className="ml-5 flex flex-row items-center space-x-4">
                    <div>
                      <FormLabel label="Disabled Date" />
                      <Controller
                        name={`disableDate`}
                        control={control}
                        render={({ field }) => (
                          <Calendar
                            id={field.name}
                            value={field.value as Date}
                            onChange={(e) => field.onChange(e.value)}
                            placeholder="DD - MM - YYYY"
                            showIcon
                            className="w-full"
                            dateFormat="dd/mm/yy"
                            style={{ width: "270px" }}
                          />
                        )}
                      />
                    </div>
                    <div>
                      <FormLabel label="Disabling Reason" />
                      <InputText
                        {...register("disabledReason", {
                          required: "This field is required.",
                        })}
                        className="!bg-inputBg"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div>
              <FormLabel label="Customer Name" />
              <InputText
                {...register("name", {
                  required: "This field is required.",
                })}
                className="!bg-inputBg"
                // placeholder="SO Name"
              />
            </div>
            <div>
              <FormLabel label="Address" />
              <InputTextarea
                //placeholder="Address"
                color="blue"
                className=" !bg-inputBg !w-full"
                {...register("address", {
                  required: "This field is required.",
                })}
              />
            </div>
            <div>
              <FormLabel label="Deploying Address" />
              <InputTextarea
                //placeholder="Address"
                color="blue"
                className=" !bg-inputBg !w-full"
                {...register("deployingAddress", {
                  required: "This field is required.",
                })}
              />
            </div>
            <div className="flex flex-row space-x-4">
              <div>
                <FormLabel label="Joined Date" />
                <Controller
                  name={`joinedDate`}
                  control={control}
                  render={({ field }) => (
                    <Calendar
                      id={field.name}
                      value={field.value as Date}
                      onChange={(e) => field.onChange(e.value)}
                      placeholder="DD - MM - YYYY"
                      showIcon
                      className="w-full"
                      dateFormat="dd/mm/yy"
                      style={{ width: "270px" }}
                    />
                  )}
                />
              </div>
              <div>
                <FormLabel label="Market Person Name" />
                <InputText
                  {...register("marketPersonName", {
                    required: false,
                  })}
                  className="!bg-inputBg !w-[400px] "
                />
              </div>
              <div>
                <FormLabel label="Commission (LKR)" />
                <Controller
                  control={control}
                  name={`commission`}
                  render={({ field }) => (
                    <InputNumber
                      value={field.value}
                      onValueChange={(e) => field.onChange(e.value)}
                      useGrouping={true}
                      minFractionDigits={2}
                      className="!w-[400px]"
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex flex-row space-x-4">
              <div>
                <FormLabel label="Telephone Number" />
                <InputText
                  {...register("telephoneNumber", {
                    required: "This field is required.",
                  })}
                  className="!bg-inputBg !w-[270px] "
                  // placeholder="SO Name"
                />
              </div>
              <div>
                <FormLabel label="Payment Person Name" />
                <InputText
                  {...register("paymentPersonName", {
                    required: false,
                  })}
                  className="!bg-inputBg !w-[460px]"
                  // placeholder="SO Name"
                />
              </div>
            </div>
            <div>
              <FormLabel label="Payment Person Contact No" />
              <InputText
                {...register("paymentPersonContact", {
                  required: false,
                })}
                className="!bg-inputBg !w-[270px] "
                // placeholder="SO Name"
              />
            </div>
            <div className="flex flex-row space-x-16">
              <div className=" text-lg font-semibold text-primary-text">
                Security Officer Requirement
              </div>
              <div>
                <Button
                  type="button"
                  className=" ml-8 flex !h-11 !w-[250px] items-center space-x-2 shadow-none lg:w-auto"
                  onClick={() => addNewSecurityOfficerRow()}
                >
                  Add New Security Officer
                </Button>
              </div>
              <div className="flex flex-row items-center">
                <Controller
                  name="isShiftRateAdded"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <InputSwitch
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      checked={field.value}
                      className=" mr-4"
                    />
                  )}
                />
                <span className="text-textColor  ml-2 text-sm font-normal">
                  Get Officer Shift Payment
                </span>
              </div>
            </div>
            <div>
              <table className=" mt-3">
                <thead>
                  <tr>
                    {tableHeaders.map((head) => (
                      <th
                        key={head}
                        className="border border-b-2 border-l-0 border-r-0 border-t-0 border-primary-border bg-white p-4 text-start text-sm font-semibold text-primary-text"
                      >
                        {head}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {securityFields.map((officer, index) => (
                    <tr key={index}>
                      {editableRows[index] ? (
                        <>
                          <td className="p-4">
                            <div className="card justify-content-center flex">
                              <Controller
                                name={`securityOfficers.${index}.rank`}
                                control={control}
                                rules={{ required: "This field is required." }}
                                render={({ field }) => (
                                  <Dropdown
                                    id={field.name}
                                    value={field.value}
                                    onChange={(e: DropdownChangeEvent) =>
                                      field.onChange(e.value)
                                    }
                                    options={levelOptions}
                                    placeholder="Select rank"
                                  />
                                )}
                              />
                            </div>
                          </td>
                          <td className="p-4">
                            <Controller
                              name={`securityOfficers.${index}.noOfOfficers`}
                              control={control}
                              rules={{ required: "This field is required." }}
                              render={({ field }) => (
                                <InputNumber
                                  id={field.name}
                                  value={field.value}
                                  onValueChange={(
                                    e: InputNumberValueChangeEvent,
                                  ) => field.onChange(e.value)}
                                  mode="decimal"
                                  showButtons
                                  min={0}
                                  max={31}
                                  className=" !w-24"
                                />
                              )}
                            />
                          </td>
                          <td className="p-4">
                            <Controller
                              control={control}
                              name={`securityOfficers.${index}.dayShiftPayment`}
                              render={({ field }) => (
                                <InputNumber
                                  value={field.value}
                                  onValueChange={(e) => field.onChange(e.value)}
                                  useGrouping={true}
                                  minFractionDigits={2}
                                  className=" !w-24"
                                />
                              )}
                            />
                          </td>
                          <td className=" p-4">
                            <Controller
                              control={control}
                              name={`securityOfficers.${index}.transDayShiftPayment`}
                              render={({ field }) => (
                                <InputNumber
                                  value={field.value}
                                  onValueChange={(e) => field.onChange(e.value)}
                                  useGrouping={true}
                                  minFractionDigits={2}
                                  className=" !w-24"
                                />
                              )}
                            />
                          </td>
                          <td className="p-4">
                            <Controller
                              name={`securityOfficers.${index}.dayShifts`}
                              control={control}
                              rules={{ required: "This field is required." }}
                              render={({ field }) => (
                                <InputNumber
                                  id={field.name}
                                  value={field.value}
                                  onValueChange={(
                                    e: InputNumberValueChangeEvent,
                                  ) => field.onChange(e.value)}
                                  mode="decimal"
                                  showButtons
                                  min={0}
                                  max={31}
                                  className=" !w-24"
                                />
                              )}
                            />
                          </td>
                          <td>
                            <Controller
                              control={control}
                              name={`securityOfficers.${index}.nightShiftPayment`}
                              render={({ field }) => (
                                <InputNumber
                                  value={field.value}
                                  onValueChange={(e) => field.onChange(e.value)}
                                  useGrouping={true}
                                  minFractionDigits={2}
                                  className=" !w-24"
                                />
                              )}
                            />
                          </td>
                          <td className=" p-4">
                            <Controller
                              control={control}
                              name={`securityOfficers.${index}.transNightShiftPayment`}
                              render={({ field }) => (
                                <InputNumber
                                  value={field.value}
                                  onValueChange={(e) => field.onChange(e.value)}
                                  useGrouping={true}
                                  minFractionDigits={2}
                                  className=" !w-24"
                                />
                              )}
                            />
                          </td>
                          <td className="p-4">
                            <Controller
                              name={`securityOfficers.${index}.nightShifts`}
                              control={control}
                              rules={{ required: "This field is required." }}
                              render={({ field }) => (
                                <InputNumber
                                  id={field.name}
                                  value={field.value}
                                  onValueChange={(
                                    e: InputNumberValueChangeEvent,
                                  ) => field.onChange(e.value)}
                                  mode="decimal"
                                  showButtons
                                  min={0}
                                  max={31}
                                  className=" !w-24"
                                />
                              )}
                            />
                          </td>
                          <td className="p-4">
                            <span className="flex flex-row space-x-3">
                              <i
                                className="pi pi-check cursor-pointer"
                                onClick={() => {
                                  toggleEditableRow(index);
                                  addNewSecurityOfficerRow();
                                }}
                              />
                              <i
                                className="pi pi-times cursor-pointer"
                                onClick={() => securityRemoveRow(index)}
                              />
                            </span>
                          </td>
                        </>
                      ) : (
                        <>
                          <td className="p-4">{officer.rank}</td>
                          <td className="p-4">{officer.noOfOfficers}</td>
                          <td className="p-4">{officer.dayShiftPayment}</td>
                          <td className="p-4">
                            {officer.transDayShiftPayment}
                          </td>
                          <td className="p-4">{officer.dayShifts}</td>
                          <td className="p-4">{officer.nightShiftPayment}</td>
                          <td className="p-4">
                            {officer.transNightShiftPayment}
                          </td>
                          <td className="p-4">{officer.nightShifts}</td>

                          <td className="p-2">
                            <span className="flex flex-row ">
                              <Button
                                icon="pi pi-pencil"
                                className=" !w-2 !border-none !bg-white !p-3 !text-primary-blue !shadow-none"
                                onClick={() => {
                                  toggleCurrentRow(index);
                                }}
                              />
                              <Button
                                type="button"
                                icon="pi pi-trash"
                                className="!w-2 !border-none !bg-white !p-3 !text-primary-blue !shadow-none"
                                onClick={() => {
                                  securityRemoveRow(index);
                                }}
                              />
                            </span>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </FormProvider>
    </FormModal>
  );
};

export default AddEditCustomer;
