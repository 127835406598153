import { Roles } from "@/constants/common";
import FormModal from "@/layouts/FormModal";
import { FormLabel } from "@/layouts/Label";
import { OfficeStaff } from "@/models/officeStaff";
import axiosInstance from "@/utils/apiUtil";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import "primeicons/primeicons.css";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";

interface AddStaffMemberProps {
  onSave?: (formData: OfficeStaff) => Promise<void>;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  loading?: boolean;
  staffMember?: OfficeStaff; // Data for pre-filling the form in edit mode
  isEdit?: boolean; // Flag to indicate data loading for edit mode
}

const AddEditStaffMember: React.FC<AddStaffMemberProps> = ({
  isOpen,
  onClose,
  title,
  loading,
  staffMember,
  isEdit,
}) => {
  const { register, handleSubmit, reset, control } = useForm<OfficeStaff>();

  useEffect(() => {
    if (isOpen) {
      reset({
        name: "",
        address: "",
        mobileNo: "",
        dependentNo: "",
        bankName: "",
        bankBranch: "",
        bankAccNo: "",
        hasEPF: undefined,
        EPFno: "",
        isActive: undefined,
        email: "",
        enableEmail: undefined,
        role: "",
      });
    }

    if (staffMember && isEdit) {
      reset({
        name: staffMember.name,
        address: staffMember.address,
        mobileNo: staffMember.mobileNo,
        dependentNo: staffMember.dependentNo,
        bankName: staffMember.bankName,
        bankBranch: staffMember.bankBranch,
        bankAccNo: staffMember.bankAccNo,
        hasEPF: staffMember.hasEPF,
        EPFno: staffMember.EPFno,
        isActive: staffMember.isActive,
        email: staffMember.email,
        enableEmail: staffMember.enableEmail,
        role: staffMember.role,
      });
    } else {
      reset();
    }
  }, [staffMember, reset, isOpen, isEdit]);

  //Get te query client instance
  const queryClient = useQueryClient();

  //adding staff member
  const mutation = useMutation({
    mutationFn: async (officeStaff) => {
      const response = await axiosInstance.post("/staffMembers", officeStaff);
      return response.data;
    },
    onSuccess: async () => {
      // Refetch the list of officers after adding a new staff memeber
      await queryClient.invalidateQueries("staffMembers" as any);
      toast.success("Staff Member Details Added Succesfully");
    },
    onError: async () => {
      toast.error("Could not add staff member details, Please try again");
    },
  });

  //updating the staff member
  const updateMutation = useMutation({
    mutationFn: ({ staffId, staffMember }: any) => {
      return axiosInstance.patch(`/staffMembers/${staffId}`, staffMember);
    },
    onSuccess: async () => {
      // Refetch the list of officers after updating a staff memeber
      await queryClient.invalidateQueries("staffMembers" as any);
      toast.success("Staff Member Details Updated Succesfully");
    },
    onError: async () => {
      toast.error("Could not update staff member details, Please try again");
    },
  });

  const onSubmit: SubmitHandler<OfficeStaff> = async (formData) => {
    // Perform add and edit based on mode
    if (isEdit && staffMember) {
      await updateMutation.mutateAsync({
        staffId: staffMember.id!,
        staffMember: formData,
      });
    } else {
      await mutation.mutateAsync(formData as any);
    }
    reset();
    onClose();
  };

  return (
    <FormModal
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      onSave={handleSubmit(onSubmit)}
      isLoading={loading}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {/** {isDataLoading && (
          <div>
            <SpinnerOverlay />
          </div>
        )}*/}
        <div className="flex flex-col space-y-10">
          <div className=" text-lg font-semibold text-primary-text">
            Staff Member Details
          </div>
          <div>
            <FormLabel label="Staff Member Name" />
            <InputText
              {...register("name", {
                required: "This field is required.",
              })}
              className="!bg-inputBg"
              // placeholder="SO Name"
            />
          </div>
          <div>
            <FormLabel label="Address" />
            <InputTextarea
              //placeholder="Address"
              color="blue"
              className=" !bg-inputBg !w-full"
              {...register("address", {
                required: "This field is required.",
              })}
            />
          </div>

          <div className="flex flex-row space-x-8">
            <div>
              <FormLabel label="Telepone Number" />
              <InputText
                {...register("mobileNo", {
                  required: "This field is required.",
                })}
                className="!bg-inputBg !w-[235px] "
                // placeholder="SO Name"
              />
            </div>
            <div>
              <FormLabel label="Dependant Number" />
              <InputText
                {...register("dependentNo", {
                  required: "This field is required.",
                })}
                className="!bg-inputBg !w-[240px]"
                // placeholder="SO Name"
              />
            </div>
            <div>
              <FormLabel label="User Role" />
              <Controller
                name="role"
                control={control}
                defaultValue={""}
                rules={{ required: "This field is required." }}
                render={({ field }) => (
                  <Dropdown
                    value={field.value}
                    onChange={(e: DropdownChangeEvent) => {
                      field.onChange(e.value); // Update form field value
                    }}
                    options={Roles}
                    className="w-full"
                  />
                )}
              />
            </div>
          </div>
          <div className=" text-lg font-semibold text-primary-text">
            Bank Details
          </div>
          <div className="flex flex-row space-x-10">
            <div>
              <FormLabel label="Bank Name" />
              <InputText
                {...register("bankName", {
                  required: "This field is required.",
                })}
                className="!bg-inputBg !w-[235px]"
                // placeholder="SO Name"
              />
            </div>
            <div>
              <FormLabel label="Bank Branch" />
              <InputText
                {...register("bankBranch", {
                  required: "This field is required.",
                })}
                className="!bg-inputBg !w-[235px]"
                // placeholder="SO Name"
              />
            </div>
            <div>
              <FormLabel label="Bank Account Number" />
              <InputText
                {...register("bankAccNo", {
                  required: "This field is required.",
                })}
                className="!bg-inputBg !w-[235px]"
                // placeholder="SO Name"
              />
            </div>
          </div>
          <div className=" text-lg font-semibold text-primary-text">
            Employment Status
          </div>
          <div className="flex flex-row space-x-28">
            <div className="flex items-center">
              <Controller
                name="hasEPF"
                control={control}
                defaultValue={false} // Set the default value as needed
                render={({ field }) => (
                  <InputSwitch
                    onChange={(value) => field.onChange(value)}
                    checked={field.value}
                    className=" mr-4"
                  />
                )}
              />
              <span className="text-md  text-textColor ml-2 font-normal">
                Has EPF/ETF
              </span>
            </div>
            <div>
              <FormLabel label="EPF No" />
              <InputText
                {...register("EPFno", {
                  required: "This field is required.",
                })}
                className="!bg-inputBg !w-[235px]"
                // placeholder="SO Name"
              />
            </div>
            <div className="flex items-center">
              <Controller
                name="isActive"
                control={control}
                defaultValue={false} // Set the default value as needed
                render={({ field }) => (
                  <InputSwitch
                    onChange={(value) => field.onChange(value)}
                    checked={field.value}
                    className=" mr-4"
                  />
                )}
              />
              <span className="text-md  text-textColor ml-2 font-normal">
                Active
              </span>
            </div>
          </div>
          <div className=" text-lg font-semibold text-primary-text">
            System Access
          </div>
          <div className="flex flex-col space-y-5">
            <div>
              <FormLabel label="Email" />
              <InputText
                {...register("email", {
                  required: "This field is required.",
                })}
                className="!bg-inputBg !w-[235px]"
                // placeholder="SO Name"
              />
            </div>
            <div className="ml-5 flex items-center">
              <Controller
                name="enableEmail"
                control={control}
                defaultValue={false} // Set the default value as needed
                render={({ field }) => (
                  <InputSwitch
                    onChange={(value) => field.onChange(value)}
                    checked={field.value}
                    className=" mr-4"
                  />
                )}
              />
              <div className="flex flex-col space-y-1">
                <span className="text-md  text-textColor ml-2 font-normal">
                  Enable Login
                </span>
                <span className="ml-2 text-sm font-normal italic text-primary-blueLigter">
                  Credentials will be emailed
                </span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormModal>
  );
};

export default AddEditStaffMember;
