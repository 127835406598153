import { Levels } from "@/constants/common";
import FormModal from "@/layouts/FormModal";
import { modalStates } from "@/models/modelStates";
import { SecurityPerson } from "@/models/securityPerson";
import axiosInstance from "@/utils/apiUtil";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { FC, useEffect, useState } from "react";
import { FaUserShield } from "react-icons/fa";
import { toast } from "react-toastify";
import DotIcon from "../../../assets/dot.svg?react";
import AddEditSecurityPerson from "./AddEditSecurity";
import ViewSecurityPerson from "./ViewSecurity";

const tableHeaders = [
  "Ref No",
  "Name",
  "Level",
  "Contact No",
  "Active Status",
  "Interview Status",
  "Current Status",
  "Actions",
];

const SecurityPersonnel: FC = () => {
  const [isOpenAddModal, setisOpenAddModal] = useState(false);
  const [isOpenViewModal, setisOpenViewModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [modalState, setModalState] = useState(modalStates.ADD);
  const [isOpenDeleteModal, setisOpenDeleteModal] = useState(false);
  const [selectedOfficerId, setSelectedOfficerId] = useState<string | null>(
    null,
  );
  const [filteredOfficers, setFilteredOfficers] = useState<SecurityPerson[]>(
    [],
  );
  const [searchTerms, setSearchTerms] = useState("");

  const items = Levels.map((level) => level.value);

  const Status = ["Active", "Inactive", "Pending", "Approved", "Rejected"];

  const status = Status.map((status) => status);

  //get All security Officers
  const { data: officers } = useQuery({
    queryKey: ["securityOfficers"],
    queryFn: async () => {
      const response = await axiosInstance.get("/securityOfficers");
      if (!response) {
        throw new Error("Network response was not ok");
      }

      return response.data;
    },
  });

  // Fetch officers by ID (if selectedSupplierId is not null)
  const { data: selectedOfficer } = useQuery({
    queryKey: ["securityOfficers", selectedOfficerId],
    queryFn: async () => {
      if (selectedOfficerId) {
        const response = await axiosInstance.get(
          `/securityOfficers/${selectedOfficerId}`,
        );
        if (!response) {
          throw new Error("Network response was not ok");
        }
        return response.data;
      }
      return null;
    },
    enabled: !!selectedOfficerId, // Only fetch data if selectedOfficerId is truthy
  });

  // Get the query client instance
  const queryClient = useQueryClient();

  // Deleting staff member
  const deleteMutation = useMutation({
    mutationFn: async (officerId) => {
      const response = await axiosInstance.delete(
        `/securityOfficers/${officerId}`,
      );
      return response.data;
    },
    onSuccess: async () => {
      // Refetch the list of officers after deleting a staff member
      await queryClient.invalidateQueries("securityOfficers" as any);
      toast.success("Security Officer Details Deleted Sccessfully");
    },
    onError: async () => {
      toast.error(
        "Could not delete security officer details, Please try again",
      );
    },
  });

  //filter using status
  const filterMutation = useMutation({
    mutationFn: async ({
      searchValue,
      filterType,
    }: {
      searchValue: string;
      filterType: string;
    }) => {
      try {
        const response = await axiosInstance.post("/securityOfficers/filter", {
          searchValue,
          filterType,
        });
        return response.data;
      } catch (error) {
        throw new Error("Error filtering security officers");
      }
    },
    onSuccess: async (data) => {
      setFilteredOfficers(data);
    },
  });

  useEffect(() => {
    if (officers) {
      setFilteredOfficers(officers);
    }
  }, [officers]);

  //searching name
  const { mutate: searchMutation } = useMutation({
    mutationFn: async (searchTerm) => {
      try {
        const response = await axiosInstance.post("/securityOfficers/search", {
          searchTerm,
        });
        return response.data;
      } catch (error) {
        throw new Error("Error searching security officers by name");
      }
    },
    onSuccess: async (data) => {
      setFilteredOfficers(data);
    },
  });

  //search while typing
  useEffect(() => {
    if (searchTerms) {
      searchMutation(searchTerms as any);

      const filteredOfficers = searchTerms
        ? officers.filter(
            (item) =>
              item.name.toLowerCase().includes(searchTerms.toLowerCase()) ||
              (item.refNo && item.refNo.includes(searchTerms)) ||
              (item.nic.nicValue && item.nic.nicValue.includes(searchTerms)),
          )
        : officers;

      setFilteredOfficers(filteredOfficers);
    } else {
      setFilteredOfficers(officers);
    }
  }, [searchTerms, officers, searchMutation]);

  const handleFilterChange = (e: DropdownChangeEvent) => {
    const { value } = e;
    setSelectedStatus(value); // Update selectedStatus state with the selected filter value
    filterMutation.mutate({ searchValue: value, filterType: "status" });
  };

  const handleLevelChange = (e: DropdownChangeEvent) => {
    const { value } = e;
    setSelectedItem(value); // Update selectedStatus state with the selected filter value
    filterMutation.mutate({ searchValue: value, filterType: "level" });
  };

  const handleClearFilters = async () => {
    setSelectedStatus("");
    setSearchTerms("");
    setSelectedItem(null);
    setFilteredOfficers(officers);
  };

  //handle update button click
  const handleEditButtonClick = (officerId: string) => {
    setModalState(modalStates.EDIT);
    setisOpenAddModal(true);
    setSelectedOfficerId(officerId); // Set the selected officer ID
  };
  // Function to handle delete button click
  const handleDeleteButtonClick = (officerId: string) => {
    setisOpenDeleteModal(true);
    setSelectedOfficerId(officerId); // Set the selected officer ID
  };

  //handle view button click
  const handleViewButtonClick = (officerId: string) => {
    setModalState(modalStates.EDIT);
    setisOpenViewModal(true);
    setSelectedOfficerId(officerId); // Set the selected officer ID
  };

  //download inventory invoice
  //  const downloadMutation = useMutation({
  //   mutationFn: async (downloadData) => {
  //     const response = await axiosInstance.post(
  //       `/securityOfficer/inventory/download`,
  //       downloadData,
  //     );
  //     return downloadPDFFromBase64(
  //       response.data.pdf,
  //       `Officer-Inventory-Invoice-${selectedOfficer.refNo}`,
  //     );
  //   },
  //   onSuccess: async () => {
  //
  //     //setIsDownloadEnabled(false);
  //     toast.success("Security Officer Inventory Invoice Downloaded Succesfully")
  //   },
  //   onError: async () => {
  //     toast.error("Could not download invenrory slip, Please add a new inventory item to download slip")
  //   },
  // });

  const downloadMutation = useMutation({
    mutationFn: async (downloadData) => {
      const response = await axiosInstance.post(
        `/securityOfficer/inventory/download`,
        downloadData,
      );

      // Assuming response.data.pdf contains the base64 string of the PDF
      const pdfBase64 = response.data.pdf;
      return pdfBase64;
    },
    onSuccess: async (pdfBase64) => {
      // Create a blob from the base64 PDF string
      const byteCharacters = atob(pdfBase64); // Decode base64 string
      const byteNumbers = new Uint8Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const blob = new Blob([byteNumbers], { type: "application/pdf" });

      // Create a URL for the blob
      const blobUrl = URL.createObjectURL(blob);

      // Open the PDF in a new window and trigger print dialog
      const pdfWindow = window.open(blobUrl);
      (pdfWindow as any).onload = () => {
        (pdfWindow as any).print(); /// Trigger print dialog
      };
    },
  });

  //handle download button click.
  const handleDownloadButtonClick = async (officerId: string, data: []) => {
    setSelectedOfficerId(officerId);
    await downloadMutation.mutateAsync(data as any);
  };

  return (
    <div className="px-8">
      <div className="my-8 mt-9 flex flex-row justify-between">
        <div className="flex ">
          <FaUserShield className=" mr-5" size={35} />
          <span className=" text-3xl font-bold text-primary-blue ">
            Security Personnel
          </span>
        </div>
      </div>

      <div className="mx-8 flex   justify-end "></div>
      <div className="grid grid-cols-2  gap-4 lg:grid-cols-4 lg:gap-8">
        <span className="p-input-icon-left">
          <i className="pi pi-search right-4" />
          <InputText
            placeholder="Search"
            className="w-full"
            value={searchTerms}
            onChange={(e) => setSearchTerms(e.target.value)}
          />
        </span>

        {/** <span className="input-icon-left">
            <InputText placeholder="Mobile Number" className="w-full" />
          </span>*/}

        <Dropdown
          value={selectedItem}
          onChange={handleLevelChange}
          options={items}
          //virtualScrollerOptions={{ itemSize: 38 }}
          placeholder="Level"
          className="w-full"
        />
        <Dropdown
          value={selectedStatus}
          onChange={handleFilterChange}
          options={status}
          //virtualScrollerOptions={{ itemSize: 38 }}
          placeholder="Status"
          className="w-full"
        />
        <Button
          onClick={() => {
            setModalState(modalStates.ADD);
            setisOpenAddModal(true);
          }}
          className="w-fit px-8  lg:ml-4"
        >
          Add New
        </Button>
        <Button onClick={handleClearFilters} className="mb-4 w-fit px-8">
          Clear Filters
        </Button>
      </div>

      <div className="max-h-[60vh] overflow-y-auto">
        <table className="w-full min-w-max max-w-[100vw] rounded-[2px] text-left text-sm md:text-base">
          <thead className="sticky top-0 z-10">
            <tr>
              {tableHeaders.map((head) => (
                <th key={head} className="bg-white p-4 text-primary-text">
                  {head}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredOfficers?.map((officer) => (
              <tr className="border-border bg-tableCellBg w-full border border-l-0 border-r-0 text-sm hover:bg-white md:text-base">
                <td className="p-4"> {officer.refNo}</td>
                <td className="p-4"> {officer.name}</td>
                <td className="p-4">{officer.appliedLevel}</td>
                <td className="p-4">{officer.mobileNo}</td>
                <td className="p-4">
                  <div className="flex items-center space-x-3">
                    <DotIcon
                      className={`h-3 w-3 ${
                        officer.activeStatus === true
                          ? "text-[#36A900]"
                          : officer.activeStatus === false
                            ? "text-[#708090]"
                            : "text-white"
                      }`}
                    />
                    {officer.activeStatus !== null && ( // Check if activeStatus is not null
                      <span>
                        {officer.activeStatus === true ? (
                          <span>Active</span>
                        ) : officer.activeStatus === false ? (
                          <span>Inactive</span>
                        ) : null}
                      </span>
                    )}
                  </div>
                </td>
                <td className="p-4">
                  {officer.interviewStatus && (
                    <div className="flex items-center space-x-3 ">
                      <DotIcon
                        className={`h-3 w-3 ${
                          officer.interviewStatus === "PENDING"
                            ? "text-[#0096FF]"
                            : officer.interviewStatus === "REJECTED"
                              ? "text-[#ca3737]"
                              : officer.interviewStatus === "APPROVED"
                                ? "text-[#69c43b]"
                                : "text-[#000000]"
                        }`}
                      />
                      {""}

                      <span>
                        {officer.interviewStatus.charAt(0).toUpperCase() +
                          officer.interviewStatus.slice(1).toLowerCase()}
                      </span>
                    </div>
                  )}
                </td>
                <td className="p-4">
                  {officer.currentStatus && (
                    <div className="flex items-center space-x-3 ">
                      <DotIcon
                        className={`h-3 w-3 ${
                          officer.currentStatus === "At Work"
                            ? "text-[#69c43b]"
                            : officer.currentStatus === "On Leave"
                              ? "text-[#ca7e37]"
                              : officer.currentStatus === "Left"
                                ? "text-[#ca3737]"
                                : "text-[#000000]"
                        }`}
                      />
                      {""}

                      <span>{officer.currentStatus}</span>
                    </div>
                  )}
                </td>
                <td className="p-4">
                  <span className="flex flex-row space-x-3">
                    <Button
                      icon="pi pi-pencil"
                      className=" !w-3 !border-none !bg-white !text-primary-blue !shadow-none"
                      onClick={() => {
                        handleEditButtonClick((officer as any).officerId);
                      }}
                    />
                    <Button
                      icon="pi pi-trash"
                      className=" !w-3 !border-none !bg-white !text-primary-blue !shadow-none"
                      onClick={() => {
                        handleDeleteButtonClick((officer as any).officerId);
                      }}
                    />
                    <Button
                      icon="pi pi-eye"
                      className=" !w-3 !border-none !bg-white !text-primary-blue !shadow-none"
                      onClick={() => {
                        handleViewButtonClick((officer as any).officerId);
                      }}
                    />
                    <Button
                      icon="pi pi-download"
                      className="!w-2 !border-none !bg-white !p-3 !text-primary-blue !shadow-none"
                      //loading={downloadMutation.isPending}
                      onClick={() => {
                        handleDownloadButtonClick(
                          (officer as any).officerId,
                          selectedOfficer,
                        );
                      }}
                    ></Button>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <AddEditSecurityPerson
        title={`${modalState} Security Officer`}
        isOpen={isOpenAddModal}
        onClose={() => setisOpenAddModal(false)}
        //onSave={() => setisOpenDeleteModal(false)}
        isEdit={modalState === modalStates.EDIT}
        securityPerson={selectedOfficer}
      />

      <ViewSecurityPerson
        title={`View Security Officer Details`}
        isOpen={isOpenViewModal}
        onClose={() => setisOpenViewModal(false)}
        //onSave={() => setisOpenDeleteModal(false)}
        isEdit={modalState === modalStates.EDIT}
        securityPerson={selectedOfficer}
      />

      <FormModal
        isOpen={isOpenDeleteModal}
        title="Delete Security Officer"
        onClose={() => setisOpenDeleteModal(false)}
        onSave={async () => {
          setisOpenDeleteModal(false);
          await deleteMutation.mutateAsync(selectedOfficer.id);
        }}
        submitText="Delete"
        submitColor="red"
        isSmall
      >
        <div>Are you sure you want to delete this Security Officer?</div>
      </FormModal>
    </div>
  );
};

export default SecurityPersonnel;
