import { FC } from "react";

const Footer: FC = () => {
  return (
    <div className="flex w-full items-center justify-center">
      <span className="text-xs">Copyright © by Tetranyde - 2024</span>
    </div>
  );
};

Footer.displayName = "Footer";
export default Footer;
