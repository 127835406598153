import { RoleAccess } from "@/constants/common";
import useAuthStore from "@/pages/auth/authStore";
import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({ name, children }) => {
  const { role } = useAuthStore((state) => state.user) || {};

  const canAccess = (role, routeName) => {
    if (
      RoleAccess[role]?.includes("All") ||
      RoleAccess[role]?.includes(routeName) ||
      !role
    ) {
      return true;
    }
    return false;
  };

  if (!canAccess(role, name)) {
    return <Navigate to="/dashboard/not-authorized" replace />;
  }

  return children;
};
