import { Navigate } from "react-router-dom";
import {
  AccountingPayroll,
  Administration,
  AdvancePayment,
  Customers,
  FoodSuppliers,
  Home,
  Inventory,
  OfficeStaff,
  Payroll,
  SecurityPersonnel
} from "./pages/dashboard";

import DotIcon from "@/assets/sidemenu-icons/dot.svg?react";
import { AiFillDollarCircle } from "react-icons/ai";
import { BsFillGrid1X2Fill } from "react-icons/bs";
import { FaCog, FaPizzaSlice, FaUserShield, FaUsers } from "react-icons/fa";
import { FaBuildingUser } from "react-icons/fa6";
import { HiSquare2Stack } from "react-icons/hi2";
import { ProtectedRoute } from "./components/ProtectedRoute";
import Forbidden from "./pages/Forbidden";
import { SignIn } from "./pages/auth";

const icon = {
  className: "w-6 h-6 text-inherit",
};

export const routes = [
  {
    layout: "dashboard",
    pages: [
      {
        icon: <BsFillGrid1X2Fill {...icon} className=" h-4 w-4" />,
        name: "Daily Roster",
        path: "/daily-roster",
        element: (
          <ProtectedRoute name="Daily Roster">
            <Home />
          </ProtectedRoute>
        ),
      },
      {
        icon: <FaUserShield {...icon} className=" h-5 w-5" />,
        name: "Security Personnel",
        path: "/security-personnel",
        element: (
          <ProtectedRoute name="Security Personnel">
            <SecurityPersonnel />
          </ProtectedRoute>
        ),
      },
      {
        icon: <FaBuildingUser {...icon} className=" h-5 w-5" />,
        name: "Customers",
        path: "/customer",
        element: (
          <ProtectedRoute name="Customers">
            <Customers />
          </ProtectedRoute>
        ),
      },
      {
        icon: <FaPizzaSlice {...icon} className=" h-5 w-5" />,
        name: "Food Suppliers",
        path: "/food-suppliers",
        element: <FoodSuppliers />,
      },
      {
        icon: <FaUsers {...icon} className="h-5 w-5" />,
        name: "Office Staff",
        path: "/office-staff",
        element: <OfficeStaff />,
      },
      {
        icon: <HiSquare2Stack {...icon} className="h-5 w-5" />,
        name: "Inventory",
        path: "/inventory",
        element: (
          <ProtectedRoute name="Inventory">
            <Inventory />
          </ProtectedRoute>
        ),
      },
      {
        icon: <AiFillDollarCircle {...icon} className="h-5 w-5" />,
        name: "Accounting & Payroll",
        path: "/accounting-payroll",
        element: (
          <ProtectedRoute name="Accounting & Payroll">
            <AccountingPayroll />
          </ProtectedRoute>
        ),
        children: [
          {
            icon: <DotIcon className=" size-5 text-inherit" />,
            name: "Payroll",
            path: "/accounting-payroll/payroll",
            element: <Payroll />,
          },
          {
            icon: <DotIcon className="size-5 text-inherit" />,
            name: "Advance Payment",
            path: "/accounting-payroll/advance-payment",
            element: <AdvancePayment />,
          },
        ],
      },
      // {
      //   icon: <BsBarChartFill {...icon} className="h-5 w-5" />,
      //   name: "Reports",
      //   path: "/reports",
      //   element: <Reports />,
      // },
      {
        icon: <FaCog {...icon} className="h-5 w-5" />,
        name: "Administration",
        path: "/administration",
        element: <Administration />,
      },
      {
        name: "Forbidden",
        path: "/not-authorized",
        element: <Forbidden />,
      },
    ],
  },
  {
    title: "auth",
    layout: "auth",
    pages: [
      {
        name: "sign-in",
        path: "/sign-in",
        element: <SignIn />,
      },
      {
        path: "/auth",
        element: <Navigate to="/auth/sign-in" replace />,
      },
      {
        path: "/",
        element: <Navigate to="/auth/sign-in" replace />,
      },
    ],
  },
  {
    layout: "dashboard",
    pages: [
      {
        path: "/dashboard",
        element: <Navigate to="/dashboard/daily-roster" replace />,
      },
    ],
  },
];
