import React, { useCallback, useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import Webcam from "react-webcam";
///import CloseIcon from "../assets/close-icon.svg?react";
//import { InputText } from "primereact/inputtext";

interface CommonModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (screenshot: string | null) => void;
  //children: React.ReactNode;
  isLoading?: boolean;
  showHeader?: boolean;
  submitColor?: string;
  submitText?: string;
  isSubmitDisabled?: boolean;
}

export const PhotoModal: React.FC<CommonModalProps> = ({
  isOpen,
  onClose,
  onSave,
  //children,
  isLoading = false,
  //showHeader = true,
  submitColor = "blue",
  isSubmitDisabled = false,
}) => {
  const webcamRef = useRef<Webcam>(null); // Reference to the Webcam component
  const [screenshot, setScreenshot] = useState<string | null>(); // State to store the screenshot

  //taking the screenshot and displaying
  const handleTakeSnapshot = useCallback(() => {
    const screenshotData = webcamRef.current?.getScreenshot();
    setScreenshot(screenshotData);
  }, []);

  //retake function
  const handleRetakePhoto = useCallback(() => {
    setScreenshot(null);
  }, []);

  //handle confirm photo
  const handleConfirmPhoto = useCallback(() => {
    screenshot && onSave(screenshot); // Pass the screenshot data to the onSave function
  }, [onSave, screenshot]);

  useEffect(() => {
    if (isOpen) {
      setScreenshot(null);
    }
  }, [isOpen]);

  return (
    <Dialog
      visible={isOpen}
      onHide={onClose}
      header="Capture Photo"
      pt={{
        content: { className: "h-[48vh]" },
        root: { className: "w-[540px]" },
      }}
    >
      <div className="max-h-[700px] flex-grow overflow-y-auto p-2">
        <div className=" h-80 w-80 rounded-lg bg-primary-border p-4 lg:ml-[100px]">
          {screenshot ? (
            <img
              src={screenshot}
              alt="Screenshot"
              className="h-full w-full object-cover"
            />
          ) : (
            <Webcam
              ref={webcamRef}
              height={1280}
              width={1280}
              screenshotFormat="image/jpeg"
            />
          )}
        </div>

        <div className=" !mt-9 flex w-full flex-row space-x-3 px-3">
          <Button
            label="Take Snapshot"
            className="outlined  rounded-lg !bg-green-900 font-semibold capitalize !text-white shadow-none"
            onClick={handleTakeSnapshot}
            disabled={isLoading}
          />

          <Button
            label="Confirm Photo"
            className="rounded-lg !bg-primary-blue shadow-none"
            onClick={handleConfirmPhoto}
            loading={isLoading}
            disabled={!screenshot || isSubmitDisabled}
            style={{ backgroundColor: submitColor }}
          />

          <Button
            label="Retake Photo"
            className="outlined  bg-whitefont-semibold rounded-lg capitalize text-primary-blue shadow-none"
            onClick={handleRetakePhoto}
            disabled={!screenshot || isSubmitDisabled}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default PhotoModal;
