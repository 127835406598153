import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Dashboard, Auth } from "@/layouts";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useEffect } from "react";
import useAuthStore from "./pages/auth/authStore";

const queryClient = new QueryClient();

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const storeUser = useAuthStore((state) => state.storeUser);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        storeUser(token);
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, [storeUser]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token && location.pathname !== "/auth/sign-in") {
      navigate("/auth/sign-in");
    }
  }, [location.pathname, navigate]);

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="/auth/*" element={<Auth />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="/dashboard/*" element={<Dashboard />} />
      </Routes>
    </QueryClientProvider>
  );
}

export default App;
