import FormModal from "@/layouts/FormModal";
import { FoodSupplier } from "@/models/foodSupplier";
import { modalStates } from "@/models/modelStates";
import axiosInstance from "@/utils/apiUtil";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FC, useEffect, useState } from "react";
import { FaPizzaSlice } from "react-icons/fa";
import { toast } from "react-toastify";
import AddEditFoodSupplier from "./AddEditFoodSupplier";

const tableHeaders = [
  "Supplier Name",
  "Address",
  "Contact Person Name",
  "Telepone No",
  "Actions",
];

const FoodSuppliers: FC = () => {
  const [isOpenAddModal, setisOpenAddModal] = useState(false);
  const [modalState, setModalState] = useState(modalStates.ADD);
  const [isOpenDeleteModal, setisOpenDeleteModal] = useState(false);
  const [selectedSupplierId, setSelectedSupplierId] = useState<string | null>(
    null,
  );
  const [filteredItems, setFilteredItems] = useState<FoodSupplier[]>([]);
  const [searchTerms, setSearchTerms] = useState("");

  //get All suppliers
  const { data: suppliers } = useQuery({
    queryKey: ["foodSuppliers"],
    queryFn: async () => {
      const response = await axiosInstance.get("/foodSuppliers");
      if (!response) {
        throw new Error("Network response was not ok");
      }

      return response.data;
    },
  });

  // Fetch supplier by ID (if selectedSupplierId is not null)
  const { data: selectedSupplier } = useQuery({
    queryKey: ["foodSuppliers", selectedSupplierId],
    queryFn: async () => {
      if (selectedSupplierId) {
        const response = await axiosInstance.get(
          `/foodSuppliers/${selectedSupplierId}`,
        );
        if (!response) {
          throw new Error("Network response was not ok");
        }
        return response.data;
      }
      return null;
    },
    enabled: !!selectedSupplierId, // Only fetch data if selectedSupplierId is truthy
  });

  // Function to handle edit button click
  const handleEditButtonClick = (supplierId: string) => {
    setModalState(modalStates.EDIT);
    setisOpenAddModal(true);
    setSelectedSupplierId(supplierId); // Set the selected supplier ID
  };

  // Function to handle delete button click
  const handleDeleteButtonClick = (supplierId: string) => {
    setisOpenDeleteModal(true);
    setSelectedSupplierId(supplierId); // Set the selected supplier ID
  };

  // Get the query client instance
  const queryClient = useQueryClient();

  const deleteMutation = useMutation({
    mutationFn: (supplierId) => {
      return axiosInstance.delete(`/foodSuppliers/${supplierId}`);
    },
    onSuccess: async () => {
      // Refetch the list of food suppliers after deleting a food supplier
      await queryClient.invalidateQueries("foodSuppliers" as any);
      toast.success("Food Supplier Details Deleted Successfully");
    },
    onError: async () => {
      toast.error("Could not delete food supplier details, Please try again");
    },
  });

  //searching name
  const { mutate: searchMutation } = useMutation({
    mutationFn: async (searchTerm) => {
      try {
        const response = await axiosInstance.post("/foodSupplier/search", {
          searchTerm,
        });
        return response.data;
      } catch (error) {
        throw new Error("Error searching Inventory by name");
      }
    },
    onSuccess: async (data) => {
      setFilteredItems(data);
    },
  });

  //search while typing
  useEffect(() => {
    if (searchTerms) {
      searchMutation(searchTerms as any);

      const filteredItems = searchTerms
        ? suppliers.filter((item) =>
            item.name.toLowerCase().includes(searchTerms.toLowerCase()),
          )
        : suppliers;

      setFilteredItems(filteredItems);
    } else {
      setFilteredItems(suppliers);
    }
  }, [searchTerms, suppliers, searchMutation]);

  return (
    <>
      <div className="ml-8 mt-9 flex flex-row">
        <FaPizzaSlice className=" mr-3" size={35} />
        <span className=" text-3xl font-bold text-primary-blue ">
          {" "}
          Food Suppliers
        </span>
      </div>
      <div className="mx-8 mt-12 flex flex-col gap-12">
        <div className="mt-4 flex h-fit w-full flex-col space-y-3 lg:w-3/4 lg:flex-row lg:items-center lg:space-x-14 lg:space-y-0">
          <span className="p-input-icon-left !w-[250px]">
            <i className="pi pi-search ml-[220px]" />
            <InputText
              placeholder="Search"
              value={searchTerms}
              onChange={(e) => setSearchTerms(e.target.value)}
            />
          </span>

          <Button
            className=" ml-8 "
            onClick={() => {
              setisOpenAddModal(true);
            }}
          >
            Add New
          </Button>
        </div>

        <div className="max-h-[60vh] overflow-y-auto">
          <table className="w-full min-w-max max-w-[100vw] rounded-[2px] text-left text-sm md:text-base">
            <thead className="sticky top-0 z-10">
              <tr>
                {tableHeaders.map((head) => (
                  <th key={head} className="bg-white p-4 text-primary-text">
                    {head}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredItems?.map((supplier) => (
                <tr className="border-border bg-tableCellBg w-full border border-l-0 border-r-0 text-sm hover:bg-white md:text-base">
                  <td className="p-4"> {supplier.name}</td>
                  <td className="p-4">{supplier.address}</td>
                  <td className="p-4">{supplier.contactPersonName}</td>
                  <td className="p-4">{supplier.contactPersonMobileNo}</td>
                  <td className="p-4">
                    <span className="flex flex-row space-x-3">
                      <Button
                        icon="pi pi-pencil"
                        className=" !w-3 !border-none !bg-white !text-primary-blue !shadow-none"
                        onClick={() => {
                          /*setModalState(modalStates.EDIT);
                      setisOpenAddModal(true);
                      getSupplierById(supplier.supplierId);*/
                          handleEditButtonClick(supplier.supplierId);
                        }}
                      />
                      <Button
                        icon="pi pi-trash"
                        className=" !w-3 !border-none !bg-white !text-primary-blue !shadow-none"
                        onClick={() => {
                          handleDeleteButtonClick(supplier.supplierId);
                        }}
                      />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <AddEditFoodSupplier
        title={`${modalState} Food Supplier`}
        isOpen={isOpenAddModal}
        onClose={() => setisOpenAddModal(false)}
        //onSave={() => setisOpenDeleteModal(false)}
        isEdit={modalState === modalStates.EDIT}
        foodSupplier={selectedSupplier}
      />

      <FormModal
        isOpen={isOpenDeleteModal}
        title="Delete Food Supplier"
        onClose={() => setisOpenDeleteModal(false)}
        onSave={async () => {
          setisOpenDeleteModal(false);
          await deleteMutation.mutateAsync(selectedSupplier.id);
        }}
        submitText="Delete"
        submitColor="red"
        isSmall
      >
        <div>Are you sure you want to delete this Food Supplier?</div>
      </FormModal>
    </>
  );
};

export default FoodSuppliers;
