import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { clsx } from "clsx";
import { useNavigate } from "react-router-dom";
import { Avatar } from "primereact/avatar";
import brandImg from "@/assets/logo.svg";
import { Button } from "primereact/button";
import useAuthStore from "./authStore";
import axiosInstance from "@/utils/apiUtil";
import { useMutation } from "@tanstack/react-query";
import "./auth.css";
import { AxiosError } from "axios";
import { jwtDecode } from "jwt-decode";
import { User } from "@/models/user";
import { RoleDefaultRoute } from "@/constants/common";

export function SignIn() {
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const storeUser = useAuthStore((state) => state.storeUser);

  const {
    mutate: signIn,
    isPending,
    error,
    reset,
    isError,
  } = useMutation({
    mutationFn: () => {
      return axiosInstance.post("/sign-in", {
        username: email,
        password,
      });
    },
    onSuccess: (res) => {
      storeUser(res.data.token);
      const decodedUser = jwtDecode(res.data.token) as User;
      const userRole = decodedUser.role;

      const defaultRoute =
        RoleDefaultRoute[userRole] || "/dashboard/daily-roster";
      navigate(defaultRoute);
    },
  });

  return (
    <div className="absolute inset-0 z-20 flex h-screen w-full flex-col md:flex-row">
      <div className="left flex w-full flex-col  justify-center  bg-primary-blue px-5 pb-16 pt-8 md:w-1/2 md:pb-0 md:pt-0 xl:px-16 ">
        <div className=" flex flex-col items-center lg:flex-row">
          <div className="mb-8 h-fit w-fit rounded-[10px] bg-white  p-2 shadow-[3px_2px_26px_10px_rgba(0,110,184,0.8)] md:mb-12 lg:mb-0  ">
            <Avatar
              className="flex h-[110px] w-[119px] items-center rounded-[10px] shadow-[0px_2px_71.3px_15px_rgba(255,255,255,0.35)]  "
              image={brandImg}
            />
          </div>
          <div className="mb-6 text-center text-2xl font-bold leading-[46px] text-white lg:mb-0 lg:w-1/2 lg:pl-12 lg:text-left lg:text-[40px]">
            WORKFORCE MANAGEMENT
          </div>
        </div>
      </div>
      <div className="flex w-full  flex-col justify-center   px-5 md:w-1/2  lg:px-36">
        <div className=" mb-8 space-y-8">
          {" "}
          <div className="text-[32px] font-semibold text-primary-blue">
            Sign-in
          </div>
          <div className="text-descriptionText">
            Enter your user id & password to login to the system
          </div>
        </div>

        <InputText
          // type="email"
          placeholder="Username"
          size="large"
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
            reset();
          }}
          className={clsx("mb-[34px]", { "p-invalid": isError })}
        />
        <InputText
          type="password"
          placeholder="Password"
          size="large"
          value={password}
          className={clsx({ "p-invalid": isError })}
          onChange={(event) => {
            setPassword(event.target.value);
            reset();
          }}
        />
        {error && error instanceof AxiosError && (
          <div className=" py-1 text-sm text-primary-red">
            {error.response?.data.error}
          </div>
        )}

        <div className=" my-10 cursor-pointer text-sm  text-primary-link underline">
          Reset Password
        </div>
        {/* <div className="-ml-2.5">
          <Checkbox label="Remember Me" />
        </div> */}

        <Button
          size="large"
          onClick={() => signIn()}
          loading={isPending}
          disabled={password.length === 0 || email.length === 0}
          className="w-full shadow-[0px_2px_8px_0px_rgba(0,110,184,0.8)] "
        >
          Sign-in
        </Button>
      </div>
    </div>
  );
}

export default SignIn;
