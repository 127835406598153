import FormModal from "@/layouts/FormModal";
import { modalStates } from "@/models/modelStates";
import { OfficeStaff } from "@/models/officeStaff";
import axiosInstance from "@/utils/apiUtil";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FC, useEffect, useState } from "react";
import { FaUsers } from "react-icons/fa";
import { toast } from "react-toastify";
import DotIcon from "../../../assets/dot.svg?react";
import AddEditStaffMember from "./AddEditStaffMember";

const tableHeaders = ["Staff Member Name", "Role", "Status", "Actions"];

const OfficeStaff: FC = () => {
  const [isOpenAddModal, setisOpenAddModal] = useState(false);
  const [modalState, setModalState] = useState(modalStates.ADD);
  const [isOpenDeleteModal, setisOpenDeleteModal] = useState(false);
  const [selectedStaffId, setSelectedStaffId] = useState<string | null>(null);
  const [filteredItems, setFilteredItems] = useState<OfficeStaff[]>([]);
  const [searchTerms, setSearchTerms] = useState("");

  //get All staff members
  const { data: staff } = useQuery({
    queryKey: ["staffMembers"],
    queryFn: async () => {
      const response = await axiosInstance.get("/staffMembers");
      if (!response) {
        throw new Error("Network response was not ok");
      }

      return response.data;
    },
  });

  // Fetch staff by ID (if selectedStaffId is not null)
  const { data: selectedStaffMember } = useQuery({
    queryKey: ["staffMembers", selectedStaffId],
    queryFn: async () => {
      if (selectedStaffId) {
        const response = await axiosInstance.get(
          `/StaffMembers/${selectedStaffId}`,
        );
        if (!response) {
          throw new Error("Network response was not ok");
        }
        return response.data;
      }
      return null;
    },
    enabled: !!selectedStaffId, // Only fetch data if selectedStaffId is truthy
  });

  // Function to handle edit button click
  const handleEditButtonClick = (staffId: string) => {
    setModalState(modalStates.EDIT);
    setisOpenAddModal(true);
    setSelectedStaffId(staffId); // Set the selected staff ID
  };

  // Function to handle delete button click
  const handleDeleteButtonClick = (staffId: string) => {
    setisOpenDeleteModal(true);
    setSelectedStaffId(staffId); // Set the selected staff ID
  };

  //get query client insatnce
  const queryClient = useQueryClient();

  //deleting the staff member
  const deleteMutation = useMutation({
    mutationFn: (staffId) => {
      return axiosInstance.delete(`/StaffMembers/${staffId}`);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("staffMembers" as any);
      toast.success("Staff Member Details Deleted Succesfully");
    },
    onError: async () => {
      toast.error("Could not delete staff member details, Please try again");
    },
  });

  //searching name
  const { mutate: searchMutation } = useMutation({
    mutationFn: async (searchTerm) => {
      try {
        const response = await axiosInstance.post("/staffMember/search", {
          searchTerm,
        });
        return response.data;
      } catch (error) {
        throw new Error("Error searching Inventory by name");
      }
    },
    onSuccess: async (data) => {
      setFilteredItems(data);
    },
  });

  //search while typing
  useEffect(() => {
    if (searchTerms) {
      searchMutation(searchTerms as any);

      const filteredItems = searchTerms
        ? staff.filter((item) =>
            item.name.toLowerCase().includes(searchTerms.toLowerCase()),
          )
        : staff;

      setFilteredItems(filteredItems);
    } else {
      setFilteredItems(staff);
    }
  }, [searchTerms, staff, searchMutation]);

  return (
    <>
      <div className="ml-8 mt-9 flex flex-row">
        <FaUsers className=" mr-3" size={35} />
        <span className=" text-3xl font-bold text-primary-blue ">
          {" "}
          Office Staff
        </span>
      </div>
      <div className="mx-8 mt-12 flex flex-col gap-12">
        <div className="mt-4 flex h-fit w-full flex-col space-y-3 lg:w-3/4 lg:flex-row lg:items-center lg:space-x-14 lg:space-y-0">
          <span className="p-input-icon-left !w-[250px]">
            <i className="pi pi-search ml-[220px]" />
            <InputText
              placeholder="Search"
              value={searchTerms}
              onChange={(e) => setSearchTerms(e.target.value)}
            />
          </span>

          <Button
            className=" ml-8 "
            onClick={() => {
              setisOpenAddModal(true);
            }}
          >
            Add New
          </Button>
        </div>

        <div className="max-h-[60vh] overflow-y-auto">
          <table className="w-full min-w-max max-w-[100vw] rounded-[2px] text-left text-sm md:text-base">
            <thead className="sticky top-0 z-10">
              <tr>
                {tableHeaders.map((head) => (
                  <th key={head} className="bg-white p-4 text-primary-text">
                    {head}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredItems?.map((member) => (
                <tr className="border-border bg-tableCellBg w-full border border-l-0 border-r-0 text-sm hover:bg-white md:text-base">
                  <td className="p-4"> {member.name}</td>
                  <td className="p-4">{member.role}</td>
                  <td className="p-4">
                    <div className="flex items-center space-x-3">
                      <DotIcon
                        className={`h-4 w-4 ${member.isActive ? "text-[#36A900]" : "text-gray-500"}`}
                      />
                      <span>{member.isActive ? "Active" : "Inactive"}</span>
                    </div>
                  </td>
                  <td className="p-4">
                    <span className="flex flex-row space-x-3">
                      <Button
                        icon="pi pi-pencil"
                        className=" !w-3 !border-none !bg-white !text-primary-blue !shadow-none"
                        onClick={() => {
                          handleEditButtonClick(member.staffId);
                        }}
                      />
                      <Button
                        icon="pi pi-trash"
                        className=" !w-3 !border-none !bg-white !text-primary-blue !shadow-none"
                        onClick={() => {
                          handleDeleteButtonClick(member.staffId);
                        }}
                      />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <AddEditStaffMember
        title={`${modalState} Office Staff Member`}
        isOpen={isOpenAddModal}
        onClose={() => setisOpenAddModal(false)}
        //onSave={() => setisOpenDeleteModal(false)}
        isEdit={modalState === modalStates.EDIT}
        staffMember={selectedStaffMember}
      />

      <FormModal
        isOpen={isOpenDeleteModal}
        title="Delete Staff Member"
        onClose={() => setisOpenDeleteModal(false)}
        onSave={async () => {
          setisOpenDeleteModal(false);
          await deleteMutation.mutateAsync(selectedStaffMember.id);
        }}
        submitText="Delete"
        isSmall
        submitColor="red"
      >
        <div>Are you sure you want to delete this Staff Member?</div>
      </FormModal>
    </>
  );
};

export default OfficeStaff;
