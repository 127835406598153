import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
///import CloseIcon from "../assets/close-icon.svg?react";
//import { InputText } from "primereact/inputtext";

interface CommonModalProps {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  onSave: () => void;
  children: React.ReactNode;
  isLoading?: boolean;
  showHeader?: boolean;
  submitColor?: string;
  submitText?: string;
  isSubmitDisabled?: boolean;
  isSmall?: boolean;
  extraLarge? : boolean;
}

export const ViewModal: React.FC<CommonModalProps> = ({
  isOpen,
  title,
  onClose,
  onSave,
  children,
  isLoading = false,
  submitColor = "blue",
  submitText = "Close",
  isSubmitDisabled = false,
  isSmall = false,
  extraLarge = false,
}) => {
  return (
    <Dialog
      visible={isOpen}
      onHide={onClose}
      header={title}
      pt={{
        content: { className: isSmall ? "h-[20vh]" : "h-[73vh]" },
        root: { className: isSmall ? "w-[540px]" : extraLarge ? "w-[80vw]":"w-[60vw]" },
      }}
    >
      <div className="max-h-[60vh] flex-grow overflow-y-auto px-8 py-6">
        {children}
      </div>
      <div className="absolute bottom-0 mb-10 flex w-full justify-end px-8">
        <Button
          label={submitText}
          className="w-[134px] rounded-[3px] !bg-primary-blue "
          onClick={onSave}
          type="submit"
          loading={isLoading}
          disabled={isSubmitDisabled}
          style={{ backgroundColor: submitColor }}
        />
      </div>
    </Dialog>
  );
};

export default ViewModal;
