import ViewModal from "@/layouts/ViewModal";
import axiosInstance from "@/utils/apiUtil";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import "primeicons/primeicons.css";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";

interface EditProcessedPayrollProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  loading?: boolean;
  year: number;
  monthString: string;
}

const tableHeaders = [
  "Ref No",
  "Name",
  "Net Salary (LKR)",
  "Processed Date",
  "Payment Method",
  "Cheque Number",
];

const tableHeaders2 = [
  "Ref No",
  "Name",
  "Net Salary (LKR)",
  "Processed Date",
  "Payment Method",
  "Reference Number",
];

const UpdateProcessedPayrolls: React.FC<EditProcessedPayrollProps> = ({
  isOpen,
  onClose,
  title,
  loading,
  year,
  monthString,
}) => {
  //get All processed payroll objects
  const { data: procesedPayrolls } = useQuery({
    queryKey: ["ProcessedPayrolls", year, monthString],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `/payrolls/processed/payMethod//${year}/${monthString}`,
      );
      if (!response) {
        throw new Error("Network response was not ok");
      }
      return response.data;
    },
  });
  //using a useState array to update the cheque fields
  const [tdChequeNumber, setTdChequeNumber] = useState<{
    [key: string]: string;
  }>({});
  const [bankTransferRefNumber, setBankTransferRefNumber] = useState<
    string | undefined
  >(undefined);

  //Get te query client instance
  const queryClient = useQueryClient();

  //updating the cheque payments cheque number
  const updateMutation = useMutation({
    mutationFn: ({ officerId, chequeNumber }: any) => {
      const data = { chequeNumber: chequeNumber };     
      return axiosInstance.patch(
        `/payroll/processed/cheque/${officerId}/${year}/${monthString}`,
        data,
      );
    },
    onSuccess: async () => {
      
      //Refetch the list of items after adding a new payroll item
      await queryClient.invalidateQueries("ProcessedPayrolls" as any);
    },
  });

  //updating the bank payments transfer number
  const updateBankMutation = useMutation({
    mutationFn: ({ officerId, bankTransferNumber }: any) => {
      const data = { bankTransferNumber: bankTransferNumber };     
      return axiosInstance.patch(
        `/payroll/processed/bank/${officerId}/${year}/${monthString}`,
        data,
      );
    },
    onSuccess: async () => {      
      //Refetch the list of items after adding a new payroll item
      await queryClient.invalidateQueries("ProcessedPayrolls" as any);
    },
  });

  return (
    <>
      <ViewModal
        isOpen={isOpen}
        title={title}
        onClose={onClose}
        onSave={onClose}
        isLoading={loading}
      >
        <div className="flex flex-col space-y-10">
          <div className=" text-lg font-semibold text-primary-text">
            Cheque Payments
          </div>

          <table>
            <thead>
              <tr>
                {tableHeaders.map((head) => (
                  <th
                    key={head}
                    className="border border-b-2 border-l-0 border-r-0 border-t-0 border-primary-border bg-white p-4 text-start text-sm font-semibold text-primary-text"
                  >
                    {head}
                  </th>
                ))}
              </tr>
            </thead>
            {procesedPayrolls?.map((payment) =>
              payment?.paymentMethod === "Cheque" ? (
                <tbody>
                  <td className=" p-4"> {payment.refNo}</td>
                  <td className=" p-4"> {payment.name}</td>
                  <td className=" p-4">
                    {" "}
                    {payment.netSalary.toLocaleString()}
                  </td>
                  <td className=" p-4">
                    {" "}
                    {new Date(payment.processedDate).toLocaleDateString()}
                  </td>
                  <td className=" p-4"> {payment.paymentMethod}</td>
                  <td className=" p-4">
                    <div className="relative">
                      <InputText
                        onChange={(e) => {
                          setTdChequeNumber((prev) => ({
                            ...prev,
                            [payment.officerId]: e.target.value,
                          }));
                        }}
                        value={tdChequeNumber[payment.officerId] || ""}
                        className={`!bg-inputBg !border-2 ${payment.chequeNumber ? "!border-green-500" : ""}`}
                      />
                      <i
                        className="pi pi-check absolute right-5 top-1/2 mr-4 -translate-y-1/2 transform cursor-pointer"
                        onClick={() => {
                          updateMutation.mutateAsync({
                            officerId: payment.officerId,
                            chequeNumber:
                              tdChequeNumber[payment.officerId] || "",
                          });
                        }}
                      />
                      <i
                        className="pi pi-times absolute right-1 top-1/2 mr-2 -translate-y-1/2 transform cursor-pointer"
                        onClick={() => {
                          setTdChequeNumber((prev) => ({
                            ...prev,
                            [payment.officerId]: "",
                          }));
                        }}
                      />
                    </div>
                  </td>
                </tbody>
              ) : null,
            )}
          </table>

          <div className=" pt-6 text-lg font-semibold text-primary-text">
            Bank Transfers
          </div>

          <table>
            <thead>
              <tr>
                {tableHeaders2.map((head) => (
                  <th
                    key={head}
                    className="border border-b-2 border-l-0 border-r-0 border-t-0 border-primary-border bg-white p-4 text-start text-sm font-semibold text-primary-text"
                  >
                    {head}
                  </th>
                ))}
              </tr>
            </thead>
            {procesedPayrolls?.map((payment) =>
              payment?.paymentMethod === "Bank" ? (
                <tbody>
                  <td className=" p-4"> {payment.refNo}</td>
                  <td className=" p-4"> {payment.name}</td>
                  <td className=" p-4">
                    {" "}
                    {payment.netSalary.toLocaleString()}
                  </td>
                  <td className=" p-4">
                    {" "}
                    {new Date(payment.processedDate).toLocaleDateString()}
                  </td>
                  <td className=" p-4"> {payment.paymentMethod}</td>
                  <td className=" p-4">
                    <div className="relative">
                      <InputText
                        onChange={(e) => {
                          setBankTransferRefNumber(e.target.value);
                        }}
                        defaultValue={payment.bankTransferNumber}
                        value={bankTransferRefNumber}
                        className={`!bg-inputBg !border-2 ${payment.bankTransferNumber ? "!border-green-500" : ""}`}
                      />
                      <i
                        className="pi pi-check absolute right-5 top-1/2 mr-4 -translate-y-1/2 transform cursor-pointer"
                        onClick={() => {
                          updateBankMutation.mutateAsync({
                            officerId: payment.officerId,
                            bankTransferNumber: bankTransferRefNumber,
                          });
                        }}
                      />
                      <i
                        className="pi pi-times absolute right-1 top-1/2 mr-2 -translate-y-1/2 transform cursor-pointer"
                        onClick={() => {
                          setBankTransferRefNumber("");
                        }}
                      />
                    </div>
                  </td>
                </tbody>
              ) : null,
            )}
          </table>
        </div>
      </ViewModal>
    </>
  );
};

export default UpdateProcessedPayrolls;
