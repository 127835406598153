import { FC } from "react";

interface FormLabelProps {
  label: string;
  required?: boolean;
}

export const FormLabel: FC<FormLabelProps> = ({ label, required = false }) => {
  return (
    <label className="block py-1 text-sm font-normal">
      {label}
      {required && <span className="text-primary-red">*</span>}
    </label>
  );
};