import { FC, useState } from "react";
import { routes } from "@/routes";
import { NavLink } from "react-router-dom";
import ArrowRight from "@/assets/sidemenu-icons/right-arrow.svg?react";
import ArrowDown from "@/assets/sidemenu-icons/down-arrow.svg?react";
import React from "react";
import useSidebarStore from "@/stores/SidebarStore";
import useAuthStore from "@/pages/auth/authStore";
import { RoleAccess } from "@/constants/common";

const Sidenav: FC = () => {
  const { isExpanded, toggleSidebar } = useSidebarStore();
  const [openSubMenus, setOpenSubMenus] = useState({});
  const { role } = useAuthStore((state) => state.user) || { role: "super" };

  const toggleSubMenu = (key) => {
    setOpenSubMenus((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const excludedPaths = [
    "/inbox/compose",
    "/inbox/:id",
    "/sign-in",
    "/sign-up",
    "/",
    "/not-authorized",
  ];
  let filteredRoutes = routes
    .filter(({ title, pages }) => {
      if (title === "auth") {
        return !pages.some((page) => excludedPaths.includes(page.path));
      }
      return true;
    })
    .map(({ layout, title, pages }, key) => {
      const regexExcludedPaths = excludedPaths.map(
        (path) => new RegExp("^" + path.replace(/:\w+/g, "[^/]+") + "$"),
      );

      const filteredPages = pages.filter(
        (page) => !regexExcludedPaths.some((regex) => regex.test(page.path)),
      );

      return {
        layout,
        title,
        pages: filteredPages,
        key,
      };
    });

  filteredRoutes = filteredRoutes.map((route) => ({
    ...route,
    pages: route.pages.filter((page) => {
      if (role === "Manager" || role === "super") return true; // Manager/Admin has access to all items
      if (!RoleAccess[role]) return false; // In case the role is not defined in RoleAccess
      return (
        RoleAccess[role].includes((page as any).name) ||
        RoleAccess[role].includes("All")
      );
    }),
  }));

  return (
    <>
      {isExpanded && (
        <div
          className="fixed inset-0 z-20 lg:hidden"
          onClick={toggleSidebar}
          style={{ background: "rgba(0, 0, 0, 0.5)" }}
        />
      )}
      <div
        className={`fixed  left-0 z-30 transform ${isExpanded ? "translate-x-0 " : "-translate-x-full lg:translate-x-0"} lg:transition-width  ${isExpanded ? "w-3/4 md:w-[30%] lg:w-[240px]" : "md:w-[0px]"} overflow-hidden bg-secondary-blue py-9 duration-100 `}
      >
        {filteredRoutes.map(({ layout, pages }, key) => (
          <ul key={key} className="flex min-h-screen flex-col gap-1   ">
            {(pages as any).map(({ icon, name, path, children }, key) => (
              <React.Fragment key={key}>
                <li onClick={() => children && toggleSubMenu(name)}>
                  <NavLink to={`/${layout}${path}`}>
                    {({ isActive }) => (
                      <div
                        className={`flex items-center ${isActive && "bg-primary-active"} px-4 py-5 text-sm text-white hover:bg-primary-active`}
                      >
                        <div className="mr-3">{icon}</div>
                        {name}
                        {children &&
                          (openSubMenus[name] ? (
                            <ArrowDown className="ml-auto" />
                          ) : (
                            <ArrowRight className="ml-auto" />
                          ))}
                      </div>
                    )}
                  </NavLink>
                </li>
                {children && openSubMenus[name] && (
                  <div className="-mt-1 bg-primary-darkBlue ">
                    <ul className=" relative ml-6 border-l border-secondary-gray  py-2 ">
                      {children.map((child) => (
                        <li key={child.name}>
                          <NavLink to={`/${layout}${child.path}`}>
                            {({ isActive }) => (
                              <div
                                className={`flex items-center rounded-sm ${isActive ? "text-white" : "text-secondary-gray"}  py-3 text-sm  hover:text-white`}
                              >
                                <div className="mr-3">{child.icon}</div>
                                {child.name}
                              </div>
                            )}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </React.Fragment>
            ))}
          </ul>
        ))}
      </div>
    </>
  );
};

Sidenav.displayName = "Sidenav";
export default Sidenav;
