import FormModal from "@/layouts/FormModal";
import { FormLabel } from "@/layouts/Label";
import { AdvancePayment } from "@/models/advancePayment";
import axiosInstance from "@/utils/apiUtil";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import "primeicons/primeicons.css";
import { Calendar } from "primereact/calendar";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";

interface AddEditAdvanceProps {
  //onSave?: (formData: AdvancePayment) => Promise<void>; to commmit commented
  isOpen: boolean;
  onClose: () => void;
  title: string;
  loading?: boolean;
  advance?: AdvancePayment; // Data for pre-filling the form in edit mode
  isEdit?: boolean; // Flag to indicate data loading for edit mode
  year: number;
  monthString: string;
}

const tableHeaders = ["Amount", "Date"];
const paymentMethods = ["Pay in Single", "Pay in two", "Pay in three"];

const options = paymentMethods.map((pay) => pay);

const payMethods = ["Bank Transfer", "Visiting Officer", "Cash"];

const payOptions = payMethods.map((method) => method);

const AddEditAdvance: React.FC<AddEditAdvanceProps> = ({
  isOpen,
  onClose,
  title,
  loading,
  advance,
  year,
  monthString,
}) => {
  const {
    handleSubmit,
    reset,
    control,
    register,
    watch,
    //formState: { /*errors*/ }
  } = useForm<AdvancePayment>();

  const payMethod = watch("payMethod");

  const [selectedOfficerId, setSelectedOfficerId] = useState<string | null>(
    null,
  );

  useEffect(() => {
    if (isOpen) {
      reset({
        rank: "",
        payment: 0,
        paymentMethod: "",
        payMethod: "",
        officerId: "",
        voName: "",
        bankTransferNo: 0,
      });
    }
  }, [advance, reset, isOpen]);

  const onSubmit: SubmitHandler<AdvancePayment> = async (formData) => {
    mutation.mutateAsync({
      officerId: selectedOfficerId,
      payment: formData.payment,
      paymentMethod: formData.paymentMethod,
      payMethod: formData.payMethod,
      voName: formData.voName,
      bankTransferNo: formData.bankTransferNo,
      pvNumber: formData.pvNumber,
      date: formData.date || new Date(),
    });

    reset();
    onClose();
  };

  //get All security Officers
  const { data: officers } = useQuery({
    queryKey: ["securityOfficers"],
    queryFn: async () => {
      const response = await axiosInstance.get("/securityOfficers/active");
      if (!response) {
        throw new Error("Network response was not ok");
      }

      return response.data;
    },
  });

  //get all visiting officers
  const { data: visitingOfficers } = useQuery({
    queryKey: ["staffMembers"],
    queryFn: async () => {
      const response = await axiosInstance.get("/visitingOfficers");
      if (!response) {
        throw new Error("Network response was not ok");
      }

      return response.data;
    },
  });

  const visitingOfficerNames = visitingOfficers?.map((vo) => vo.name);

  useEffect(() => {}, [year]);

  //fetch officer details and due amounts
  const { data: selectedOfficer } = useQuery({
    queryKey: ["Payroll", selectedOfficerId],
    queryFn: async () => {
      if (selectedOfficerId) {
        const response = await axiosInstance.get(
          `/advancePayments/${selectedOfficerId}/${year}/${monthString}`,
        );
        if (!response) {
          throw new Error("Network response was not ok");
        }
        return response.data;
      }
      return null;
    },
    enabled: !!selectedOfficerId, // Only fetch data if selectedCustomerId is truthy
  });

  const officerOptions = officers?.map((officer) => ({
    value: officer.officerId,
    label: `${officer.name} - ${officer.refNo}`,
  }));

  const officerOptionTemplate = (option) => (
    <div className="align-items-center flex">
      <div>{option.label}</div>
    </div>
  );

  const officerSelectedTemplate = (option) => {
    if (option) {
      return (
        <div className="align-items-center flex">
          <div>{option.label}</div>
        </div>
      );
    }
    return null; // Return null if option is null or undefined
  };

  const handleRankChange = (e: DropdownChangeEvent) => {
    const { value } = e;
    setSelectedOfficerId(value); // Set the selected officer ID
  };

  // Get the query client instance
  const queryClient = useQueryClient();

  // adding advance payment
  const mutation = useMutation({
    mutationFn: async ({
      officerId,
      payment,
      paymentMethod,
      payMethod,
      voName,
      bankTransferNo,
      pvNumber,
      date,
    }: any) => {
      const postData = {
        payment: payment,
        paymentMethod: paymentMethod,
        payMethod: payMethod,
        voName: voName,
        bankTransferNo: bankTransferNo,
        pvNumber: pvNumber,
        date: date,
      };

      const response = await axiosInstance.post(
        `/advancePayment/${officerId}/${year}/${monthString}`,
        postData,
      );
      return response.data;
    },
    onSuccess: async () => {
      // Refetch the list of payments after adding the advanced payments
      await queryClient.invalidateQueries("AdvancePayments" as any);
      toast.success("Advance Payment Added Successfully");
    },
  });

  return (
    <>
      <FormModal
        isOpen={isOpen}
        title={title}
        onClose={onClose}
        onSave={handleSubmit(onSubmit)}
        isLoading={loading}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col space-y-10">
            <div className=" text-lg font-semibold text-primary-text">
              Security Officer Details
            </div>
            <div>
              <FormLabel label="Officer Name" />
              <Dropdown
                value={selectedOfficerId}
                onChange={handleRankChange}
                options={officerOptions}
                // optionLabel="name"
                placeholder="Select a Security Officer"
                filter
                valueTemplate={officerSelectedTemplate}
                itemTemplate={officerOptionTemplate}
                className="!h-12 w-full"
              />
            </div>
            {/* <div>    //commenting without removing in case the field is required again in future.
              <FormLabel label="Rank" />
              <InputText
                defaultValue={fetchedOfficer?.appliedLevel}
                readOnly
                className="w-full"
              />
            </div> */}
            <div className="grid grid-cols-2 gap-4">
              <div>
                <FormLabel label="Advance Payment" />
                <Controller
                  name={`payment`}
                  control={control}
                  rules={{ required: "This field is required." }}
                  render={({ field }) => (
                    <InputNumber
                      value={field.value}
                      onValueChange={(e) => field.onChange(e.value)}
                      useGrouping={true}
                      minFractionDigits={2}
                      className="w-full"
                    />
                  )}
                />
              </div>

              <div>
                <FormLabel label="Payment Voucher Number" />
                <InputText
                  {...register("pvNumber", {
                    required: "This field is required.",
                  })}
                  className="w-full"
                />
              </div>
              <div>
                <FormLabel label="Date" />
                <Controller
                  name={`date`}
                  control={control}
                  render={({ field }) => (
                    <Calendar
                      id={field.name}
                      value={field.value as any}
                      onChange={(e) => field.onChange(e.value)}
                      placeholder="DD - MM - YYYY"
                      showIcon
                      className="w-full"
                      dateFormat="dd/mm/yy"
                    />
                  )}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <div>
                <FormLabel label="Select Payment Method" />
                <Controller
                  name="payMethod"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Dropdown
                      {...field}
                      options={payOptions}
                      className="w-full"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </div>
              {payMethod == "Visiting Officer" && (
                <div>
                  <FormLabel label="Select Visiting Officer" />
                  <Controller
                    name="voName"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Dropdown
                        {...field}
                        options={visitingOfficerNames}
                        className="w-full"
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                      />
                    )}
                  />
                </div>
              )}
              {payMethod == "Bank Transfer" && (
                <div>
                  <FormLabel label="Bank Transfer No." />
                  <Controller
                    name="bankTransferNo"
                    control={control}
                    render={({ field }) => (
                      <InputNumber
                        value={field.value}
                        onValueChange={(e) => field.onChange(e.value)}
                        className="w-full"
                        useGrouping={false}
                      />
                    )}
                  />
                </div>
              )}
              <div>
                <FormLabel label="Select Deducing Method" />
                <Controller
                  name="paymentMethod"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Dropdown
                      {...field}
                      options={options}
                      className="w-full"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </div>
            </div>
            <div>
              <div className="grid grid-cols-1 gap-2 lg:grid-cols-2  lg:gap-10">
                <div className=" text-lg font-semibold text-primary-text">
                  Previous Due Advance Payments
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <table>
                <thead>
                  <tr>
                    {tableHeaders.map((head) => (
                      <th
                        key={head}
                        className="border border-b-2 border-l-0 border-r-0 border-t-0 border-primary-border bg-white p-4 text-start text-sm font-semibold text-primary-text"
                      >
                        {head}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {selectedOfficer?.map((officer) => (
                    <tr>
                      <td className=" p-4">{officer.payment}</td>
                      <td className=" p-4">
                        {" "}
                        {new Date(officer.date).toLocaleDateString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </FormModal>
    </>
  );
};

export default AddEditAdvance;
