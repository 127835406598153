import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { FC, useState } from "react";
import { FaCog } from "react-icons/fa";
// import AddEditFoodSupplier from "./AddEditFoodSupplier";
// import { modalStates } from "@/models/modelStates";
// import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
// import axiosInstance from "@/utils/apiUtil";
// import FormModal from "@/layouts/FormModal";
import { Checkbox } from "primereact/checkbox";
import { InputSwitch } from "primereact/inputswitch";

const Administration: FC = () => {
  const [, setisOpenAddModal] = useState(false);
  // const [modalState, setModalState] = useState(modalStates.ADD);
  // const [isOpenDeleteModal, setisOpenDeleteModal] = useState(false);
  // const [selectedSupplierId, ] = useState<string | null>(
  //   null,
  // );

  //get All suppliers
  // const { data: suppliers } = useQuery({
  //   queryKey: ["foodSuppliers"],
  //   queryFn: async () => {
  //     const response = await axiosInstance.get("/foodSuppliers");
  //     if (!response) {
  //       throw new Error("Network response was not ok");
  //     }

  //     return response.data;
  //   },
  // });

  // Fetch supplier by ID (if selectedSupplierId is not null)
  // const { data: selectedSupplier } = useQuery({
  //   queryKey: ["foodSuppliers", selectedSupplierId],
  //   queryFn: async () => {
  //     if (selectedSupplierId) {
  //       const response = await axiosInstance.get(
  //         `/foodSuppliers/${selectedSupplierId}`,
  //       );
  //       if (!response) {
  //         throw new Error("Network response was not ok");
  //       }
  //       return response.data;
  //     }
  //     return null;
  //   },
  //   enabled: !!selectedSupplierId, // Only fetch data if selectedSupplierId is truthy
  // });

  // // Function to handle edit button click
  // const handleEditButtonClick = (supplierId: string) => {
  //   setModalState(modalStates.EDIT);
  //   setisOpenAddModal(true);
  //   setSelectedSupplierId(supplierId); // Set the selected supplier ID
  // };

  // // Function to handle delete button click
  // const handleDeleteButtonClick = (supplierId: string) => {
  //   setisOpenDeleteModal(true);
  //   setSelectedSupplierId(supplierId); // Set the selected supplier ID
  // };

  /*delete
  const deleteMutation = useMutation({
    mutationFn: (supplierId) => {
      return axiosInstance.delete(`/foodSuppliers/${supplierId}`);
    },
    onSuccess: () => {
       
    },
  });*/

  // Get the query client instance
  // const queryClient = useQueryClient();

  // const deleteMutation = useMutation({
  //   mutationFn: (supplierId) => {
  //     return axiosInstance.delete(`/foodSuppliers/${supplierId}`);
  //   },
  //   onSuccess: async () => {
  //
  //     // Refetch the list of food suppliers after deleting a food supplier
  //     await queryClient.invalidateQueries("foodSuppliers" as any);
  //   },
  // });

  // const depSet = [
  //   {
  //     role: "Receptionist",
  //     bypass: false,
  //     modules: [{ moduleName: "Security Personnel", permissions: ["SO-2"] }],
  //   },
  //   {
  //     role: "Manager",
  //     bypass: true,
  //     modules: [],
  //   },
  // ];

  const roleSet = [
    {
      role: "Receptionist",
      bypass: false,
      modules: [{ moduleName: "Security Personnel", permissions: ["SO-2"] }],
    },
    {
      role: "Manager",
      bypass: true,
      modules: [],
    },
  ];

  const permissionsSet = [
    {
      module: "Security Personnel",
      permissionsList: [
        { code: "SO-4", desc: "Mark interview status and feedback" },
        // { code: "SO-1", desc: "All" },
        { code: "SO-2", desc: "Add basic form details" },
      ],
    },
    {
      module: "Customers",
      permissionsList: [
        { code: "C-4", desc: "Edit customer" },
        // { code: "SO-1", desc: "All" },
        { code: "C-1", desc: "Add customer" },
      ],
    },
    {
      module: "Food Suppliers",
      permissionsList: [
        { code: "FS-4", desc: "Edit Supplier" },
        // { code: "SO-1", desc: "All" },
        { code: "FS-1", desc: "Add Supplier" },
      ],
    },
  ];

  return (
    <>
      <div className="mx-8 mt-9 flex flex-row justify-between">
        <div className="flex">
          <FaCog className=" mr-3" size={35} />
          <span className=" text-3xl font-bold text-primary-blue ">
            System User Roles
          </span>
        </div>

        <Button
          className=" ml-8 "
          onClick={() => {
            setisOpenAddModal(true);
          }}
        >
          Add New Role
        </Button>
      </div>

      <div className="mx-8 mt-12 flex flex-col gap-12">
        {roleSet.map((role) => {
          return (
            <div className="space-y-6 rounded-md border border-primary-blue p-4">
              <div className="text-lg font-semibold text-primary-blue">
                {role.role}
              </div>
              <div className="flex items-center">
                <InputSwitch disabled checked={role.bypass} className=" mr-4" />
                <span className="text-textColor  ml-2 text-sm font-normal">
                  Bypass Mode
                </span>
              </div>

              <div className=" grid grid-cols-1 gap-3 border-t py-2 lg:grid-cols-2">
                {permissionsSet.map((permission) => {
                  return (
                    <div>
                      <div className="py-2 font-semibold text-primary-blue">
                        {permission.module}
                      </div>
                      <div className="flex flex-col gap-2">
                        {permission.permissionsList.map((perm) => {
                          return (
                            <div className="flex items-center">
                              <Checkbox
                                name={permission.module}
                                value="Cheese"
                                // onChange={() => {}}
                                checked={
                                  role.bypass
                                    ? true
                                    : role.modules
                                        .find(
                                          (r) =>
                                            r.moduleName === permission.module,
                                        )
                                        ?.permissions.includes(perm.code) ||
                                      false
                                }
                              />
                              <span className="text-textColor  ml-2 text-sm font-normal">
                                {perm.desc}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      {/* 
      <AddEditFoodSupplier
        title={`${modalState} Food Supplier`}
        isOpen={isOpenAddModal}
        onClose={() => setisOpenAddModal(false)}
        //onSave={() => setisOpenDeleteModal(false)}
        isEdit={modalState === modalStates.EDIT}
        foodSupplier={selectedSupplier}
      /> */}

      {/* <FormModal
        isOpen={isOpenDeleteModal}
        title="Delete Food Supplier"
        onClose={() => setisOpenDeleteModal(false)}
        onSave={async () => {
          setisOpenDeleteModal(false);
          await deleteMutation.mutateAsync(selectedSupplier.id);
        }}
        submitText="Delete"
        submitColor="red"
        isSmall
      >
        <div>Are you sure you want to delete this Food Supplier?</div>
      </FormModal> */}
    </>
  );
};

export default Administration;
